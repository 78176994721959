import React, { useState, useEffect } from "react";
import {
  Typography,
  Table,
  Tag,
  message,
  Spin,
  Divider,
  DatePicker,
  Input,
  Button,
  Space,
  AutoComplete,
  Row,
  Col,
} from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { api } from "../api";
import moment from "moment-timezone";
import UserDetail from "./UserManagement/UserDetail";
import { Helmet } from "react-helmet";
import { CSVLink } from "react-csv";
import dayjs from "dayjs";

const { Title, Text } = Typography;
const { RangePicker } = DatePicker;

const AdminTransactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [userDetailVisible, setUserDetailVisible] = useState(false);
  const [dateRange, setDateRange] = useState([
    dayjs().subtract(30, "days"),
    dayjs(),
  ]);
  const [couponCode, setCouponCode] = useState("");
  const [userSearch, setUserSearch] = useState("");
  const [couponOptions, setCouponOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]);

  useEffect(() => {
    fetchTransactions();
  }, []);

  const fetchTransactions = async () => {
    try {
      setLoading(true);
      const params = new URLSearchParams();
      if (dateRange && dateRange.length === 2) {
        params.append("start_date", dateRange[0].format("YYYY-MM-DD"));
        params.append("end_date", dateRange[1].format("YYYY-MM-DD"));
      }
      if (couponCode) params.append("coupon_code", couponCode);
      if (userSearch) params.append("user_search", userSearch);

      const response = await api.get("/subscriptions/admin/transactions", {
        params,
      });
      console.log("Fetched transactions:", response.data); // デバッグ用ログ
      setTransactions(response.data);
    } catch (error) {
      console.error("Failed to fetch transactions:", error);
      if (error.response && error.response.status === 403) {
        message.error("アクセス権限がありません");
      } else {
        message.error("トランザクションの取得に失敗しました");
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchCouponOptions = async (search) => {
    try {
      const response = await api.get("/subscriptions/admin/coupon-codes", {
        params: { search },
      });
      setCouponOptions(response.data.map((code) => ({ value: code })));
    } catch (error) {
      console.error("Failed to fetch coupon options:", error);
    }
  };

  const fetchUserOptions = async (search) => {
    try {
      const response = await api.get(
        "/subscriptions/admin/user-search-suggestions",
        { params: { search } },
      );
      setUserOptions(
        response.data.map((user) => ({
          value: user.user_id,
          label: `${user.full_name} (${user.email}) ${user.company_name || ""}`,
        })),
      );
    } catch (error) {
      console.error("Failed to fetch user options:", error);
    }
  };

  const showUserDetail = (userId) => {
    setSelectedUserId(userId);
    setUserDetailVisible(true);
  };

  const handleUserDetailCancel = () => {
    setSelectedUserId(null);
    setUserDetailVisible(false);
  };

  const handleUserUpdate = () => {
    fetchTransactions();
  };

  const planNameMap = {
    free: "フリープラン",
    standard: "スタンダードプラン",
    pro: "プロプラン",
    premium: "プレミアムプラン",
    custom: "カスタムプラン",
  };

  const datePresets = [
    {
      label: "前々月",
      value: [
        dayjs().subtract(2, "month").startOf("month"),
        dayjs().subtract(2, "month").endOf("month"),
      ],
    },
    {
      label: "前月",
      value: [
        dayjs().subtract(1, "month").startOf("month"),
        dayjs().subtract(1, "month").endOf("month"),
      ],
    },
    { label: "今月", value: [dayjs().startOf("month"), dayjs()] },
    {
      label: "前の月",
      value: () => {
        const start = dayjs().subtract(1, "month").startOf("month");
        return [start, start.clone().endOf("month")];
      },
    },
    {
      label: "次の月",
      value: () => {
        const start = dayjs().add(1, "month").startOf("month");
        return [start, start.clone().endOf("month")];
      },
    },
    { label: "過去7日間", value: [dayjs().subtract(7, "days"), dayjs()] },
    { label: "過去14日間", value: [dayjs().subtract(14, "days"), dayjs()] },
    { label: "過去30日間", value: [dayjs().subtract(30, "days"), dayjs()] },
    { label: "過去90日間", value: [dayjs().subtract(90, "days"), dayjs()] },
  ];

  const columns = [
    {
      title: "会社名/部署名",
      key: "company",
      render: (_, record) => (
        <div>
          <div>{record.user.company_name}</div>
          <div style={{ fontSize: "12px", color: "#888" }}>
            {record.user.department_name}
          </div>
        </div>
      ),
    },
    {
      title: "氏名/メール",
      key: "user",
      render: (_, record) => (
        <div>
          <a
            onClick={() => showUserDetail(record.user.user_id)}
          >{`${record.user.last_name} ${record.user.first_name}`}</a>
          <div style={{ fontSize: "12px", color: "#888" }}>
            {record.user.email}
          </div>
        </div>
      ),
    },
    {
      title: "日付",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) =>
        moment(date).tz("Asia/Tokyo").format("YYYY/MM/DD HH:mm"),
    },
    {
      title: "金額（税込）",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => `¥${amount.toLocaleString()}`,
    },
    {
      title: "ステータス",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag color={status === "succeeded" ? "green" : "red"}>
          {status === "succeeded" ? "成功" : "失敗"}
        </Tag>
      ),
    },
    {
      title: "内容",
      dataIndex: "plan_name",
      key: "plan_name",
      render: (planName, record) => {
        const planType = planNameMap[planName] || planName;
        const transactionType = record.is_new ? "新規" : "継続";
        if (planName === "custom") {
          return `${planType}（${transactionType}）`;
        } else if (planName === "free") {
          return `${planType}（${transactionType}）`;
        } else {
          const paymentType = record.is_yearly ? "年払い" : "月払い";
          return `${planType}（${transactionType}／${paymentType}）`;
        }
      },
    },
  ];

  if (loading) {
    return <Spin size="large" />;
  }

  const csvData = transactions.map((transaction) => ({
    Company: transaction.user.company_name,
    Department: transaction.user.department_name,
    LastName: transaction.user.last_name,
    FirstName: transaction.user.first_name,
    Email: transaction.user.email,
    Date: moment(transaction.created_at)
      .tz("Asia/Tokyo")
      .format("YYYY/MM/DD HH:mm"),
    Amount: `¥${transaction.amount.toLocaleString()}`,
    Status: transaction.status === "succeeded" ? "成功" : "失敗",
    Content: (() => {
      const planType =
        planNameMap[transaction.plan_name] || transaction.plan_name;
      const transactionType = transaction.is_new ? "新規" : "継続";
      if (
        transaction.plan_name === "custom" ||
        transaction.plan_name === "free"
      ) {
        return `${planType}（${transactionType}）`;
      } else {
        const paymentType = transaction.is_yearly ? "年払い" : "月払い";
        return `${planType}（${transactionType}／${paymentType}）`;
      }
    })(),
  }));

  return (
    <>
      <Helmet>
        <title>トランザクション一覧 | SEO記事生成モード - magicss</title>
        <meta name="robots" content="noindex" />
        <meta
          property="og:title"
          content={`トランザクション一覧 | SEO記事生成モード - magicss`}
        />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/ogp.png`}
        />
        <meta
          property="og:description"
          content={`コンテンツ生成AIツール「magicss」のトランザクション一覧ページです。`}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <div>
        <Text style={{ margin: "16px 0" }}>管理者機能</Text>
        <Title level={1} className="gradient-text" style={{ fontSize: "24px" }}>
          トランザクション一覧
        </Title>
        <Divider style={{ margin: "24px 0" }} />
        <Row gutter={[16, 16]}>
          <Col xs={24} lg={20} xl={18}>
            <Space style={{ marginBottom: 16 }} wrap>
              <RangePicker
                value={dateRange}
                onChange={setDateRange}
                format="YYYY年MM月DD日"
                presets={datePresets}
                allowEmpty={[true, true]}
              />
              <AutoComplete
                options={couponOptions}
                onSearch={fetchCouponOptions}
                onSelect={setCouponCode}
                onChange={setCouponCode}
                style={{ width: 200 }}
                placeholder="クーポンコード"
              />
              <AutoComplete
                options={userOptions}
                onSearch={fetchUserOptions}
                onSelect={setUserSearch}
                onChange={setUserSearch}
                style={{ width: 200 }}
                placeholder="ユーザー検索"
              />
              <Button type="primary" onClick={fetchTransactions}>
                検索
              </Button>
            </Space>
          </Col>
          <Col
            xs={24}
            lg={4}
            xl={6}
            style={{ textAlign: "right", paddingTop: "16px" }}
          >
            <CSVLink
              data={csvData}
              filename={"transactions.csv"}
              className="ant-btn ant-btn-primary"
            >
              <DownloadOutlined /> CSV ダウンロード
            </CSVLink>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={transactions}
          rowKey="id"
          scroll={{ x: "max-content" }}
          loading={loading}
        />
        <UserDetail
          visible={userDetailVisible}
          onCancel={handleUserDetailCancel}
          userId={selectedUserId}
          onUpdate={handleUserUpdate}
        />
        <style jsx>{`
          .user-hover:hover {
            color: #1890ff;
          }
        `}</style>
      </div>
    </>
  );
};

export default AdminTransactions;
