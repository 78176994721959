// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#u_detail input,
#u_detail .ant-select-selector,
#u_detail .ant-input-number,
#u_detail .ant-picker,
#u_detail textarea {
  border-color: #fff !important;
}
.u_detail_modal > div {
  height: 100%;
}
.u_detail_modal .ant-modal-content {
  height: 80vh;
  padding: 0;
}
.u_detail_modal .ant-modal-body {
  padding: 40px;
}
.u_detail_modal .ant-tabs-content-holder {
  height: calc(80vh - 150px);
  overflow-y: auto;
  scroll-behavior: smooth;
  scrollbar-width: thin;
  scrollbar-color: #e7e7e7 #f5f5f5;
}
.u_detail_modal .ant-tabs-content {
  padding: 16px;
}

@media (max-width: 800px) {
  .u_detail_modal {
    overflow-y: auto;
    height: 80vh;
    border-radius: 8px;
  }
  .u_detail_modal .ant-modal-body {
    padding: 20px;
  }
  .u_detail_modal .ant-modal-content {
    height: auto;
    border-radius: 0;
  }
  .u_detail_modal .ant-tabs-content {
    padding: 0;
  }
  .u_detail_modal .ant-tabs-nav-operations {
    display: none;
  }
  .u_detail_modal .ant-tabs-content-holder {
    height: auto;
    overflow: inherit;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/UserManagement/UserDetail.css"],"names":[],"mappings":"AAAA;;;;;EAKE,6BAA6B;AAC/B;AACA;EACE,YAAY;AACd;AACA;EACE,YAAY;EACZ,UAAU;AACZ;AACA;EACE,aAAa;AACf;AACA;EACE,0BAA0B;EAC1B,gBAAgB;EAChB,uBAAuB;EACvB,qBAAqB;EACrB,gCAAgC;AAClC;AACA;EACE,aAAa;AACf;;AAEA;EACE;IACE,gBAAgB;IAChB,YAAY;IACZ,kBAAkB;EACpB;EACA;IACE,aAAa;EACf;EACA;IACE,YAAY;IACZ,gBAAgB;EAClB;EACA;IACE,UAAU;EACZ;EACA;IACE,aAAa;EACf;EACA;IACE,YAAY;IACZ,iBAAiB;EACnB;AACF","sourcesContent":["#u_detail input,\n#u_detail .ant-select-selector,\n#u_detail .ant-input-number,\n#u_detail .ant-picker,\n#u_detail textarea {\n  border-color: #fff !important;\n}\n.u_detail_modal > div {\n  height: 100%;\n}\n.u_detail_modal .ant-modal-content {\n  height: 80vh;\n  padding: 0;\n}\n.u_detail_modal .ant-modal-body {\n  padding: 40px;\n}\n.u_detail_modal .ant-tabs-content-holder {\n  height: calc(80vh - 150px);\n  overflow-y: auto;\n  scroll-behavior: smooth;\n  scrollbar-width: thin;\n  scrollbar-color: #e7e7e7 #f5f5f5;\n}\n.u_detail_modal .ant-tabs-content {\n  padding: 16px;\n}\n\n@media (max-width: 800px) {\n  .u_detail_modal {\n    overflow-y: auto;\n    height: 80vh;\n    border-radius: 8px;\n  }\n  .u_detail_modal .ant-modal-body {\n    padding: 20px;\n  }\n  .u_detail_modal .ant-modal-content {\n    height: auto;\n    border-radius: 0;\n  }\n  .u_detail_modal .ant-tabs-content {\n    padding: 0;\n  }\n  .u_detail_modal .ant-tabs-nav-operations {\n    display: none;\n  }\n  .u_detail_modal .ant-tabs-content-holder {\n    height: auto;\n    overflow: inherit;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
