import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  message,
  Typography,
  Tag,
  Tabs,
  Table,
  Spin,
  Select,
  InputNumber,
  DatePicker,
  Divider,
  Tooltip,
  Progress,
  Switch,
  Popconfirm,
  Row,
  Col,
  Card,
  Statistic,
} from "antd";
import {
  SearchOutlined,
  SyncOutlined,
  ExportOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { Line } from "react-chartjs-2";
import "./UserDetail.css";
import moment from "moment-timezone";
import { getUserRole } from "../../auth";
import { api, getUserAnalytics } from "../../api";
import dayjs from "dayjs";

const { Text, Title } = Typography;
const { TabPane } = Tabs;
const { TextArea } = Input;
const { Option } = Select;

const UserDetail = ({ visible, onCancel, userId, onUpdate }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [articles, setArticles] = useState([]);
  const [recommendedKeywords, setRecommendedKeywords] = useState([]);
  const [recommendedKeywordsUpdateAt, setRecommendedKeywordsUpdateAt] =
    useState(null);
  const [currentUserRole, setCurrentUserRole] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [ticketLogs, setTicketLogs] = useState([]);
  const [isUpdatingKeywords, setIsUpdatingKeywords] = useState(false);
  const [showRecommendedKeywords, setShowRecommendedKeywords] = useState(false);
  const [analyticsData, setAnalyticsData] = useState({
    totalArticles: 0,
    totalPayment: 0,
    subscriptionMonths: 0,
    loginCount60Days: 0,
    articleCount60Days: 0,
    engagementData: [],
  });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const fetchUserRole = async () => {
      const role = await getUserRole();
      setCurrentUserRole(role);
    };

    fetchUserRole();
  }, []);

  useEffect(() => {
    if (visible && userId) {
      fetchUserDetails();
      fetchUserArticles();
      fetchRecommendedKeywords();
      fetchTransactions();
      fetchTicketLogs();
      fetchAnalyticsData();
    }
  }, [visible, userId]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const fetchUserDetails = async () => {
    try {
      setLoading(true);
      const response = await api.get(`/users/${userId}`);
      setUserData(response.data);
      form.setFieldsValue({
        ...response.data,
        plan: response.data.plan?.name,
        next_renewal_date: response.data.subscription?.next_renewal_date
          ? moment(response.data.subscription.next_renewal_date)
          : null,
        next_ticket_update: response.data.subscription?.next_ticket_update
          ? moment(response.data.subscription.next_ticket_update)
          : null,
        stripe_customer_id: response.data.stripe_customer_id,
      });
    } catch (error) {
      console.error("Failed to fetch user details:", error);
      message.error("ユーザー詳細の取得に失敗しました");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("userData updated:", userData);
  }, [userData]);

  const fetchAnalyticsData = async () => {
    try {
      const response = await getUserAnalytics(userId);
      setAnalyticsData(response);
    } catch (error) {
      console.error("Failed to fetch analytics data:", error);
      if (error.response) {
        console.error("Error response:", error.response.data);
        message.error(
          `分析データの取得に失敗しました: ${error.response.data.detail}`,
        );
      } else {
        message.error("分析データの取得に失敗しました");
      }
    }
  };

  const fetchUserArticles = async () => {
    try {
      const response = await api.get(`/seo-article/list?user_id=${userId}`);
      setArticles(response.data.articles || []); // response.data.articles を使用
    } catch (error) {
      console.error("Failed to fetch user articles:", error);
      message.error("ユーザーの記事一覧の取得に失敗しました");
      setArticles([]);
    }
  };

  const fetchTransactions = async () => {
    try {
      const response = await api.get(`/subscriptions/transactions/${userId}`);
      setTransactions(response.data);
    } catch (error) {
      console.error("Failed to fetch transactions:", error);
      message.error("決済履歴の取得に失敗しました");
    }
  };

  const fetchTicketLogs = async () => {
    try {
      const response = await api.get(`/subscriptions/ticket-logs/${userId}`);
      setTicketLogs(response.data);
    } catch (error) {
      console.error("Failed to fetch ticket logs:", error);
      message.error("チケット履歴の取得に失敗しました");
    }
  };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const updatedValues = {
        ...values,
        next_renewal_date: values.next_renewal_date
          ? dayjs(values.next_renewal_date).format("YYYY-MM-DD")
          : null,
      };
      await api.put(`/users/${userId}`, updatedValues);
      message.success("ユーザー情報を更新しました");
      onUpdate();
      fetchUserDetails();
    } catch (error) {
      console.error("Failed to update user details:", error);
      message.error("ユーザー情報の更新に失敗しました");
    }
    setLoading(false);
  };

  const updateRecommendedKeywords = async () => {
    try {
      setIsUpdatingKeywords(true);
      const response = await api.post(
        `/seo-article/update-keywords-recommend`,
        { user_id: userId },
      );
      message.success("おすすめキーワードを更新しました");
      fetchRecommendedKeywords();
    } catch (error) {
      console.error("Failed to update recommended keywords:", error);
      message.error("おすすめキーワードの更新に失敗しました");
    } finally {
      setIsUpdatingKeywords(false);
    }
  };

  const fetchRecommendedKeywords = async () => {
    try {
      const response = await api.get(
        `/seo-article/keywords-recommend?user_id=${userId}`,
      );
      setRecommendedKeywords(response.data.keywords);
      setRecommendedKeywordsUpdateAt(
        response.data.recommended_keywords_update_at,
      );
    } catch (error) {
      console.error("Failed to fetch recommended keywords:", error);
      message.error("おすすめキーワードの取得に失敗しました");
    }
  };

  const CustomDatePicker = ({ value, onChange, ...props }) => {
    return (
      <DatePicker
        {...props}
        value={value ? dayjs(value) : null}
        onChange={(date, dateString) =>
          onChange(date ? date.toDate() : null, dateString)
        }
        format="YYYY年MM月DD日"
      />
    );
  };

  const roleTags = {
    user: { color: "blue", text: "User" },
    parent: { color: "green", text: "Parent" },
    admin: { color: "volcano", text: "Admin" },
    master: { color: "red", text: "Master" },
  };

  const planTags = {
    free: { color: "blue", text: "フリープラン" },
    standard: { color: "green", text: "スタンダードプラン" },
    pro: { color: "orange", text: "プロプラン" },
    premium: { color: "gold", text: "プレミアムプラン" },
    custom: { color: "purple", text: "カスタムプラン" },
  };

  const isEditable = (field) => {
    if (currentUserRole === "master") {
      return [
        "company_name",
        "department_name",
        "email",
        "last_name",
        "first_name",
        "last_name_kana",
        "first_name_kana",
        "plan",
        "ticket_count",
        "next_renewal_date",
        "stripe_customer_id",
        "note",
      ].includes(field);
    }
    if (currentUserRole === "admin" && field === "note") return true;
    return false;
  };

  const getStatusColor = (status) => {
    if (status.includes("予約中") || status.includes("更新予約中"))
      return "orange";
    if (status.includes("中") || status.includes("更新中")) return "green";
    if (status === "生成済み") return "blue";
    if (status === "生成失敗") return "red";
    return "default";
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => confirm()}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => clearFilters()}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
  });

  const articleColumns = [
    {
      title: "ステータス",
      dataIndex: "status",
      key: "status",
      filters: [
        { text: "予約中", value: "予約中" },
        { text: "更新予約中", value: "更新予約中" },
        { text: "生成中", value: "生成中" },
        { text: "更新中", value: "更新中" },
        { text: "生成済み", value: "生成済み" },
        { text: "生成失敗", value: "生成失敗" },
      ],
      onFilter: (value, record) => record.status.includes(value),
      render: (status, record) => (
        <>
          <Tag color={getStatusColor(status)}>{status.split("（")[0]}</Tag>
          {status.includes("（") && (
            <span
              style={{ marginLeft: "5px", fontSize: "12px", color: "#888" }}
            >
              {status.split("（")[1].replace("）", "")}
            </span>
          )}
        </>
      ),
    },
    {
      title: "キーワード",
      dataIndex: "keyword",
      key: "keyword",
      ...getColumnSearchProps("keyword"),
    },
    {
      title: "生成実行日",
      dataIndex: "execution_date",
      key: "execution_date",
      sorter: (a, b) => new Date(a.execution_date) - new Date(b.execution_date),
      render: (text, record) => {
        if (record.status.includes("予約中")) {
          return record.estimated_wait_time != null
            ? `予想待ち時間: ${record.estimated_wait_time}分`
            : "予想待ち時間: 計算中";
        }
        return text
          ? moment.utc(text).tz("Asia/Tokyo").format("YYYY/MM/DD HH:mm")
          : "-";
      },
    },
    {
      title: "完了日",
      dataIndex: "completed_at",
      key: "completed_at",
      sorter: (a, b) => new Date(a.completed_at) - new Date(b.completed_at),
      render: (text, record) => {
        if (record.status === "生成済み" || record.status === "生成失敗") {
          return text
            ? moment.utc(text).tz("Asia/Tokyo").format("YYYY/MM/DD HH:mm")
            : "-";
        }
        return "-";
      },
    },
  ];

  const userInfoColumns = [
    {
      title: "項目",
      dataIndex: "item",
      key: "item",
      render: (text) => <Text style={{ fontSize: "12px" }}>{text}</Text>,
    },
    {
      title: "内容",
      dataIndex: "value",
      key: "value",
      render: (text, record) => record.value,
    },
  ];

  const userInfoData = [
    {
      key: "company_and_department",
      item: "会社名/部署",
      value: (
        <>
          <Form.Item
            name="company_name"
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              margin: "0 8px 0 0",
            }}
          >
            <Input disabled={!isEditable("company_name")} />
          </Form.Item>
          <Form.Item
            name="department_name"
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              margin: "0",
            }}
          >
            <Input disabled={!isEditable("department_name")} />
          </Form.Item>
        </>
      ),
    },
    {
      key: "name",
      item: "氏名",
      value: (
        <>
          <Form.Item
            name="last_name"
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              margin: "0 8px 0 0",
            }}
          >
            <Input disabled={!isEditable("last_name")} />
          </Form.Item>
          <Form.Item
            name="first_name"
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              margin: "0",
            }}
          >
            <Input disabled={!isEditable("first_name")} />
          </Form.Item>
        </>
      ),
    },
    {
      key: "name_kana",
      item: "氏名(カタカナ)",
      value: (
        <>
          <Form.Item
            name="last_name_kana"
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              margin: "0 8px 0 0",
            }}
          >
            <Input disabled={!isEditable("last_name_kana")} />
          </Form.Item>
          <Form.Item
            name="first_name_kana"
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              margin: "0",
            }}
          >
            <Input disabled={!isEditable("first_name_kana")} />
          </Form.Item>
        </>
      ),
    },
    {
      key: "email",
      item: "Email",
      value: (
        <Form.Item name="email" style={{ margin: "0" }}>
          <Input disabled={!isEditable("email")} />
        </Form.Item>
      ),
    },
    {
      key: "plan",
      item: "プラン",
      value: (
        <Form.Item name="plan" style={{ margin: "0" }}>
          <Select disabled={!isEditable("plan")}>
            <Option value="free">フリープラン</Option>
            <Option value="standard">スタンダードプラン</Option>
            <Option value="pro">プロプラン</Option>
            <Option value="premium">プレミアムプラン</Option>
            <Option value="custom">カスタムプラン</Option>
          </Select>
        </Form.Item>
      ),
    },
    {
      key: "subscription_status",
      item: "サブスクリプション状態",
      value: (
        <div style={{ padding: "4px 11px" }}>
          {userData?.subscription ? (
            <>
              <Tag
                color={
                  userData.subscription.status === "canceling" ? "red" : "green"
                }
              >
                {userData.subscription.status === "canceling"
                  ? "キャンセル予約中"
                  : "アクティブ"}
              </Tag>
              {(userData.subscription.status === "pending" ||
                userData.subscription.has_pending_custom_plan) && (
                <Tag color="orange" style={{ marginLeft: "8px" }}>
                  カスタムプラン承認待ち
                </Tag>
              )}
            </>
          ) : (
            "N/A"
          )}
        </div>
      ),
    },
    {
      key: "ticket_count",
      item: "チケット",
      value: (
        <Form.Item name="ticket_count" style={{ margin: "0" }}>
          <InputNumber disabled={!isEditable("ticket_count")} />
        </Form.Item>
      ),
    },
    {
      key: "next_renewal_date",
      item: "次回更新日",
      value: (
        <Form.Item name="next_renewal_date" style={{ margin: 0 }}>
          <CustomDatePicker
            style={{ width: "100%" }}
            disabled={!isEditable("next_renewal_date")}
          />
        </Form.Item>
      ),
    },
    {
      key: "next_ticket_update",
      item: "次回チケット更新日",
      value: (
        <div style={{ padding: "4px 11px" }}>
          {userData?.subscription?.next_ticket_update
            ? moment(userData.subscription.next_ticket_update)
                .tz("Asia/Tokyo")
                .format("YYYY年MM月DD日")
            : userData?.subscription?.is_final_update
              ? "期間満了"
              : "N/A"}
        </div>
      ),
    },
    {
      key: "stripe_customer_id",
      item: "StripeID",
      value: (
        <Form.Item name="stripe_customer_id" style={{ margin: "0" }}>
          <Input disabled={!isEditable("stripe_customer_id")} />
        </Form.Item>
      ),
    },
    {
      key: "registered_at",
      item: "登録日",
      value: (
        <div style={{ padding: "4px 11px" }}>
          {userData?.registered_at
            ? moment(userData.registered_at)
                .tz("Asia/Tokyo")
                .format("YYYY/MM/DD HH:mm")
            : "-"}
        </div>
      ),
    },
    {
      key: "last_login_at",
      item: "最終ログイン",
      value: (
        <div style={{ padding: "4px 11px" }}>
          {analyticsData.lastLoginAt
            ? moment(analyticsData.lastLoginAt)
                .tz("Asia/Tokyo")
                .format("YYYY/MM/DD HH:mm")
            : "-"}
        </div>
      ),
    },
    {
      key: "note",
      item: "備考",
      value: (
        <Form.Item name="note" style={{ margin: "0" }}>
          <TextArea rows={4} disabled={!isEditable("note")} />
        </Form.Item>
      ),
    },
  ];

  const renderAnalyticsTab = () => {
    const chartData = {
      labels: analyticsData.engagementData.map((d) =>
        moment(d.date).format("MM/DD"),
      ),
      datasets: [
        {
          label: "エンゲージメントスコア",
          data: analyticsData.engagementData.map((d) => d.engagementScore),
          fill: false,
          borderColor: "rgb(0, 212, 255)",
          tension: 0.1,
        },
      ],
    };

    const chartOptions = {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: false,
        },
      },
      scales: {
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: "エンゲージメントスコア",
          },
        },
        x: {
          title: {
            display: true,
            text: "日付",
          },
        },
      },
    };

    const cardStyle = {
      textAlign: "center",
    };

    const valueStyle = {
      fontSize: "24px",
      fontWeight: "bold",
      color: "#0072ff",
    };

    const CustomStatistic = ({
      title,
      value,
      prefix,
      suffix,
      valueStyle,
      suffixStyle,
    }) => (
      <Statistic
        title={title}
        value={value}
        prefix={prefix}
        valueStyle={valueStyle}
        formatter={(value) => (
          <span>
            {value}
            <span style={suffixStyle}>{suffix}</span>
          </span>
        )}
      />
    );

    return (
      <>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} lg={6}>
            <Card style={cardStyle}>
              <CustomStatistic
                title="SEO記事生成数"
                value={analyticsData.totalArticles}
                valueStyle={valueStyle}
                suffix=" 本"
                suffixStyle={{
                  fontSize: "14px",
                  fontWeight: "normal",
                  color: "rgba(0, 0, 0, 0.45)",
                }}
              />
            </Card>
          </Col>
          <Col xs={24} sm={12} lg={6}>
            <Card style={cardStyle}>
              <CustomStatistic
                title="総支払い金額"
                value={analyticsData.totalPayment}
                suffix=" 円"
                valueStyle={valueStyle}
                suffixStyle={{
                  fontSize: "14px",
                  fontWeight: "normal",
                  color: "rgba(0, 0, 0, 0.45)",
                }}
              />
            </Card>
          </Col>
          <Col xs={24} sm={12} lg={6}>
            <Card style={cardStyle}>
              <CustomStatistic
                title="継続月数"
                value={
                  analyticsData.subscriptionMonths === 0
                    ? "-"
                    : analyticsData.subscriptionMonths
                }
                suffix={analyticsData.subscriptionMonths === 0 ? "" : "ヶ月"}
                valueStyle={valueStyle}
                suffixStyle={{
                  fontSize: "14px",
                  fontWeight: "normal",
                  color: "rgba(0, 0, 0, 0.45)",
                }}
              />
            </Card>
          </Col>
          <Col xs={24} sm={12} lg={6}>
            <Card style={cardStyle}>
              <CustomStatistic
                title="直近60日ログイン数"
                value={analyticsData.loginCount60Days}
                valueStyle={valueStyle}
                suffix=" 回"
                suffixStyle={{
                  fontSize: "14px",
                  fontWeight: "normal",
                  color: "rgba(0, 0, 0, 0.45)",
                }}
              />
            </Card>
          </Col>
        </Row>
        <Title
          level={3}
          style={{
            fontSize: "16px",
            color: "rgb(0 212 255)",
            marginTop: "24px",
            marginBottom: "16px",
          }}
        >
          エンゲージメントスコア（直近30日間）
        </Title>
        <Line data={chartData} options={chartOptions} />
      </>
    );
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        message.success("Stripeの支払いIDをコピーしました");
      },
      (err) => {
        console.error("Failed to copy: ", err);
        message.error("コピーに失敗しました");
      },
    );
  };

  const transactionColumns = [
    {
      title: "日付",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) =>
        moment(date).tz("Asia/Tokyo").format("YYYY/MM/DD HH:mm"),
    },
    {
      title: "金額（税込）",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => `¥${amount.toLocaleString()}`,
    },
    {
      title: "ステータス",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag color={status === "succeeded" ? "green" : "red"}>
          {status === "succeeded" ? "成功" : "失敗"}
        </Tag>
      ),
    },
    {
      title: "内容",
      dataIndex: "plan_name",
      key: "plan_name",
      render: (planName, record) => {
        const planType = planTags[planName]?.text || planName;
        const paymentType = record.is_yearly ? "年払い" : "月払い";
        const transactionType = record.is_new ? "新規" : "継続";
        return planType === "フリープラン"
          ? `${planType}（${transactionType}）`
          : `${planType}（${transactionType}／${paymentType}）`;
      },
    },
  ];

  const ticketLogColumns = [
    {
      title: "日時",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) =>
        moment(date).tz("Asia/Tokyo").format("YYYY/MM/DD HH:mm"),
    },
    {
      title: "変更前",
      dataIndex: "previous_count",
      key: "previous_count",
    },
    {
      title: "変更後",
      dataIndex: "new_count",
      key: "new_count",
    },
    {
      title: "理由",
      dataIndex: "reason",
      key: "reason",
    },
  ];

  const recommendedKeywordsColumns = [
    {
      title: "キーワード",
      dataIndex: "keyword",
      key: "keyword",
    },
    {
      title: "検索Vol",
      dataIndex: "volume",
      key: "volume",
      sorter: (a, b) => a.volume - b.volume,
    },
    {
      title: "CPC",
      dataIndex: "cpc",
      key: "cpc",
      render: (cpc) => `$${cpc?.toFixed(2) || "N/A"}`,
      sorter: (a, b) => (a.cpc || 0) - (b.cpc || 0),
    },
    {
      title: "トレンド",
      dataIndex: "trend",
      key: "trend",
      render: (_, record) => {
        const data = record.monthly_volumes || [];
        const width = 80;
        const height = 24;
        const points = data
          .map(
            (d, i) =>
              `${(i / (data.length - 1)) * width},${height - (d.volume / Math.max(...data.map((d) => d.volume))) * height}`,
          )
          .join(" ");

        return (
          <svg width={width} height={height}>
            <polyline
              fill="none"
              stroke="#00d4ff"
              strokeWidth="1"
              points={points}
            />
          </svg>
        );
      },
    },
    {
      title: "競合性",
      dataIndex: "competition",
      key: "competition",
      render: (competition) => {
        const value =
          competition !== null && competition !== undefined
            ? Math.min(Math.round(competition * 10), 10)
            : 0;
        return (
          <Tooltip
            title={`競合性: ${value}/10 (${(competition * 100).toFixed(2)}%)`}
          >
            <Progress
              percent={value * 10}
              steps={10}
              size="small"
              showInfo={false}
              strokeColor="#1890ff"
            />
          </Tooltip>
        );
      },
      sorter: (a, b) => (a.competition || 0) - (b.competition || 0),
    },
  ];

  const CustomPlanTab = ({ userId, subscription, onUpdate }) => {
    const [loading, setLoading] = useState(false);
    const [customPlanForm] = Form.useForm();

    useEffect(() => {
      if (subscription) {
        const planData =
          subscription.status === "pending"
            ? subscription
            : subscription.pending_custom_plan || subscription;

        customPlanForm.setFieldsValue({
          end_date: moment(planData.current_period_end),
          total_amount: planData.custom_data?.total_amount,
          is_monthly_update: planData.custom_data?.is_monthly_update,
          monthly_tickets: planData.custom_data?.monthly_tickets,
          total_tickets: planData.custom_data?.total_tickets,
        });
      } else {
        customPlanForm.resetFields();
      }
    }, [subscription]);

    const handleCustomPlanSubmit = async (values) => {
      setLoading(true);
      try {
        const formData = {
          ...values,
          end_date: dayjs(values.end_date).format("YYYY-MM-DD"),
          target_user_id: userId,
          is_monthly_update: values.is_monthly_update || false,
          monthly_tickets: values.is_monthly_update
            ? values.monthly_tickets
            : undefined,
          total_tickets: values.is_monthly_update
            ? undefined
            : values.total_tickets,
        };
        await api.post("/subscriptions/create-custom-plan", formData);
        message.success("カスタムプランが作成されました。承認待ちです。");
        customPlanForm.resetFields();
        onUpdate();
      } catch (error) {
        console.error("Error in handleCustomPlanSubmit:", error);
        const errorMessage =
          error.response?.data?.detail || error.message || "不明なエラー";
        message.error("カスタムプランの作成に失敗しました: " + errorMessage);
      } finally {
        setLoading(false);
      }
    };

    const handleApproveCustomPlan = async () => {
      setLoading(true);
      try {
        await api.post(`/subscriptions/approve-custom-plan/${subscription.id}`);
        message.success("カスタムプランが承認されました");
        onUpdate();
      } catch (error) {
        message.error(
          "カスタムプランの承認に失敗しました: " + error.response?.data?.detail,
        );
      } finally {
        setLoading(false);
      }
    };

    const handleUpdateCustomPlan = async (values) => {
      setLoading(true);
      try {
        console.log("Updating custom plan with values:", values);
        const formData = {
          end_date: values.end_date.format("YYYY-MM-DD"),
          total_amount: subscription.custom_data.total_amount,
          is_monthly_update: subscription.custom_data.is_monthly_update,
          monthly_tickets: subscription.custom_data.monthly_tickets,
          total_tickets: subscription.custom_data.total_tickets,
        };
        console.log("Formatted form data:", formData);
        const response = await api.put(
          `/subscriptions/update-custom-plan/${subscription.id}`,
          formData,
        );
        console.log("Custom plan update response:", response.data);
        message.success("カスタムプランが更新されました");
        onUpdate();
      } catch (error) {
        console.error("Error updating custom plan:", error);
        if (error.response) {
          console.error("Error response:", error.response.data);
          console.error("Status code:", error.response.status);
          console.error("Headers:", error.response.headers);
        } else if (error.request) {
          console.error("Error request:", error.request);
        } else {
          console.error("Error message:", error.message);
        }
        message.error(
          "カスタムプランの更新に失敗しました: " +
            (error.response?.data?.detail || error.message),
        );
      } finally {
        setLoading(false);
      }
    };

    const handleCancelPendingCustomPlan = async () => {
      setLoading(true);
      try {
        await api.post(
          `/subscriptions/cancel-pending-custom-plan/${subscription.id}`,
        );
        message.success("承認待ちのカスタムプランがキャンセルされました");
        onUpdate();
      } catch (error) {
        message.error(
          "カスタムプランのキャンセルに失敗しました: " +
            error.response?.data?.detail,
        );
      } finally {
        setLoading(false);
      }
    };

    const handleCancelCustomPlan = async () => {
      setLoading(true);
      try {
        await api.post(`/subscriptions/cancel-custom-plan/${subscription.id}`);
        message.success("カスタムプランがキャンセルされました");
        onUpdate();
      } catch (error) {
        message.error(
          "カスタムプランのキャンセルに失敗しました: " +
            error.response?.data?.detail,
        );
      } finally {
        setLoading(false);
      }
    };

    const renderCustomPlanForm = (isUpdate = false) => (
      <Form
        form={customPlanForm}
        onFinish={isUpdate ? handleUpdateCustomPlan : handleCustomPlanSubmit}
      >
        <Title
          level={3}
          style={{
            fontSize: "16px",
            color: "rgb(0 212 255)",
            marginBottom: "16px",
          }}
        >
          {isUpdate ? "カスタムプランの更新" : "カスタムプランの登録申請"}
        </Title>
        <Table
          dataSource={[
            {
              item: "終了日",
              value: (
                <Form.Item
                  name="end_date"
                  rules={[
                    { required: true, message: "終了日を選択してください" },
                  ]}
                  style={{ margin: 0 }}
                >
                  <CustomDatePicker
                    style={{ width: "100%" }}
                    disabledDate={(current) =>
                      current && current < dayjs().endOf("day")
                    }
                    presets={[
                      { label: "30日後", value: dayjs().add(30, "d") },
                      { label: "90日後", value: dayjs().add(90, "d") },
                      { label: "180日後", value: dayjs().add(180, "d") },
                    ]}
                  />
                </Form.Item>
              ),
            },
            ...(!isUpdate
              ? [
                  {
                    item: "総額",
                    value: (
                      <Form.Item
                        name="total_amount"
                        rules={[
                          { required: true, message: "総額を入力してください" },
                        ]}
                        style={{ margin: 0 }}
                      >
                        <InputNumber
                          min={0}
                          addonBefore="¥"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    ),
                  },
                  {
                    item: "チケット付与方法",
                    value: (
                      <Form.Item
                        name="is_monthly_update"
                        valuePropName="checked"
                        style={{ margin: 0 }}
                      >
                        <Switch
                          checkedChildren="月次"
                          unCheckedChildren="一括"
                        />
                      </Form.Item>
                    ),
                  },
                  {
                    item: "チケット数",
                    value: (
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues.is_monthly_update !==
                          currentValues.is_monthly_update
                        }
                      >
                        {({ getFieldValue }) =>
                          getFieldValue("is_monthly_update") ? (
                            <Form.Item
                              name="monthly_tickets"
                              rules={[
                                {
                                  required: true,
                                  message: "月間チケット数を入力してください",
                                },
                              ]}
                              style={{ margin: 0 }}
                            >
                              <InputNumber
                                min={1}
                                addonAfter="枚/月"
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          ) : (
                            <Form.Item
                              name="total_tickets"
                              rules={[
                                {
                                  required: true,
                                  message: "総チケット数を入力してください",
                                },
                              ]}
                              style={{ margin: 0 }}
                            >
                              <InputNumber
                                min={1}
                                addonAfter="枚"
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          )
                        }
                      </Form.Item>
                    ),
                  },
                ]
              : []),
          ]}
          columns={[
            { title: "項目", dataIndex: "item", key: "item", width: "30%" },
            { title: "内容", dataIndex: "value", key: "value" },
          ]}
          pagination={false}
          showHeader={false}
          size="small"
        />
        <Form.Item style={{ marginTop: "16px", textAlign: "right" }}>
          <Button type="primary" htmlType="submit">
            {isUpdate
              ? "カスタムプランを更新する"
              : "カスタムプランの登録を申請する"}
          </Button>
          {isUpdate && (
            <Popconfirm
              title="カスタムプランをキャンセルしてフリープランに移行しますか？"
              onConfirm={handleCancelCustomPlan}
              okText="はい"
              cancelText="いいえ"
            >
              <Button danger style={{ marginLeft: "8px" }}>
                即時キャンセルする
              </Button>
            </Popconfirm>
          )}
        </Form.Item>
      </Form>
    );

    const renderPendingCustomPlan = () => {
      const pendingPlan =
        subscription.status === "pending"
          ? subscription
          : subscription.pending_custom_plan;

      return (
        <div>
          <Title
            level={3}
            style={{
              fontSize: "16px",
              color: "rgb(0 212 255)",
              marginBottom: "16px",
            }}
          >
            承認待ちのカスタムプラン
          </Title>
          <Table
            dataSource={[
              {
                item: "期間",
                value: `${moment(pendingPlan.current_period_start).format("YYYY/MM/DD")} - ${moment(pendingPlan.current_period_end).format("YYYY/MM/DD")}`,
              },
              {
                item: "総額",
                value: `¥${pendingPlan.custom_data?.total_amount.toLocaleString() || "N/A"}`,
              },
              {
                item: "チケット",
                value: pendingPlan.custom_data?.is_monthly_update
                  ? `月間 ${pendingPlan.custom_data.monthly_tickets} 枚`
                  : `総数 ${pendingPlan.custom_data?.total_tickets} 枚`,
              },
            ]}
            columns={[
              { title: "項目", dataIndex: "item", key: "item", width: "30%" },
              { title: "内容", dataIndex: "value", key: "value" },
            ]}
            pagination={false}
            showHeader={false}
            size="small"
          />
          <div style={{ marginTop: "16px", textAlign: "right" }}>
            {(currentUserRole === "master" || currentUserRole === "admin") && (
              <Button
                onClick={handleCancelPendingCustomPlan}
                style={{ marginRight: "8px" }}
              >
                申請をキャンセル
              </Button>
            )}
            {currentUserRole === "master" && (
              <Button type="primary" onClick={handleApproveCustomPlan}>
                承認して有効化する
              </Button>
            )}
          </div>
        </div>
      );
    };

    const renderContent = () => {
      console.log("subscription:", subscription); // デバッグ用

      if (!subscription) {
        return renderCustomPlanForm(false);
      }

      if (
        subscription.status === "pending" ||
        subscription.has_pending_custom_plan
      ) {
        return renderPendingCustomPlan();
      }

      // カスタムプランのアクティブユーザーの判定を修正
      if (subscription.status === "active" && subscription.plan_id === 6) {
        // 6はカスタムプランのID
        return renderCustomPlanForm(true);
      }

      return renderCustomPlanForm(false);
    };

    return <Spin spinning={loading}>{renderContent()}</Spin>;
  };

  if (!userData) {
    return null;
  }

  return (
    <>
      <Modal
        visible={visible}
        onCancel={onCancel}
        footer={null}
        centered
        width={1080}
        className="u_detail_modal"
      >
        <Spin spinning={loading}>
          <Row
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 16,
            }}
          >
            <Col xs={24} lg={10}>
              <Text>
                {userData.company_name} {userData.department_name}
              </Text>
              <br />
              <Text style={{ fontSize: 16 }}>
                {userData.last_name} {userData.first_name}
              </Text>
              <Text type="secondary" style={{ fontSize: 12 }}>
                {" "}
                ({userData.last_name_kana} {userData.first_name_kana})
              </Text>
            </Col>
            <Col xs={24} lg={10}>
              <Text>{userData.email}</Text>
              <br />
              <Text type="secondary" style={{ fontSize: 12 }}>
                ID: {userData.user_id}
              </Text>
            </Col>
            <Col xs={24} lg={4}>
              <Tag color={roleTags[userData.role]?.color}>
                {roleTags[userData.role]?.text}
              </Tag>
              <Tag color={planTags[userData.plan?.name || "free"]?.color}>
                {planTags[userData.plan?.name || "free"]?.text}
              </Tag>
            </Col>
          </Row>

          <Divider />

          <Tabs
            tabPosition={windowWidth <= 800 ? "top" : "left"}
            tabBarStyle={{
              background: "none",
              width: windowWidth <= 800 ? "100%" : "160px",
            }}
            style={{ height: "auto" }}
          >
            <TabPane tab="基本情報" key="1">
              <Form
                form={form}
                onFinish={onFinish}
                layout="vertical"
                id="u_detail"
              >
                <Table
                  columns={userInfoColumns}
                  dataSource={userInfoData}
                  pagination={false}
                  showHeader={false}
                  size="small"
                />
                {(currentUserRole === "master" ||
                  currentUserRole === "admin") && (
                  <Form.Item style={{ marginTop: "16px", textAlign: "right" }}>
                    <Button type="primary" htmlType="submit">
                      更新
                    </Button>
                  </Form.Item>
                )}
              </Form>
            </TabPane>
            <TabPane tab="分析" key="2">
              {renderAnalyticsTab()}
            </TabPane>
            <TabPane tab="生成履歴" key="3">
              <Button
                icon={<ExportOutlined />}
                onClick={() =>
                  window.open(`/seo-article/list/${userId}`, "_blank")
                }
                style={{ marginBottom: "24px" }}
              >
                生成履歴ページを開く
              </Button>
              <Table
                columns={articleColumns}
                dataSource={articles}
                rowKey="id"
                pagination={{
                  pageSize: 5,
                  position: ["bottomLeft"],
                }}
                onRow={(record) => ({
                  onClick: () => {
                    if (record.status === "生成済み") {
                      window.open(
                        `/seo-article/detail/${record.uuid}`,
                        "_blank",
                      );
                    }
                  },
                  style: {
                    cursor:
                      record.status === "生成済み" ? "pointer" : "default",
                  },
                })}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "16px",
                }}
              >
                <div>
                  <Text>
                    おすすめキーワード：{recommendedKeywords.length}件
                  </Text>
                  <Text style={{ marginLeft: "16px" }}>
                    （最終更新日時：
                    {recommendedKeywordsUpdateAt
                      ? moment(recommendedKeywordsUpdateAt)
                          .tz("Asia/Tokyo")
                          .format("YYYY/MM/DD HH:mm")
                      : "未更新"}
                    ）
                  </Text>
                  <Button
                    icon={<SyncOutlined />}
                    onClick={updateRecommendedKeywords}
                    loading={isUpdatingKeywords}
                    disabled={isUpdatingKeywords}
                    style={{ marginLeft: "16px" }}
                  >
                    更新する
                  </Button>
                  {recommendedKeywords.length > 0 && (
                    <Button
                      icon={<EyeOutlined />}
                      onClick={() =>
                        setShowRecommendedKeywords(!showRecommendedKeywords)
                      }
                      style={{ marginLeft: "16px" }}
                    >
                      {showRecommendedKeywords ? "隠す" : "表示する"}
                    </Button>
                  )}
                </div>
              </div>
              {showRecommendedKeywords && (
                <Table
                  columns={recommendedKeywordsColumns}
                  dataSource={recommendedKeywords}
                  rowKey="id"
                  pagination={{ pageSize: 10 }}
                  style={{ marginTop: "16px" }}
                />
              )}
            </TabPane>
            <TabPane tab="決済履歴" key="4">
              <Table
                columns={transactionColumns}
                dataSource={transactions}
                rowKey="id"
                pagination={{ pageSize: 10 }}
                onRow={(record) => ({
                  onClick: () =>
                    copyToClipboard(record.stripe_payment_intent_id),
                  style: { cursor: "pointer" },
                })}
              />
              <Typography.Text type="secondary">
                行をクリックすると、その決済のStripe支払いIDをコピーできます。
              </Typography.Text>
            </TabPane>
            <TabPane tab="チケット履歴" key="5">
              <Table
                columns={ticketLogColumns}
                dataSource={ticketLogs}
                rowKey="id"
                pagination={{ pageSize: 10 }}
              />
            </TabPane>
            <TabPane tab="カスタムプラン" key="6">
              <CustomPlanTab
                userId={userData.user_id}
                subscription={userData.subscription}
                onUpdate={fetchUserDetails}
              />
            </TabPane>
          </Tabs>
        </Spin>
      </Modal>
    </>
  );
};

export default UserDetail;
