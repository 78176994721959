// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#static_page {
  min-height: 100vh;
}
.static_inner {
  width: 800px;
  max-width: calc(100% - 32px);
  margin: 24px auto;
  background-color: #fff;
  padding: 64px;
}
#static_page h1 {
  font-size: 1.7rem;
}
#static_page h2 {
  font-size: 1.2rem;
  margin: 1.5em 0 1em;
}
#static_page h3 {
  font-size: 1.1rem;
}
#static_page li {
  line-height: 1.7;
}
#static_page li ol {
  list-style-type: disc;
}

#static_table {
  width: 100%;
}
#static_table th,
#static_table td {
  border: 1px solid #efefef;
  padding: 8px;
}
#static_table th {
  background: #fafafa;
  text-align: left;
}

@media (max-width: 800px) {
  .static_inner {
    padding: 24px;
  }
  #static_page h1 {
    font-size: 1.4rem;
  }
  #static_page h2 {
    font-size: 1.1rem;
    margin: 1.5em 0 1em;
  }
  #static_page h3 {
    font-size: 1rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/StaticPages/forStatic.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;AACA;EACE,YAAY;EACZ,4BAA4B;EAC5B,iBAAiB;EACjB,sBAAsB;EACtB,aAAa;AACf;AACA;EACE,iBAAiB;AACnB;AACA;EACE,iBAAiB;EACjB,mBAAmB;AACrB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,qBAAqB;AACvB;;AAEA;EACE,WAAW;AACb;AACA;;EAEE,yBAAyB;EACzB,YAAY;AACd;AACA;EACE,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE;IACE,aAAa;EACf;EACA;IACE,iBAAiB;EACnB;EACA;IACE,iBAAiB;IACjB,mBAAmB;EACrB;EACA;IACE,eAAe;EACjB;AACF","sourcesContent":["#static_page {\n  min-height: 100vh;\n}\n.static_inner {\n  width: 800px;\n  max-width: calc(100% - 32px);\n  margin: 24px auto;\n  background-color: #fff;\n  padding: 64px;\n}\n#static_page h1 {\n  font-size: 1.7rem;\n}\n#static_page h2 {\n  font-size: 1.2rem;\n  margin: 1.5em 0 1em;\n}\n#static_page h3 {\n  font-size: 1.1rem;\n}\n#static_page li {\n  line-height: 1.7;\n}\n#static_page li ol {\n  list-style-type: disc;\n}\n\n#static_table {\n  width: 100%;\n}\n#static_table th,\n#static_table td {\n  border: 1px solid #efefef;\n  padding: 8px;\n}\n#static_table th {\n  background: #fafafa;\n  text-align: left;\n}\n\n@media (max-width: 800px) {\n  .static_inner {\n    padding: 24px;\n  }\n  #static_page h1 {\n    font-size: 1.4rem;\n  }\n  #static_page h2 {\n    font-size: 1.1rem;\n    margin: 1.5em 0 1em;\n  }\n  #static_page h3 {\n    font-size: 1rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
