import React, { useEffect } from "react";
import { Result, Button } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import AuthenticatedRoute from "./AuthenticatedRoute";
import { Helmet } from "react-helmet";

const SubscriptionSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // ここでバックエンドAPIを呼び出して、セッションIDを検証し、
    // 必要な場合はユーザーの情報を更新します
  }, []);

  return (
    <>
      <Helmet>
        <title>プラン変更が完了しました | magicss</title>
        <meta name="robots" content="noindex" />
        <meta
          property="og:title"
          content={`プラン変更が完了しました | magicss`}
        />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/ogp.png`}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <AuthenticatedRoute>
        <Result
          status="success"
          title="プランの変更が完了しました！"
          subTitle="新しいプランでのご利用をお楽しみください。"
          extra={[
            <Button
              key="console"
              onClick={() => navigate("/subscription-management")}
            >
              サブスクリプション管理へ
            </Button>,
          ]}
        />
      </AuthenticatedRoute>
    </>
  );
};

export default SubscriptionSuccess;
