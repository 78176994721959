import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  message,
  Row,
  Col,
  Typography,
  Segmented,
} from "antd";
import { MailOutlined } from "@ant-design/icons";
import { registerEmail as registerEmailAuth } from "../../auth";
import styles from "./Auth.module.css";
import "../../index.css";
import LoginForm from "./LoginForm";
import RegisterEmailForm from "./RegisterEmail";

const { Title } = Typography;

const Auth = () => {
  const [authType, setAuthType] = useState("login");
  const navigate = useNavigate();
  const [utmParams, setUtmParams] = useState({});
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const params = {};
    ["utm_source", "utm_medium", "utm_campaign", "utm_content", "ref"].forEach(
      (param) => {
        const value = searchParams.get(param);
        if (value) {
          params[param] = value;
        }
      },
    );

    const storedParams = JSON.parse(localStorage.getItem("utmParams") || "{}");
    const combinedParams = { ...storedParams, ...params };

    if (Object.keys(combinedParams).length > 0) {
      setUtmParams(combinedParams);
      localStorage.setItem("utmParams", JSON.stringify(combinedParams));
    }
  }, [location]);

  const handleLogin = () => {
    message.success("ログインに成功しました");
    const from = utmParams.from_page || "/";
    navigate(from);
  };

  const handleRegisterEmail = async (values) => {
    try {
      const registerData = {
        email: values.email,
        utm_params: utmParams,
      };
      await registerEmailAuth(registerData);
      message.success("認証URLを送信しました。メールをご確認ください。");
      return true;
    } catch (error) {
      if (error.response && error.response.status === 400) {
        message.error(error.response.data.detail);
      } else {
        message.error("認証URLの送信に失敗しました");
      }
      return false;
    }
  };

  return (
    <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
      <Col xs={22} sm={18} md={16} lg={8}>
        <div
          className={styles.authContainer}
          style={{ boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)" }}
        >
          <div className="logo outside">
            <span>Ignite creativity with..</span>
            magicss
          </div>
          <Segmented
            options={[
              { label: "ログイン", value: "login" },
              { label: "新規登録", value: "register" },
            ]}
            value={authType}
            onChange={setAuthType}
            block
            size="large"
            className={styles.authSegmented}
          />
          <div className={styles.authContent}>
            {authType === "login" ? (
              <LoginForm onFinish={handleLogin} utmParams={utmParams} />
            ) : (
              <RegisterEmailForm
                onFinish={handleRegisterEmail}
                utmParams={utmParams}
              />
            )}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Auth;
