import React, { useState } from 'react';
import { Form, Input, Button, Steps, Typography, message } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { Helmet } from 'react-helmet';

const { Text } = Typography;

const RegisterEmailForm = ({ onFinish }) => {
  const [form] = Form.useForm();
  const [emailSent, setEmailSent] = useState(false);
  const [submittedEmail, setSubmittedEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async values => {
    setLoading(true);
    try {
      const params = new URLSearchParams(window.location.search);
      const utmParams = {
        utm_source: params.get('utm_source'),
        utm_medium: params.get('utm_medium'),
        utm_campaign: params.get('utm_campaign'),
        utm_content: params.get('utm_content'),
        ref: params.get('ref'),
        from_page: params.get('from'),
      };

      const data = {
        email: values.email,
        utm_params: Object.keys(utmParams).reduce((acc, key) => {
          if (utmParams[key]) acc[key] = utmParams[key];
          return acc;
        }, {}),
      };

      const success = await onFinish(data);
      if (success) {
        setEmailSent(true);
        setSubmittedEmail(values.email);
        form.resetFields();
      } else {
        setEmailSent(false);
      }
    } catch (error) {
      console.error('Error in handleSubmit:', error);
      message.error('登録に失敗しました。もう一度お試しください。');
      setEmailSent(false);
    } finally {
      setLoading(false);
    }
  };

  const steps = [
    { title: '認証URLの送信' },
    { title: 'メール確認/認証' },
    { title: '情報入力(約2分)' },
  ];

  return (
    <>
      <Helmet>
        <title>新規ユーザー登録 | magicss</title>
        <meta name="robots" content="noindex" />
        <meta property="og:title" content={`新規ユーザー登録 | magicss`} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/ogp.png`}
        />
        <meta
          property="og:description"
          content={`コンテンツ生成AIツール「magicss」の新規ユーザー登録ページです。`}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Steps
        current={emailSent ? 1 : 0}
        items={steps}
        labelPlacement="vertical"
        size="small"
        style={{ marginBottom: '28px' }}
      />
      {!emailSent ? (
        <Form form={form} onFinish={handleSubmit}>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: 'メールアドレスを入力してください' },
              {
                type: 'email',
                message: '有効なメールアドレスを入力してください',
              },
            ]}
          >
            <Input
              placeholder="メールアドレス"
              size="large"
              style={{
                border: '2px solid #f5f5f5',
                backgroundColor: '#f5f5f5',
                padding: '8px',
                transition: 'all 0.3s',
              }}
              onMouseEnter={e => {
                e.currentTarget.style.backgroundColor = '#eee';
              }}
              onMouseLeave={e => {
                e.currentTarget.style.backgroundColor = '#f5f5f5';
              }}
              onFocus={e => {
                e.currentTarget.style.border = '2px solid #0072ff';
              }}
              onBlur={e => {
                e.currentTarget.style.border = '2px solid #f5f5f5';
              }}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              // disabled
              block
              size="large"
              style={{
                background: 'linear-gradient(90deg, #0072ff 0%, #00d4ff 100%)',
                border: 'none',
                transition: 'all 0.3s',
                width: '100%',
                fontWeight: 'bold',
              }}
              onMouseEnter={e => {
                e.currentTarget.style.opacity = '0.7';
              }}
              onMouseLeave={e => {
                e.currentTarget.style.opacity = '1.0';
              }}
            >
              認証用URLを送信する
              <SendOutlined
                style={{
                  marginLeft: 'auto',
                  right: '10px',
                  position: 'absolute',
                  top: '50%',
                  transform: 'translate(0, -50%)',
                  fontSize: '14px',
                }}
              />
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <Text style={{ marginTop: '16px', fontSize: '14px' }}>
          {submittedEmail}
          <br />
          <br />
          上記メールアドレス宛にメールを送信しました。
          <br />
          30分以内に認証URLをクリックして登録を完了させてください。
        </Text>
      )}
    </>
  );
};

export default RegisterEmailForm;
