import React, { useState, useEffect } from "react";
import {
  Typography,
  Row,
  Col,
  Card,
  Space,
  Divider,
  Button,
  Table,
  Tag,
  Modal,
  message,
  Spin,
  Alert,
} from "antd";
import {
  FireOutlined,
  ExclamationCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { getUserProfileWithStripeInfo } from "../auth";
import { api } from "../api";
import { Helmet } from "react-helmet";
import moment from "moment-timezone";

const { Title, Text, Paragraph } = Typography;

const SubscriptionManagement = () => {
  const [userProfile, setUserProfile] = useState(null);
  const [stripeInfo, setStripeInfo] = useState(null);
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const [
        profileWithStripeInfo,
        subscriptionResponse,
        transactionsResponse,
      ] = await Promise.all([
        getUserProfileWithStripeInfo(),
        api.get("/subscriptions/current-subscription"),
        api.get("/subscriptions/transactions"),
      ]);
      setUserProfile(profileWithStripeInfo);
      setStripeInfo({
        last4: profileWithStripeInfo.last4,
        brand: profileWithStripeInfo.brand,
      });
      setCurrentSubscription(subscriptionResponse.data);
      setTransactions(transactionsResponse.data);
      console.log(currentSubscription);
    } catch (error) {
      console.error("Failed to fetch data:", error);
      message.error("データの取得に失敗しました");
    } finally {
      setLoading(false);
    }
  };

  const handleCancelSubscription = async () => {
    try {
      await api.post("/subscriptions/cancel-subscription");
      message.success("サブスクリプションをキャンセルしました");
      fetchData();
    } catch (error) {
      console.error("Failed to cancel subscription:", error);
      message.error("サブスクリプションのキャンセルに失敗しました");
    }
    setCancelModalVisible(false);
  };

  const handleChangePaymentMethod = async () => {
    try {
      const response = await api.post("/subscriptions/create-portal-session");
      if (response.data && response.data.url) {
        window.location.href = response.data.url;
      } else {
        throw new Error("Invalid response from server");
      }
    } catch (error) {
      console.error("Failed to create portal session:", error);
      message.error(
        "決済方法の変更に失敗しました。管理者にお問い合わせください。",
      );
    }
  };

  const planTags = {
    free: { color: "blue", text: "フリープラン" },
    standard: { color: "green", text: "スタンダードプラン" },
    pro: { color: "orange", text: "プロプラン" },
    premium: { color: "gold", text: "プレミアムプラン" },
    custom: { color: "purple", text: "カスタムプラン" },
  };

  const planNameMap = {
    free: "フリープラン",
    standard: "スタンダードプラン",
    pro: "プロプラン",
    premium: "プレミアムプラン",
    custom: "カスタムプラン",
  };

  const getNextUpdateDate = () => {
    if (!currentSubscription) return "N/A";

    const nextTicketUpdate = moment.utc(currentSubscription.next_ticket_update);
    const now = moment.utc();

    // フリープランの場合、last_ticket_update_at から30日後に更新
    if (
      currentSubscription.plan.type === "free" &&
      currentSubscription.last_ticket_update_at
    ) {
      const lastUpdate = moment.utc(currentSubscription.last_ticket_update_at);
      const nextUpdate = lastUpdate.add(30, "days");
      if (nextUpdate.isAfter(now)) {
        return nextUpdate.local().format("YYYY年MM月DD日");
      }
    }
    // 他のプランの場合
    else if (nextTicketUpdate.isValid() && nextTicketUpdate.isAfter(now)) {
      return nextTicketUpdate.local().format("YYYY年MM月DD日");
    }

    return "更新予定なし";
  };

  const getTicketUpdateCount = () => {
    if (!userProfile || !currentSubscription) return 0;

    if (currentSubscription.plan.type === "custom") {
      return currentSubscription.custom_data?.is_monthly_update
        ? currentSubscription.custom_data.monthly_tickets
        : 0;
    } else if (currentSubscription.plan.type === "free") {
      return 0; // フリープランは0枚更新
    } else {
      return currentSubscription.plan.monthly_tickets;
    }
  };

  const isSubscriptionEnding = () => {
    if (!currentSubscription) return false;
    if (currentSubscription.plan.type === "free") return false;
    return currentSubscription.is_final_update;
  };

  const CancelSubscriptionModal = ({
    visible,
    onCancel,
    onConfirm,
    subscription,
  }) => {
    const isYearly = subscription?.is_yearly;
    const nextRenewalDate = subscription
      ? moment(subscription.current_period_end).format("YYYY年MM月DD日")
      : "N/A";

    return (
      <Modal
        visible={visible}
        title={
          <Title level={4} style={{ marginBottom: 0 }}>
            <ExclamationCircleOutlined
              style={{ color: "#faad14", marginRight: 8 }}
            />
            サブスクリプションのキャンセル
          </Title>
        }
        onCancel={onCancel}
        footer={[
          <Button key="back" onClick={onCancel}>
            戻る
          </Button>,
          <Button key="submit" type="primary" danger onClick={onConfirm}>
            キャンセルを確定
          </Button>,
        ]}
        width={600}
      >
        <div style={{ marginTop: "20px" }}>
          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            <Alert
              message="キャンセルの影響"
              description={
                <ul style={{ paddingLeft: "20px", marginBottom: 0 }}>
                  <li>現在のプランは{nextRenewalDate}まで利用可能です。</li>
                  <li>
                    {nextRenewalDate}以降は自動的にフリープランに変更されます。
                  </li>
                  <li>フリープランでは機能が制限されます。</li>
                </ul>
              }
              type="warning"
              showIcon
            />

            {isYearly && (
              <Alert
                message="年払いプランに関する重要な注意"
                description={
                  <ul style={{ paddingLeft: "20px", marginBottom: 0 }}>
                    <li>
                      <strong>未使用のチケットについて：</strong>{" "}
                      {nextRenewalDate}
                      時点で使用されなかったチケットは無効となります。お早めにご利用ください。
                    </li>
                    <li>
                      <strong>返金について：</strong>{" "}
                      年払いプランのキャンセルに伴う返金は行っておりません。
                    </li>
                  </ul>
                }
                type="error"
                showIcon
                icon={<WarningOutlined />}
              />
            )}

            <div style={{ marginTop: "10px" }}>
              <Paragraph>
                <Text strong>キャンセル後の動作：</Text>
              </Paragraph>
              <ul style={{ marginTop: "5px", marginBottom: "10px" }}>
                <li>現在の保有チケットは{nextRenewalDate}まで使用できます。</li>
                <li>{nextRenewalDate}以降、チケットは更新されなくなります。</li>
                <li>
                  フリープランの制限内で引き続きサービスをご利用いただけます。
                </li>
              </ul>
            </div>

            <Divider style={{ margin: "10px 0" }} />

            <Paragraph>
              <Text type="secondary">
                キャンセルを確定する前に、もう一度ご確認ください。キャンセル後も
                {nextRenewalDate}までは現在のプランをご利用いただけます。
              </Text>
            </Paragraph>
          </Space>
        </div>
      </Modal>
    );
  };

  const columns = [
    {
      title: "日付",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) =>
        moment(date).tz("Asia/Tokyo").format("YYYY/MM/DD HH:mm"),
    },
    {
      title: "金額（税込）",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => `¥${amount.toLocaleString()}`,
    },
    {
      title: "ステータス",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag color={status === "succeeded" ? "green" : "red"}>
          {status === "succeeded" ? "成功" : "失敗"}
        </Tag>
      ),
    },
    {
      title: "内容",
      dataIndex: "plan_name",
      key: "plan_name",
      render: (planName, record) => {
        const planType = planNameMap[planName] || planName;
        const transactionType = record.is_new ? "新規" : "継続";
        if (planName === "custom") {
          return `${planType}`;
        } else if (planName === "free") {
          return `${planType}`;
        } else {
          const paymentType = record.is_yearly ? "年払い" : "月払い";
          return `${planType}／${paymentType}`;
        }
      },
    },
  ];

  if (loading) {
    return <Spin size="large" />;
  }

  return (
    <>
      <Helmet>
        <title>サブスクリプション管理 | SEO記事生成モード - magicss`</title>
        <meta name="robots" content="noindex" />
        <meta
          property="og:title"
          content={`サブスクリプション管理 | SEO記事生成モード - magicss`}
        />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/ogp.png`}
        />
        <meta
          property="og:description"
          content={`コンテンツ生成AIツール「magicss」のサブスクリプション管理ページです。`}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <div>
        <Text style={{ margin: "16px 0" }}>ユーザー設定</Text>
        <Title level={1} className="gradient-text" style={{ fontSize: "24px" }}>
          サブスクリプション管理
        </Title>
        <Text style={{ color: "#6d8eb7" }}>
          次回更新日やチケットの更新日、決済方法の変更などサブスクリプションの管理を行なえます。
        </Text>
        <Divider style={{ margin: "24px 0" }} />

        <Row gutter={[16, 16]}>
          <Col xs={24} lg={8}>
            <Card title="現在のプラン">
              {currentSubscription && (
                <>
                  <Tag
                    color={
                      planTags[currentSubscription.plan.type]?.color ||
                      "default"
                    }
                  >
                    {planTags[currentSubscription.plan.type]?.text}
                    {currentSubscription.plan.type !== "free" &&
                      currentSubscription.plan.type !== "custom" &&
                      `（${currentSubscription.is_yearly ? "年払い" : "月払い"}）`}
                  </Tag>
                  <Divider />
                  {currentSubscription.plan.type !== "free" && (
                    <>
                      <Text>
                        {currentSubscription.plan.type === "custom"
                          ? "有効期限"
                          : "次回更新日"}
                        ：
                        {moment(currentSubscription.current_period_end).format(
                          "YYYY年MM月DD日",
                        )}
                      </Text>
                      {currentSubscription.status === "canceling" && (
                        <Text type="warning" style={{ marginLeft: "8px" }}>
                          （自動キャンセル）
                        </Text>
                      )}
                      {currentSubscription &&
                        currentSubscription.plan.type !== "custom" &&
                        currentSubscription.status === "active" && (
                          <Button
                            onClick={() => setCancelModalVisible(true)}
                            size="small"
                            style={{ display: "block", marginTop: "10px" }}
                          >
                            キャンセルする
                          </Button>
                        )}
                    </>
                  )}
                  {currentSubscription.plan.type === "free" && (
                    <Text>フリープランには有効期限はありません。</Text>
                  )}
                </>
              )}
            </Card>
          </Col>
          <Col xs={24} lg={8}>
            <Card title="チケット">
              {userProfile && currentSubscription && (
                <>
                  <Text>
                    チケット残り：
                    <FireOutlined style={{ color: "#0072ff" }} />
                    <Text
                      style={{
                        color: "#0072ff",
                        margin: "0 4px 0 4px",
                        fontSize: "16px",
                      }}
                    >
                      {userProfile.ticket_count}
                    </Text>
                    枚
                  </Text>
                  <Divider />
                  {getNextUpdateDate() !== "更新予定なし" && (
                    <>
                      <Text style={{ display: "block", marginBottom: "9px" }}>
                        次回チケット更新：
                      </Text>
                      {currentSubscription.plan.type === "free" ? (
                        <Text>
                          {getNextUpdateDate()}に
                          <FireOutlined style={{ color: "#0072ff" }} />{" "}
                          0枚にリセット
                        </Text>
                      ) : (
                        <Text>
                          {getNextUpdateDate()}に
                          {isSubscriptionEnding() ? (
                            <>
                              <FireOutlined style={{ color: "#0072ff" }} />{" "}
                              0枚にリセット
                            </>
                          ) : (
                            <>
                              <FireOutlined style={{ color: "#0072ff" }} />{" "}
                              {getTicketUpdateCount()}枚に更新
                            </>
                          )}
                        </Text>
                      )}
                    </>
                  )}
                </>
              )}
            </Card>
          </Col>
          <Col xs={24} lg={8}>
            <Card title="決済方法">
              {userProfile && (
                <>
                  <div
                    style={{
                      background: "linear-gradient(45deg, #e5f3ff, #ffeadc)",
                      borderRadius: "10px",
                      padding: "10px",
                      textAlign: "center",
                      marginBottom: "16px",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "14px",
                        marginRight: "16px",
                        color: "#647a7a",
                      }}
                    >
                      {stripeInfo.brand
                        ? `${stripeInfo.brand.toUpperCase()}`
                        : "カード"}
                    </Text>
                    <Text
                      style={{
                        fontSize: "18px",
                        letterSpacing: "2px",
                        color: "#647a7a",
                      }}
                    >
                      {stripeInfo.last4
                        ? `**** **** **** ${stripeInfo.last4}`
                        : "未登録"}
                    </Text>
                  </div>
                  <Button onClick={handleChangePaymentMethod} block>
                    決済方法の変更・履歴の確認
                  </Button>
                  <Text
                    style={{
                      textAlign: "center",
                      marginTop: "9px",
                      display: "block",
                    }}
                  >
                    ※領収書等もご確認いただけます
                  </Text>
                </>
              )}
            </Card>
          </Col>
        </Row>

        <Title level={3} style={{ margin: "32px 0 16px", fontSize: "18px" }}>
          決済履歴
        </Title>
        <Table
          columns={columns}
          dataSource={transactions}
          rowKey="id"
          scroll={{ x: "max-content" }}
        />

        <CancelSubscriptionModal
          visible={cancelModalVisible}
          onCancel={() => setCancelModalVisible(false)}
          onConfirm={handleCancelSubscription}
          subscription={currentSubscription}
        />
      </div>
    </>
  );
};

export default SubscriptionManagement;
