import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  message,
  Row,
  Col,
  Typography,
  Alert,
  Checkbox,
  Steps,
  Divider,
  Select,
  ConfigProvider,
} from "antd";
import { theme } from "antd";
import { registerProfile } from "../../auth";
import styles from "./Auth.module.css";
import { api } from "../../api";
import { Helmet } from "react-helmet";

const { Title, Text } = Typography;
const { Option } = Select;

const RegisterProfile = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const { token } = useParams();
  const [emailConfirmed, setEmailConfirmed] = useState(false);
  const [tokenExpired, setTokenExpired] = useState(false);
  const [utmParams, setUtmParams] = useState({});
  const { useToken } = theme;

  const customFields = [
    {
      question: "あなたについて教えてください。",
      options: [
        "事業会社",
        "SEO支援会社",
        "制作会社",
        "ライター/編集者",
        "その他",
      ],
    },
    {
      question: "コンテンツSEO(記事)の実施状況",
      options: [
        "自社/自分で制作している",
        "他社に制作依頼している",
        "実施を検討している",
        "分からない",
      ],
    },
    {
      question: "記事制作におけるAIの活用状況",
      options: [
        "複数のAIツールを有効活用している",
        "ChatGPT等は使っているがまだまだ",
        "あまり活用できていない",
        "全く活用できていない",
      ],
    },
  ];

  useEffect(() => {
    const storedParams = JSON.parse(localStorage.getItem("utmParams") || "{}");
    setUtmParams(storedParams);
  }, []);

  useEffect(() => {
    if (!token) {
      navigate("/login", { replace: true });
    }
    const confirmEmail = async () => {
      try {
        const response = await api.get(`/auth/confirm-email/${token}`);
        setEmailConfirmed(true);
        setEmail(response.data.email);
        message.success("メールアドレスの認証が完了しました。");
      } catch (error) {
        if (error.response && error.response.status === 400) {
          setEmailConfirmed(false);
          setTokenExpired(true);
          message.error(
            "トークンが無効または期限切れです。再度登録をお試しください。",
          );
          setTimeout(() => {
            navigate("/login");
          }, 3000);
        } else {
          message.error("無効なトークンです。再度登録をお試しください。");
          navigate("/login");
        }
      }
    };
    confirmEmail();
  }, [token, navigate]);

  const handleRegisterProfile = async (values) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("email", email);
      formData.append("last_name", values.full_name.last_name);
      formData.append("first_name", values.full_name.first_name);
      formData.append("last_name_kana", values.full_name_kana.last_name_kana);
      formData.append("first_name_kana", values.full_name_kana.first_name_kana);
      if (values.company_name)
        formData.append("company_name", values.company_name);
      if (values.department_name)
        formData.append("department_name", values.department_name);
      formData.append("password", values.password);
      formData.append("confirm_password", values.confirm_password);
      customFields.forEach((field, index) => {
        formData.append(
          `custom_field_${index + 1}`,
          values[`custom_field_${index + 1}`],
        );
      });
      formData.append("agreed_to_terms", values.agreed_to_terms);
      formData.append(
        "agreed_to_privacy_policy",
        values.agreed_to_privacy_policy,
      );
      formData.append("token", token);
      formData.append("is_active", "true"); // is_activeをtrueに設定

      console.log("Sending form data:", Object.fromEntries(formData.entries()));

      Object.entries(utmParams).forEach(([key, value]) => {
        formData.append(key, value);
      });

      await registerProfile(formData);
      message.success("会員登録が完了しました。ログインしてください。");
      navigate("/login");
    } catch (error) {
      console.error("Registration error:", error);
      if (error.response && error.response.data && error.response.data.detail) {
        message.error(`会員登録に失敗しました: ${error.response.data.detail}`);
      } else {
        message.error("会員登録に失敗しました");
      }
    }
    setLoading(false);
  };

  const steps = [
    {
      title: "認証URLの送信",
    },
    {
      title: "メール確認/認証",
    },
    {
      title: "情報入力(約2分)",
    },
  ];

  const selectStyle = {
    width: "100%",
  };

  const customSelectTokens = {
    Select: {
      colorBgContainer: token.colorFillTertiary,
      colorBorder: token.colorFillTertiary,
      selectorBg: "#f5f5f5",
      controlHeight: 48,
      controlOutline: "none",
      activeBorderColor: "#0072ff",
    },
  };

  return (
    <>
      <Helmet>
        <title>新規ユーザー登録 | magicss</title>
        <meta name="robots" content="noindex" />
        <meta property="og:title" content={`新規ユーザー登録 | magicss`} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/ogp.png`}
        />
        <meta
          property="og:description"
          content={`コンテンツ生成AIツール「magicss」の新規ユーザー登録ページです。`}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
        <Col xs={22} sm={20} md={18} lg={10}>
          <div
            className={styles.authContainer}
            style={{ boxShadow: "0 0 50px 0 rgba(0, 0, 0, 0.1)" }}
          >
            <div className="logo outside">
              <span>Ignite creativity with..</span>
              magicss
            </div>
            <Text
              style={{
                color: "#ff0000",
                textAlign: "center",
                marginBottom: "24px",
              }}
            >
              ※※※まだ登録は完了していません※※※
            </Text>
            <Title
              level={1}
              style={{
                fontSize: "18px",
                margin: "0 auto 24px",
                display: "inline-block",
              }}
            >
              新規無料ユーザー登録
            </Title>
            <Steps
              current={2}
              items={steps}
              labelPlacement="vertical"
              size="small"
              style={{ marginBottom: "28px" }}
            />
            {tokenExpired ? (
              <Alert
                message="無効なトークン"
                description="トークンの有効期限が切れています。再度登録をお試しください。"
                type="error"
                showIcon
                action={
                  <Button
                    type="primary"
                    onClick={() => navigate("/register-email")}
                  >
                    新規登録
                  </Button>
                }
              />
            ) : emailConfirmed ? (
              <>
                <Text style={{ marginBottom: "24px", textAlign: "center" }}>
                  以下のフォームに必要事項を入力し、15分以内に会員登録を完了してください。
                </Text>
                <Divider
                  style={{ margin: "0 0 24px", borderColor: "#f5f5f5" }}
                />
                <ConfigProvider
                  theme={{
                    components: customSelectTokens,
                  }}
                >
                  <Form
                    onFinish={handleRegisterProfile}
                    layout="vertical"
                    autoComplete="on"
                    id="registerForm"
                  >
                    <Row gutter={16}>
                      <Col xs={24} lg={12}>
                        <Form.Item
                          name="company_name"
                          label={
                            <span
                              className={styles.label}
                              style={{ color: "#999", fontSize: "12px" }}
                            >
                              会社名
                            </span>
                          }
                        >
                          <Input
                            placeholder="会社名"
                            size="large"
                            style={{
                              border: "2px solid #f5f5f5",
                              backgroundColor: "#f5f5f5",
                              padding: "8px",
                              transition: "all 0.3s",
                            }}
                            onMouseEnter={(e) => {
                              e.currentTarget.style.backgroundColor = "#eee";
                            }}
                            onMouseLeave={(e) => {
                              e.currentTarget.style.backgroundColor = "#f5f5f5";
                            }}
                            onFocus={(e) => {
                              e.currentTarget.style.border =
                                "2px solid #0072ff";
                            }}
                            onBlur={(e) => {
                              e.currentTarget.style.border =
                                "2px solid #f5f5f5";
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} lg={12}>
                        <Form.Item
                          name="department_name"
                          label={
                            <span
                              className={styles.label}
                              style={{ color: "#999", fontSize: "12px" }}
                            >
                              部署名
                            </span>
                          }
                        >
                          <Input
                            placeholder="部署名"
                            size="large"
                            style={{
                              border: "2px solid #f5f5f5",
                              backgroundColor: "#f5f5f5",
                              padding: "8px",
                              transition: "all 0.3s",
                            }}
                            onMouseEnter={(e) => {
                              e.currentTarget.style.backgroundColor = "#eee";
                            }}
                            onMouseLeave={(e) => {
                              e.currentTarget.style.backgroundColor = "#f5f5f5";
                            }}
                            onFocus={(e) => {
                              e.currentTarget.style.border =
                                "2px solid #0072ff";
                            }}
                            onBlur={(e) => {
                              e.currentTarget.style.border =
                                "2px solid #f5f5f5";
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={24} lg={12}>
                        <Form.Item
                          name={["full_name", "last_name"]}
                          label={
                            <span
                              className={styles.label}
                              style={{ color: "#999", fontSize: "12px" }}
                            >
                              姓
                            </span>
                          }
                          rules={[
                            { required: true, message: "姓を入力してください" },
                          ]}
                        >
                          <Input
                            placeholder="姓"
                            size="large"
                            style={{
                              border: "2px solid #f5f5f5",
                              backgroundColor: "#f5f5f5",
                              padding: "8px",
                              transition: "all 0.3s",
                            }}
                            onMouseEnter={(e) => {
                              e.currentTarget.style.backgroundColor = "#eee";
                            }}
                            onMouseLeave={(e) => {
                              e.currentTarget.style.backgroundColor = "#f5f5f5";
                            }}
                            onFocus={(e) => {
                              e.currentTarget.style.border =
                                "2px solid #0072ff";
                            }}
                            onBlur={(e) => {
                              e.currentTarget.style.border =
                                "2px solid #f5f5f5";
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} lg={12}>
                        <Form.Item
                          name={["full_name", "first_name"]}
                          label={
                            <span
                              className={styles.label}
                              style={{ color: "#999", fontSize: "12px" }}
                            >
                              名
                            </span>
                          }
                          rules={[
                            { required: true, message: "名を入力してください" },
                          ]}
                        >
                          <Input
                            placeholder="名"
                            size="large"
                            style={{
                              border: "2px solid #f5f5f5",
                              backgroundColor: "#f5f5f5",
                              padding: "8px",
                              transition: "all 0.3s",
                            }}
                            onMouseEnter={(e) => {
                              e.currentTarget.style.backgroundColor = "#eee";
                            }}
                            onMouseLeave={(e) => {
                              e.currentTarget.style.backgroundColor = "#f5f5f5";
                            }}
                            onFocus={(e) => {
                              e.currentTarget.style.border =
                                "2px solid #0072ff";
                            }}
                            onBlur={(e) => {
                              e.currentTarget.style.border =
                                "2px solid #f5f5f5";
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={24} lg={12}>
                        <Form.Item
                          name={["full_name_kana", "last_name_kana"]}
                          label={
                            <span
                              className={styles.label}
                              style={{ color: "#999", fontSize: "12px" }}
                            >
                              姓（カタカナ）
                            </span>
                          }
                          rules={[
                            {
                              required: true,
                              message: "姓（カタカナ）を入力してください",
                            },
                          ]}
                        >
                          <Input
                            placeholder="姓（カタカナ）"
                            size="large"
                            style={{
                              border: "2px solid #f5f5f5",
                              backgroundColor: "#f5f5f5",
                              padding: "8px",
                              transition: "all 0.3s",
                            }}
                            onMouseEnter={(e) => {
                              e.currentTarget.style.backgroundColor = "#eee";
                            }}
                            onMouseLeave={(e) => {
                              e.currentTarget.style.backgroundColor = "#f5f5f5";
                            }}
                            onFocus={(e) => {
                              e.currentTarget.style.border =
                                "2px solid #0072ff";
                            }}
                            onBlur={(e) => {
                              e.currentTarget.style.border =
                                "2px solid #f5f5f5";
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} lg={12}>
                        <Form.Item
                          name={["full_name_kana", "first_name_kana"]}
                          label={
                            <span
                              className={styles.label}
                              style={{ color: "#999", fontSize: "12px" }}
                            >
                              名（カタカナ）
                            </span>
                          }
                          rules={[
                            {
                              required: true,
                              message: "名（カタカナ）を入力してください",
                            },
                          ]}
                        >
                          <Input
                            placeholder="名（カタカナ）"
                            size="large"
                            style={{
                              border: "2px solid #f5f5f5",
                              backgroundColor: "#f5f5f5",
                              padding: "8px",
                              transition: "all 0.3s",
                            }}
                            onMouseEnter={(e) => {
                              e.currentTarget.style.backgroundColor = "#eee";
                            }}
                            onMouseLeave={(e) => {
                              e.currentTarget.style.backgroundColor = "#f5f5f5";
                            }}
                            onFocus={(e) => {
                              e.currentTarget.style.border =
                                "2px solid #0072ff";
                            }}
                            onBlur={(e) => {
                              e.currentTarget.style.border =
                                "2px solid #f5f5f5";
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Divider
                      style={{ margin: "0 0 24px", borderColor: "#f5f5f5" }}
                    />
                    {customFields.map((field, index) => (
                      <Form.Item
                        key={index}
                        name={`custom_field_${index + 1}`}
                        label={
                          <span
                            style={{
                              color: token.colorTextLabel,
                              fontSize: token.fontSizeSM,
                            }}
                          >
                            {field.question}
                          </span>
                        }
                        rules={[
                          { required: true, message: "選択してください" },
                        ]}
                      >
                        <Select
                          placeholder="選択してください"
                          style={selectStyle}
                        >
                          {field.options.map((option, optionIndex) => (
                            <Option key={optionIndex} value={option}>
                              {option}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    ))}
                    <Divider
                      style={{ margin: "0 0 24px", borderColor: "#f5f5f5" }}
                    />
                    <Row gutter={16}>
                      <Col xs={24} lg={12}>
                        <Form.Item
                          name="password"
                          label={
                            <span
                              className={styles.label}
                              style={{ color: "#999", fontSize: "12px" }}
                            >
                              パスワード
                            </span>
                          }
                          rules={[
                            {
                              required: true,
                              message: "パスワードを入力してください",
                            },
                            {
                              min: 8,
                              message:
                                "パスワードは8文字以上で入力してください",
                            },
                          ]}
                        >
                          <div
                            style={{
                              border: "2px solid #f5f5f5",
                              backgroundColor: "#f5f5f5",
                              transition: "all 0.3s",
                              borderRadius: "8px", // 角丸を追加
                            }}
                            onMouseEnter={(e) => {
                              e.currentTarget.style.backgroundColor = "#eee";
                            }}
                            onMouseLeave={(e) => {
                              e.currentTarget.style.backgroundColor = "#f5f5f5";
                            }}
                            onFocus={(e) => {
                              e.currentTarget.style.border =
                                "2px solid #0072ff";
                            }}
                            onBlur={(e) => {
                              e.currentTarget.style.border =
                                "2px solid #f5f5f5";
                            }}
                          >
                            <Input.Password
                              placeholder="パスワード"
                              autoComplete="new-password"
                              size="large"
                              style={{
                                border: "none",
                                backgroundColor: "transparent",
                                outline: "none",
                                padding: "8px",
                              }}
                            />
                          </div>
                        </Form.Item>
                      </Col>
                      <Col xs={24} lg={12}>
                        <Form.Item
                          name="confirm_password"
                          label={
                            <span
                              className={styles.label}
                              style={{ color: "#999", fontSize: "12px" }}
                            >
                              パスワード（確認用）
                            </span>
                          }
                          dependencies={["password"]}
                          rules={[
                            {
                              required: true,
                              message: "パスワード（確認用）を入力してください",
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (
                                  !value ||
                                  getFieldValue("password") === value
                                ) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error("パスワードが一致しません"),
                                );
                              },
                            }),
                          ]}
                        >
                          <div
                            style={{
                              border: "2px solid #f5f5f5",
                              backgroundColor: "#f5f5f5",
                              transition: "all 0.3s",
                              borderRadius: "8px", // 角丸を追加
                            }}
                            onMouseEnter={(e) => {
                              e.currentTarget.style.backgroundColor = "#eee";
                            }}
                            onMouseLeave={(e) => {
                              e.currentTarget.style.backgroundColor = "#f5f5f5";
                            }}
                            onFocus={(e) => {
                              e.currentTarget.style.border =
                                "2px solid #0072ff";
                            }}
                            onBlur={(e) => {
                              e.currentTarget.style.border =
                                "2px solid #f5f5f5";
                            }}
                          >
                            <Input.Password
                              placeholder="パスワード（確認用）"
                              autoComplete="new-password"
                              size="large"
                              style={{
                                border: "none",
                                backgroundColor: "transparent",
                                outline: "none",
                                padding: "8px",
                              }}
                            />
                          </div>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Divider
                      style={{ margin: "0 0 24px", borderColor: "#f5f5f5" }}
                    />
                    <Form.Item
                      name="agreed_to_terms"
                      valuePropName="checked"
                      rules={[
                        {
                          validator: (_, value) =>
                            value
                              ? Promise.resolve()
                              : Promise.reject(
                                  new Error("利用規約に同意する必要があります"),
                                ),
                        },
                      ]}
                    >
                      <Checkbox>
                        <a
                          href="/terms-of-service"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          利用規約
                        </a>
                        に同意する
                      </Checkbox>
                    </Form.Item>

                    <Form.Item
                      name="agreed_to_privacy_policy"
                      valuePropName="checked"
                      rules={[
                        {
                          validator: (_, value) =>
                            value
                              ? Promise.resolve()
                              : Promise.reject(
                                  new Error(
                                    "プライバシーポリシーに同意する必要があります",
                                  ),
                                ),
                        },
                      ]}
                    >
                      <Checkbox>
                        <a
                          href="/privacy-policy"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          プライバシーポリシー
                        </a>
                        に同意する
                      </Checkbox>
                    </Form.Item>

                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        block
                        size="large"
                        style={{
                          background:
                            "linear-gradient(90deg, #0072ff 0%, #00d4ff 100%)",
                          border: "none",
                          transition: "all 0.3s",
                          width: "100%",
                          fontWeight: "bold",
                        }}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.opacity = "0.7";
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.opacity = "1.0";
                        }}
                      >
                        無料登録を完了する
                      </Button>
                    </Form.Item>
                  </Form>
                </ConfigProvider>
              </>
            ) : (
              <Alert
                message="メールアドレスの確認中"
                description="メールアドレスの確認中です。しばらくお待ちください。"
                type="info"
                showIcon
              />
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default RegisterProfile;
