import React from "react";
import { Typography, Layout, Button, Divider } from "antd";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import HubSpotForms from "./HubSpotForms";
import "./forStatic.css";

const { Title, Paragraph } = Typography;
const { Header, Content } = Layout;

const ContactUs = ({ isAuthenticated }) => {
  const renderHeader = () => (
    <Header
      style={{
        background: "#fff",
        padding: "0 16px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div style={{ flex: 1 }}></div>
      <div
        className="logo mobile-logo"
        style={{ textAlign: "center", flex: 1 }}
      >
        <span>Ignite creativity with..</span>magicss
      </div>
      <div style={{ flex: 1, textAlign: "right" }}>
        {!isAuthenticated && (
          <Button type="primary">
            <Link to="/login">無料登録</Link>
          </Button>
        )}
      </div>
    </Header>
  );

  return (
    <>
      <Helmet>
        <title>お問い合わせ | magicss</title>
        <meta name="robots" content="noindex" />
        <meta property="og:title" content={`お問い合わせ | magicss`} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/ogp.png`}
        />
        <meta
          property="og:description"
          content={`コンテンツ生成AIツール「magicss」のお問い合わせフォームです。`}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Layout id="static_page">
        {renderHeader()}
        <Content className="static_inner">
          <HubSpotForms formType="regular" />
        </Content>
      </Layout>
    </>
  );
};

export default ContactUs;
