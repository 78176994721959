import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';
import {
  Typography,
  Layout,
  Menu,
  Button,
  Modal,
  Input,
  Segmented,
  message,
  Spin,
  Dropdown,
  Skeleton,
  Row,
  Col,
  Tabs,
  Tooltip,
  Progress,
  Checkbox,
  Divider,
  Table,
  Collapse,
  Breadcrumb,
  Tag,
  Affix,
  Switch,
  Space,
  Grid,
  Drawer,
} from 'antd';
import {
  LockOutlined,
  InfoCircleOutlined,
  RadarChartOutlined,
  CaretDownOutlined,
  ContactsOutlined,
  BulbOutlined,
  ProductOutlined,
  BarsOutlined,
  EditOutlined,
  CopyOutlined,
  CodeOutlined,
  RobotOutlined,
  FormOutlined,
  DatabaseOutlined,
  MenuOutlined,
  ClusterOutlined,
  ExportOutlined,
} from '@ant-design/icons';
import axios from 'axios';
import { Radar } from 'react-chartjs-2';
import { Helmet } from 'react-helmet';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip as ChartTooltip,
  Legend,
} from 'chart.js';
import StructuredHeadings from './StructuredHeadings';
import AuthenticatedMenu from '../../components/AuthenticatedMenu';
import './ArticleDetail.css';
import { getUserRole, getUserProfile } from '../../auth';
import { setAuthToken } from '../../api';
import moment from 'moment-timezone';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  ChartTooltip,
  Legend,
);

const { Title, Text, Paragraph } = Typography;
const { Header, Content, Sider, Footer } = Layout;
const { TabPane } = Tabs;
const { Panel } = Collapse;
const { useBreakpoint } = Grid;

const SharedArticle = () => {
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [topTopics, setTopTopics] = useState([]);
  const [uniqueTopics, setUniqueTopics] = useState([]);
  const [relatedKeywords, setRelatedKeywords] = useState([]);
  const [queryInsightsSummaries, setQueryInsightsSummaries] = useState({});
  const [topicCheckStatus, setTopicCheckStatus] = useState('idle');
  const [contentGenerationStatus, setContentGenerationStatus] =
    useState('idle');
  const { uuid } = useParams();
  const [products, setProducts] = useState([]);
  const [selectedTab, setSelectedTab] = useState('relatedKeywords');
  const [showAllTopics, setShowAllTopics] = useState(false);
  const [lastJobStatus, setLastJobStatus] = useState({
    topicCheck: null,
    contentGeneration: null,
  });
  const [editableTitle, setEditableTitle] = useState('');
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [shareStatus, setShareStatus] = useState('private');
  const [shareUrl, setShareUrl] = useState('');
  const [sharePassword, setSharePassword] = useState('');
  const [userPlan, setUserPlan] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const [ticketCount, setTicketCount] = useState(0);
  const [userRole, setUserRole] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isPasswordRequired, setIsPasswordRequired] = useState(false);
  const [password, setPassword] = useState('');
  const [isPasswordVerified, setIsPasswordVerified] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();
  const screens = useBreakpoint();
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [activeKey, setActiveKey] = useState('1');
  const contentRef = useRef(null);
  const location = useLocation();

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const utmParams = {};
    ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'ref'].forEach(
      param => {
        const value = searchParams.get(param);
        if (value) {
          utmParams[param] = value;
        }
      },
    );

    // SharedArticleのパスを含むfrom_pageを設定
    utmParams.from_page = location.pathname + location.search;

    if (Object.keys(utmParams).length > 0) {
      localStorage.setItem('utmParams', JSON.stringify(utmParams));
    }
  }, [location]);

  useEffect(() => {
    const fetchArticleAndStatus = async () => {
      try {
        const token = localStorage.getItem('token');
        if (token) {
          setAuthToken(token);
          try {
            const profile = await getUserProfile();
            setIsAuthenticated(true);
            setUserProfile(profile);
            setUserRole(profile.role);
            setTicketCount(profile.ticket_count);
          } catch (error) {
            console.error('Failed to get user profile:', error);
            setIsAuthenticated(false);
          }
        } else {
          setIsAuthenticated(false);
        }

        const articleResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/shared/${uuid}`,
          {
            headers: token ? { Authorization: `Bearer ${token}` } : {},
            params: isPasswordVerified ? { password } : {},
          },
        );

        if (!isPasswordVerified && articleResponse.data.password_required) {
          setIsPasswordRequired(true);
        } else {
          setArticle(articleResponse.data);
          parseArticleData(articleResponse.data);
          if (!isEditingTitle) {
            setEditableTitle(articleResponse.data.title);
          }
          setShareStatus(
            prevStatus =>
              articleResponse.data.share_status || prevStatus || 'private',
          );
          setShareUrl(prevUrl => {
            if (articleResponse.data.share_status === 'private') {
              return '';
            }
            return articleResponse.data.share_url || prevUrl || '';
          });

          // Products data fetch
          const productsResponse = await axios.get(
            `${process.env.REACT_APP_API_URL}/seo-article/products/${uuid}`,
            {
              headers: token ? { Authorization: `Bearer ${token}` } : {},
            },
          );
          setProducts(productsResponse.data);

          if (isAuthenticated) {
            const statusResponse = await axios.get(
              `${process.env.REACT_APP_API_URL}/seo-article/status/${uuid}`,
              {
                headers: { Authorization: `Bearer ${token}` },
              },
            );
            setTopicCheckStatus(statusResponse.data.topic_check_status);
            setContentGenerationStatus(
              statusResponse.data.content_generation_status,
            );
            setLastJobStatus({
              topicCheck: statusResponse.data.last_topic_check_status,
              contentGeneration:
                statusResponse.data.last_content_generation_status,
            });
          }
        }
      } catch (error) {
        console.error('Failed to fetch article or status:', error);
        message.error('記事情報の取得に失敗しました');
      } finally {
        setLoading(false);
      }
    };

    fetchArticleAndStatus();
  }, [uuid, isEditingTitle, shareStatus, isPasswordVerified, password]);

  const parseArticleData = data => {
    if (data.top_topics) {
      const parsedTopTopics =
        typeof data.top_topics === 'string'
          ? JSON.parse(data.top_topics)
          : data.top_topics;
      setTopTopics(
        parsedTopTopics.filter(
          topic => topic[0] && !topic[0].startsWith('トピック'),
        ),
      );
    }

    if (data.unique_topics) {
      const parsedUniqueTopics =
        typeof data.unique_topics === 'string'
          ? JSON.parse(data.unique_topics)
          : data.unique_topics;
      setUniqueTopics(
        parsedUniqueTopics.filter(
          topic => topic[0] && !topic[0].startsWith('トピック'),
        ),
      );
    }

    if (data.related_keywords) {
      const parsedRelatedKeywords =
        typeof data.related_keywords === 'string'
          ? JSON.parse(data.related_keywords)
          : data.related_keywords;
      setRelatedKeywords(parsedRelatedKeywords);
    }

    if (data.query_insights_summaries) {
      setQueryInsightsSummaries(JSON.parse(data.query_insights_summaries));
    }
  };

  const handlePasswordSubmit = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/shared/${uuid}`,
        {
          params: { password },
        },
      );
      if (response.data.password_required) {
        message.error('パスワードが正しくありません');
        setPassword('');
      } else {
        setArticle(response.data);
        setIsPasswordVerified(true);
        setIsPasswordRequired(false);
        parseArticleData(response.data);
        message.success('パスワードが確認されました。記事を表示します。');
      }
    } catch (error) {
      console.error('Failed to authenticate with password:', error);
      message.error('パスワードが正しくありません');
      setPassword('');
    }
  };

  const showRegistrationModal = () => {
    setIsModalVisible(true);
  };

  const handleRegistration = () => {
    const utmParams = JSON.parse(localStorage.getItem('utmParams') || '{}');
    const searchParams = new URLSearchParams(utmParams);
    navigate(`/login?${searchParams.toString()}`);
  };

  const ReadOnlyStructuredHeadings = ({ headings, isContentGenerated }) => {
    const handleChange = () => {
      message.warning('共有された記事の構成案は編集できません。');
    };

    const handleDragStart = e => {
      e.preventDefault();
      message.warning('共有された記事の構成案は編集できません。');
    };

    return (
      <div onDragStart={handleDragStart}>
        <StructuredHeadings
          headings={headings}
          onChange={handleChange}
          isContentGenerated={isContentGenerated}
          isReadOnly={true}
        />
      </div>
    );
  };

  const searchIntents = article?.search_intents
    ? JSON.parse(article.search_intents)
    : [];
  const readerGoals = article?.reader_goals
    ? JSON.parse(article.reader_goals)
    : { content_goal: '', best_result: '' };
  const searchPersona = article?.search_persona || '';

  const queryInsightsData = {
    labels: [
      '専門性',
      'コンバージョン',
      '緊急性',
      'ローカリゼーション',
      'ニーズの多様性',
      'ビジュアライゼーション',
      '公式度と信頼性',
      '情報の最新性',
      'UGCやレビュー・体験談',
    ],
    datasets: [
      {
        label: 'Query Insights',
        data: article
          ? [
              article.expertise_and_depth,
              article.purchase_motivation_and_conversion,
              article.urgency_and_cta,
              article.localization_and_regional_adaptation,
              article.diversity_of_user_needs,
              article.visualization,
              article.source_formality_and_reliability,
              article.freshness_of_information,
              article.ugc_utilization_and_impact,
            ]
          : [],
        backgroundColor: 'rgba(0,212,255,0.1)',
        borderColor: 'rgb(0 212 255)',
        borderWidth: 2,
      },
    ],
  };

  const queryInsightsDescriptions = [
    {
      category: '専門性',
      description: '上位サイトがどの程度専門的で深い情報を提供しているか',
    },
    {
      category: 'コンバージョン',
      description:
        '上位サイトがどの程度購買意欲を喚起し、コンバージョンを促進しているか',
    },
    {
      category: '緊急性',
      description:
        '検索者が緊急を要し、また上位サイトが即座の行動を促しているか',
    },
    {
      category: 'ローカリゼーション',
      description: '国や地域に根付いたローカルな情報である度合い',
    },
    {
      category: 'ニーズの多様性',
      description: 'どの程度、多様なユーザーニーズを内容したキーワードであるか',
    },
    {
      category: 'ビジュアライゼーション',
      description: '上位サイトがどの程度ビジュアルコンテンツを提供しているか',
    },
    {
      category: '公式度と信頼性',
      description: '上位サイトの情報源がどの程度公式で信頼できるか',
    },
    {
      category: '情報の最新性',
      description: '上位サイトがどの程度最新の情報を提供しているか',
    },
    {
      category: 'UGCやレビュー・体験談',
      description:
        '上位サイトがどの程度ユーザー生成コンテンツを活用し、影響力があるか',
    },
  ];

  const categoryColors = {
    '企業/ブランド': { backgroundColor: '#e6f7ff', color: '#1890ff' },
    '商品/サービス': { backgroundColor: '#fff2e8', color: '#fa8c16' },
    '機能/プラン': { backgroundColor: '#f9f0ff', color: '#7b61ff' },
    ウェブサイト: { backgroundColor: '#f8f9cd', color: '#b5b01f' },
    '店舗/施設': { backgroundColor: '#f0fff1', color: '#33b65e' },
    '書籍/コンテンツ': { backgroundColor: '#f3e4ee', color: '#c76fbf' },
    公的機関: { backgroundColor: '#fffbe6', color: '#faad14' },
    その他: { backgroundColor: '#f0f0f0', color: '#8c8c8c' },
  };

  const productsColumns = [
    {
      title: '関連スコア',
      dataIndex: 'article_data',
      key: 'relevance',
      render: (article_data, record) => {
        const relevance = article_data[article.id]?.relevance || 0;
        return (
          <Progress
            percent={relevance * 20}
            steps={5}
            size={7}
            strokeColor={{
              '0%': '#108ee9',
              '100%': '#87d068',
            }}
            format={() => null}
          />
        );
      },
      width: 120,
    },
    {
      title: 'プロダクト名',
      dataIndex: 'name',
      key: 'name',
      width: 200,
    },
    {
      title: 'カテゴリー',
      dataIndex: 'category',
      key: 'category',
      render: category => (
        <span
          style={{
            display: 'inline-block',
            padding: '2px 8px',
            borderRadius: '4px',
            backgroundColor:
              categoryColors[category]?.backgroundColor || '#f0f0f0',
            color: categoryColors[category]?.color || '#000',
            fontSize: '12px',
            width: '100px',
            textAlign: 'center ',
          }}
        >
          {category}
        </span>
      ),
      width: 180,
    },
    {
      title: '説明文',
      dataIndex: 'article_data',
      key: 'description',
      render: (article_data, record) =>
        article_data[article.id]?.description || '',
      width: 240,
    },
  ];

  const renderStructuredHeadings = headings => {
    if (!headings) return null;
    const lines = headings.split('\n');
    let h2Count = 0;
    let h3Count = 0;

    return (
      <ul className="table-of-contents">
        {lines.map((line, index) => {
          if (line.startsWith('$$')) {
            h2Count++;
            h3Count = 0;
            const headingText = line.slice(2).trim();
            const headingId = `content-h2-${h2Count}`;
            return (
              <li key={index} className="toc-parent">
                <a
                  href={`#${headingId}`}
                  onClick={e => {
                    e.preventDefault();
                    scrollToHeading(headingId);
                  }}
                >
                  {headingText}
                </a>
              </li>
            );
          } else if (line.startsWith('###')) {
            h3Count++;
            const headingText = line.slice(3).trim();
            const headingId = `content-h2-${h2Count}-h3-${h3Count}`;
            return (
              <li key={index}>
                <a
                  href={`#${headingId}`}
                  onClick={e => {
                    e.preventDefault();
                    scrollToHeading(headingId);
                  }}
                >
                  {headingText}
                </a>
              </li>
            );
          }
          return null;
        })}
      </ul>
    );
  };

  const scrollToHeading = headingId => {
    const element = document.getElementById(headingId);
    if (element) {
      const offset = 0;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  const ArticleContent = ({ content }) => {
    if (!content) return null;

    const copyHtml = () => {
      const formattedHtml = content.replace(/></g, '>\n<');
      navigator.clipboard
        .writeText(formattedHtml)
        .then(() => {
          message.success('HTMLをコピーしました');
        })
        .catch(error => {
          message.error('HTMLのコピーに失敗しました');
          console.error('Failed to copy HTML: ', error);
        });
    };

    const copyText = () => {
      const tempDiv = document.createElement('div');

      // タイトルを追加
      const titleElement = document.createElement('h1');
      titleElement.textContent = article.title;
      tempDiv.appendChild(titleElement);

      // アイキャッチ画像を追加（もし存在する場合）
      if (article.eyecatch_image_url) {
        const imgPlaceholder = document.createElement('p');
        imgPlaceholder.textContent = `[画像: ${process.env.REACT_APP_API_URL}${article.eyecatch_image_url}]`;
        tempDiv.appendChild(imgPlaceholder);
      }

      // 本文を追加
      tempDiv.innerHTML += content;

      // テーブル後に空行を追加
      const tables = tempDiv.getElementsByTagName('table');
      for (let i = 0; i < tables.length; i++) {
        const br = document.createElement('br');
        tables[i].parentNode.insertBefore(br, tables[i].nextSibling);
      }

      // スタイルを適用
      const style = document.createElement('style');
      style.textContent = `
          h1, h2, h3, h4 { font-weight: bold !important; }
          table { border-collapse: collapse !important; width: 100% !important; margin-bottom: 16px !important; }
          th, td { border: 1px solid black !important; padding: 4px !important; }
          img { max-width: 100% !important; height: auto !important; margin: 10px 0 !important; }
        `;
      tempDiv.appendChild(style);

      // コピー用の一時的な要素を作成
      const hiddenElement = document.createElement('div');
      hiddenElement.innerHTML = tempDiv.innerHTML;
      hiddenElement.style.position = 'fixed';
      hiddenElement.style.pointerEvents = 'none';
      hiddenElement.style.opacity = '0';

      // ボディに追加
      document.body.appendChild(hiddenElement);

      // 選択範囲を作成
      const range = document.createRange();
      range.selectNode(hiddenElement);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);

      try {
        // 選択した内容をクリップボードにコピー
        document.execCommand('copy');
        message.success('Googleドキュメント用にコピーしました');
      } catch (err) {
        message.error('コピーに失敗しました');
        console.error('Failed to copy text: ', err);
      }

      // 選択を解除し、一時的な要素を削除
      window.getSelection().removeAllRanges();
      document.body.removeChild(hiddenElement);
    };

    const showCopyButtons = article.share_status === 'password';

    const addIdsToHeadings = htmlContent => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlContent, 'text/html');

      let h2Count = 0;
      let h3Count = 0;

      doc.querySelectorAll('h2, h3, h4').forEach(heading => {
        if (heading.tagName === 'H2') {
          h2Count++;
          h3Count = 0;
          heading.id = `content-h2-${h2Count}`;
        } else if (heading.tagName === 'H3') {
          h3Count++;
          heading.id = `content-h2-${h2Count}-h3-${h3Count}`;
        } else if (heading.tagName === 'H4') {
          heading.id = `content-h2-${h2Count}-h3-${h3Count}-h4-${heading.textContent.replace(/\s+/g, '-').toLowerCase()}`;
        }
      });

      return doc.body.innerHTML;
    };

    const parser = new DOMParser();
    const doc = parser.parseFromString(article.content, 'text/html');
    const firstH2 = doc.querySelector('h2');
    const introduction = firstH2
      ? article.content.substring(0, article.content.indexOf(firstH2.outerHTML))
      : '';
    const mainContent = firstH2
      ? article.content.substring(article.content.indexOf(firstH2.outerHTML))
      : article.content;

    return (
      <div className="article-content">
        {showCopyButtons && (
          <div className="copy-buttons">
            <Button onClick={copyHtml} icon={<CodeOutlined />}>
              HTMLをコピー
            </Button>
            <Button onClick={copyText} icon={<CopyOutlined />}>
              Google Docs用にコピー
            </Button>
          </div>
        )}
        <div
          className="html-content"
          dangerouslySetInnerHTML={{ __html: addIdsToHeadings(introduction) }}
        />
        <div className="table-of-contents">
          <div className="toc-title">この記事の目次</div>
          {renderStructuredHeadings(article.structured_headings)}
        </div>
        <div
          className="html-content"
          dangerouslySetInnerHTML={{ __html: addIdsToHeadings(mainContent) }}
        />
      </div>
    );
  };

  const fixedColumn30 = {
    width: 30,
    paddingLeft: 8,
    paddingRight: 8,
  };
  const fixedColumn50 = {
    width: 50,
    paddingLeft: 8,
    paddingRight: 8,
  };
  const fixedColumn240 = {
    width: 240,
    paddingLeft: 16,
    paddingRight: 16,
  };

  const handleCopyStructure = () => {
    if (article.structured_headings) {
      const formattedStructure = article.structured_headings
        .split('\n')
        .map(line => {
          if (line.startsWith('$$')) {
            return `<h2>${line.slice(2).trim()}</h2>`;
          } else if (line.startsWith('###')) {
            return `<h3>${line.slice(3).trim()}</h3>`;
          }
          return line;
        })
        .join('\n');

      navigator.clipboard
        .writeText(formattedStructure)
        .then(() => {
          message.success('構成案をコピーしました！');
        })
        .catch(error => {
          console.error('Failed to copy structure:', error);
          message.error('構成案のコピーに失敗しました');
        });
    }
  };

  const handleTabChange = key => {
    setActiveKey(key);
    if (contentRef.current && !screens.lg) {
      contentRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const tabItems = [
    { key: '1', icon: <RadarChartOutlined />, title: '上位サイトの傾向' },
    { key: '2', icon: <ContactsOutlined />, title: '検索者の分析' },
    { key: '3', icon: <BulbOutlined />, title: 'トピック分析' },
    { key: '4', icon: <ProductOutlined />, title: 'プロダクト分析' },
    { key: '5', icon: <BarsOutlined />, title: '構成案の生成' },
    { key: '6', icon: <EditOutlined />, title: '本文の生成' },
  ];

  const MobileTabMenu = ({ activeKey, onChange, items }) => {
    return (
      <Menu
        mode="horizontal"
        selectedKeys={[activeKey]}
        onClick={({ key }) => onChange(key)}
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          display: 'flex',
          justifyContent: 'space-around',
          background: '#fff',
          borderTop: '1px solid #f0f0f0',
          zIndex: 1000,
        }}
        className="mobileMenu"
      >
        {items.map(item => (
          <Menu.Item key={item.key} icon={item.icon} />
        ))}
      </Menu>
    );
  };

  const renderTabContent = key => {
    const item = tabItems.find(item => item.key === key);
    return (
      <div ref={contentRef}>
        {!screens.lg && (
          <Title
            level={3}
            style={{ marginBottom: '20px' }}
            className="mobile_tabTitle"
          >
            {item.title}
          </Title>
        )}
        {key === '1' && (
          <Row gutter={24}>
            <Col xs={24} lg={12}>
              {screens.lg && (
                <Radar
                  data={{
                    labels: queryInsightsData.labels,
                    datasets: queryInsightsData.datasets,
                  }}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                    scales: {
                      r: {
                        min: 0,
                        max: 5,
                        ticks: {
                          stepSize: 1,
                          font: {
                            size: 12,
                          },
                        },
                        pointLabels: {
                          font: {
                            size: 12,
                          },
                        },
                      },
                    },
                  }}
                />
              )}
            </Col>
            <Col xs={24} lg={12} className="queryInsight">
              <Title
                level={3}
                style={{ fontSize: '14px', color: 'rgb(0 212 255)' }}
              >
                上位サイトの傾向
                <Tooltip title="上位サイトの特徴を分析し、9つの観点からAIがスコア化した独自の指標です。">
                  <InfoCircleOutlined
                    style={{ marginLeft: '6px', color: 'rgb(0 212 255)' }}
                  />
                </Tooltip>
                ：
              </Title>
              <Collapse
                defaultActiveKey={['0']}
                accordion
                expandIconPosition="left"
                bordered={false}
              >
                {queryInsightsDescriptions.map((item, index) => (
                  <Panel
                    header={
                      <div
                        style={{
                          display: 'grid',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: '100%',
                          gridTemplateColumns: '5fr 4fr',
                        }}
                      >
                        <span className="qi_categoryname">{item.category}</span>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: '10px',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Progress
                            percent={
                              queryInsightsData.datasets[0].data[index] * 20
                            }
                            steps={5}
                            size="small"
                            strokeColor={{
                              '0%': '#108ee9',
                              '100%': '#87d068',
                            }}
                            style={{ width: '28px', marginRight: '10px' }}
                            format={() => ''} // この行を追加してn%を非表示にします
                          />
                          <span style={{ marginRight: '10px' }}>
                            <span
                              style={{
                                color:
                                  queryInsightsData.datasets[0].data[index] >=
                                  4.0
                                    ? '#0072ff'
                                    : '#00d4ff',
                              }}
                            >
                              {queryInsightsData.datasets[0].data[
                                index
                              ].toFixed(1)}
                            </span>
                            <span style={{ color: '#999' }}> / 5.0</span>
                          </span>
                          <Tooltip title={item.description}>
                            <InfoCircleOutlined
                              style={{ color: 'rgba(0, 0, 0, 0.45)' }}
                            />
                          </Tooltip>
                        </div>
                      </div>
                    }
                    key={index}
                    style={{
                      borderBottom:
                        index === queryInsightsDescriptions.length - 1
                          ? 'none'
                          : '1px solid #f0f0f0',
                      transition: 'background-color 0.3s',
                    }}
                  >
                    <p>{queryInsightsSummaries[item.category] || ''}</p>
                  </Panel>
                ))}
              </Collapse>
            </Col>
          </Row>
        )}
        {key === '2' && (
          <Row gutter={24}>
            <Col xs={24} lg={12}>
              <Title
                level={3}
                style={{ fontSize: '14px', color: 'rgb(0 212 255)' }}
              >
                検索意図
                <Tooltip title="キーワードに内包される検索者の知りたいことをAIが分析したデータです。">
                  <InfoCircleOutlined
                    style={{ marginLeft: '6px', color: 'rgb(0 212 255)' }}
                  />
                </Tooltip>
                ：
              </Title>
              {article && article.search_intents && (
                <Table
                  bordered
                  dataSource={searchIntents.map((intent, index) => ({
                    key: index,
                    index: index + 1,
                    intent: intent.replace(/"/g, ''),
                  }))}
                  columns={[
                    {
                      dataIndex: 'index',
                      key: 'index',
                      render: (text, record, index) => ({
                        children: text,
                        props: {
                          style: {
                            backgroundColor: '#fafafa',
                            padding: '16px',
                            fontWeight: 'bold',
                          },
                        },
                      }),
                    },
                    {
                      dataIndex: 'intent',
                      key: 'intent',
                    },
                  ]}
                  showHeader={false}
                  pagination={false}
                />
              )}
              <Title
                level={3}
                style={{
                  fontSize: '14px',
                  marginTop: '36px',
                  color: 'rgb(0 212 255)',
                }}
              >
                読者のゴール
                <Tooltip title="検索行動の後で、読者がどのような状態になりたいかをAIが分析したデータです。">
                  <InfoCircleOutlined
                    style={{ marginLeft: '6px', color: 'rgb(0 212 255)' }}
                  />
                </Tooltip>
                ：
              </Title>
              {article && article.reader_goals && (
                <Table
                  bordered
                  dataSource={[
                    {
                      key: 'content_goal',
                      label: 'コンテンツのゴール',
                      value: readerGoals.content_goal,
                    },
                    {
                      key: 'best_result',
                      label: '最高の結果',
                      value: readerGoals.best_result,
                    },
                  ]}
                  columns={[
                    {
                      dataIndex: 'label',
                      key: 'label',
                      render: (text, record, index) => ({
                        children: text,
                        props: {
                          style: {
                            backgroundColor: '#fafafa',
                            padding: '16px',
                            fontWeight: 'bold',
                          },
                        },
                      }),
                    },
                    {
                      dataIndex: 'value',
                      key: 'value',
                    },
                  ]}
                  showHeader={false}
                  pagination={false}
                />
              )}
            </Col>
            <Col xs={24} lg={12} className="persona_table">
              <Title
                level={3}
                style={{ fontSize: '14px', color: 'rgb(0 212 255)' }}
              >
                検索者のペルソナ
                <Tooltip title="AIが多角的に分析した、中央値的な検索者のペルソナです。">
                  <InfoCircleOutlined
                    style={{ marginLeft: '6px', color: 'rgb(0 212 255)' }}
                  />
                </Tooltip>
                ：
              </Title>
              {article && article.search_persona && (
                <Table
                  bordered
                  dataSource={searchPersona
                    .split('\n')
                    .map((line, index) => {
                      const [key, value] = line.split(':');
                      return key && value
                        ? { key: index, label: key.trim(), value: value.trim() }
                        : null;
                    })
                    .filter(Boolean)}
                  columns={[
                    {
                      dataIndex: 'label',
                      key: 'label',
                      width: 160,
                      render: (text, record, index) => ({
                        children: text,
                        props: {
                          style: {
                            backgroundColor: '#fafafa',
                            padding: '16px',
                            fontWeight: 'bold',
                          },
                        },
                      }),
                    },
                    {
                      dataIndex: 'value',
                      key: 'value',
                    },
                  ]}
                  showHeader={false}
                  pagination={false}
                />
              )}
            </Col>
          </Row>
        )}
        {key === '3' && (
          <Row gutter={[24, 36]}>
            <Col xs={24} xl={8}>
              <Title
                level={3}
                style={{ fontSize: '14px', color: 'rgb(0 212 255)' }}
              >
                重要語句
                <Tooltip title="上位サイトで頻出している重要な語句です。一般的に用いられるサジェストワードやLSIと比べ、ある意味ではよりリアルなデータと言えます。">
                  <InfoCircleOutlined
                    style={{ marginLeft: '6px', color: 'rgb(0 212 255)' }}
                  />
                </Tooltip>
                ：
              </Title>
              <Table
                columns={[
                  {
                    title: '',
                    dataIndex: 'checked',
                    key: 'check',
                    render: (_, record) => {
                      const keyword = record[0];
                      const checkState = record[2];
                      const checkboxColor =
                        checkState === 1
                          ? 'pink'
                          : checkState === 2
                            ? 'red'
                            : checkState === 3
                              ? 'orange'
                              : 'default';
                      return (
                        <Checkbox
                          checked={checkState !== 0}
                          disabled={true}
                          className={`custom-checkbox ${checkboxColor}`}
                        />
                      );
                    },
                    ...fixedColumn30,
                  },
                  {
                    title: 'キーワード',
                    dataIndex: 'keyword',
                    key: 'keyword',
                    render: (_, record) => (
                      <span style={{ color: record[2] ? '#ccc' : 'inherit' }}>
                        {record[0]}
                      </span>
                    ),
                  },
                  {
                    title: '出現割合',
                    dataIndex: 'count',
                    key: 'count',
                    render: (_, record) => (
                      <Progress
                        percent={record[1] * 20}
                        steps={5}
                        size={7}
                        strokeColor={{
                          '0%': '#108ee9',
                          '100%': '#87d068',
                        }}
                        format={() => null}
                      />
                    ),
                    ...fixedColumn50,
                  },
                ]}
                dataSource={relatedKeywords}
                className="topic-table"
                pagination={false}
              />
            </Col>
            <Col xs={24} xl={8}>
              <Title
                level={3}
                style={{ fontSize: '14px', color: 'rgb(0 212 255)' }}
              >
                上位サイトの主要トピック
                <Tooltip title="上位サイトのコンテンツをAIが分析し、頻出しているトピックを整理したデータです。">
                  <InfoCircleOutlined
                    style={{ marginLeft: '6px', color: 'rgb(0 212 255)' }}
                  />
                </Tooltip>
                ：
              </Title>
              <Table
                columns={[
                  {
                    title: '',
                    dataIndex: 'checked',
                    key: 'check',
                    render: (_, record) => {
                      const checkState = record[2];
                      const checkboxColor =
                        checkState === 1
                          ? 'pink'
                          : checkState === 2
                            ? 'red'
                            : checkState === 3
                              ? 'orange'
                              : 'default';
                      return (
                        <Checkbox
                          checked={checkState !== 0}
                          disabled={true}
                          className={`custom-checkbox ${checkboxColor}`}
                        />
                      );
                    },
                    ...fixedColumn30,
                  },
                  {
                    title: 'トピック',
                    dataIndex: 'topic',
                    key: 'topic',
                    render: (_, record) => (
                      <span style={{ color: record[2] ? '#ccc' : 'inherit' }}>
                        {record[0]}
                      </span>
                    ),
                  },
                  {
                    title: '頻出度',
                    dataIndex: 'frequency',
                    key: 'frequency',
                    render: (_, record) => (
                      <Progress
                        percent={record[1] * 20}
                        steps={5}
                        size={7}
                        strokeColor={{
                          '0%': '#108ee9',
                          '100%': '#87d068',
                        }}
                        format={() => null}
                      />
                    ),
                    ...fixedColumn50,
                  },
                ]}
                dataSource={topTopics}
                className="topic-table"
                pagination={false}
              />
            </Col>
            <Col xs={24} xl={8}>
              <Title
                level={3}
                style={{ fontSize: '14px', color: 'rgb(0 212 255)' }}
              >
                独自性を出しやすいトピック
                <Tooltip title="上位サイトが扱っていないが、一定のニーズがある可能性あるトピックのアイデアです。">
                  <InfoCircleOutlined
                    style={{ marginLeft: '6px', color: 'rgb(0 212 255)' }}
                  />
                </Tooltip>
                ：
              </Title>
              <Table
                columns={[
                  {
                    title: '',
                    dataIndex: 'checked',
                    key: 'check',
                    render: (_, record) => {
                      const checkState = record[2];
                      const checkboxColor =
                        checkState === 1
                          ? 'pink'
                          : checkState === 2
                            ? 'red'
                            : checkState === 3
                              ? 'orange'
                              : 'default';
                      return (
                        <Checkbox
                          checked={checkState !== 0}
                          disabled={true}
                          className={`custom-checkbox ${checkboxColor}`}
                        />
                      );
                    },
                    ...fixedColumn30,
                  },
                  {
                    title: 'トピック',
                    dataIndex: 'topic',
                    key: 'topic',
                    render: (_, record) => (
                      <span style={{ color: record[2] ? '#ccc' : 'inherit' }}>
                        {record[0]}
                      </span>
                    ),
                  },
                  {
                    title: '独自性',
                    dataIndex: 'uniqueness',
                    key: 'uniqueness',
                    render: (_, record) => (
                      <Progress
                        percent={record[1] * 20}
                        steps={5}
                        size={7}
                        strokeColor={{
                          '0%': '#108ee9',
                          '100%': '#87d068',
                        }}
                        format={() => null}
                      />
                    ),
                    ...fixedColumn50,
                  },
                ]}
                dataSource={uniqueTopics}
                className="topic-table"
                pagination={false}
              />
            </Col>
          </Row>
        )}
        {key === '4' && (
          <>
            <Title
              level={3}
              style={{ fontSize: '14px', color: 'rgb(0 212 255)' }}
            >
              上位サイトで紹介されているプロダクト
              <Tooltip title="上位サイトで紹介されている商品やサービス、機関などをAIが分析したデータです。">
                <InfoCircleOutlined
                  style={{ marginLeft: '6px', color: 'rgb(0 212 255)' }}
                />
              </Tooltip>
              ：
            </Title>
            {products && products.length > 0 && (
              <Table
                dataSource={products.sort(
                  (a, b) =>
                    (b.article_data[article.id]?.presence || 0) -
                    (a.article_data[article.id]?.presence || 0),
                )}
                columns={productsColumns}
                pagination={false}
                scroll={{ x: 'max-content' }}
              />
            )}
          </>
        )}
        {key === '5' && (
          <Row gutter={[24, 40]}>
            <Col xs={24} lg={14}>
              <Title
                level={3}
                style={{ fontSize: '14px', color: 'rgb(0 212 255)' }}
              >
                AIタイトル
                <Tooltip title="分析したあらゆる情報を元に、AIが生成した記事のタイトル候補です。">
                  <InfoCircleOutlined
                    style={{ marginLeft: '6px', color: 'rgb(0 212 255)' }}
                  />
                </Tooltip>
                ：
              </Title>
              <Title
                level={2}
                style={{ margin: '8px 0 36px', fontSize: '20px' }}
              >
                {article.title}
              </Title>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: '4px',
                }}
              >
                <Title
                  level={3}
                  style={{ fontSize: '14px', color: 'rgb(0 212 255)' }}
                >
                  AI構成案
                  <Tooltip title="あらゆる情報を元にAIが生成した記事の構成案です。">
                    <InfoCircleOutlined
                      style={{ marginLeft: '6px', color: 'rgb(0 212 255)' }}
                    />
                  </Tooltip>
                  ：
                </Title>
                <Button
                  onClick={handleCopyStructure}
                  style={{ fontSize: '12px', color: '#108ee9' }}
                >
                  <CopyOutlined style={{ fontSize: '16px' }} />
                  &nbsp;コピー
                </Button>
              </div>
              {article.structured_headings && (
                <ReadOnlyStructuredHeadings
                  headings={article.structured_headings}
                  isContentGenerated={
                    article.content !== null && article.content !== ''
                  }
                />
              )}
            </Col>
            <Col xs={24} lg={10}>
              <Affix offsetTop={24}>
                <div
                  className="content-check-table-wrap"
                  style={{ marginBottom: '16px' }}
                >
                  <div style={{ marginBottom: '16px', textAlign: 'right' }}>
                    <Switch
                      checked={showAllTopics}
                      onChange={checked => setShowAllTopics(checked)}
                      size="small"
                    />
                    <span style={{ fontSize: '12px', marginLeft: '4px' }}>
                      すべて表示
                    </span>
                  </div>
                  <Segmented
                    options={[
                      {
                        label: '重要語句',
                        value: 'relatedKeywords',
                      },
                      {
                        label: '主要トピック',
                        value: 'topTopics',
                      },
                      {
                        label: '独自トピック',
                        value: 'uniqueTopics',
                      },
                    ]}
                    value={selectedTab}
                    onChange={value => {
                      setSelectedTab(value);
                    }}
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginBottom: '4px',
                    }}
                    block
                  />
                  {selectedTab === 'relatedKeywords' && (
                    <Table
                      columns={[
                        {
                          title: 'チェック',
                          dataIndex: 'checked',
                          key: 'check',
                          width: '10%',
                          render: (_, record) => {
                            const keyword = record[0];
                            const checkState = record[2];
                            const checkboxColor =
                              checkState === 1
                                ? 'pink'
                                : checkState === 2
                                  ? 'red'
                                  : checkState === 3
                                    ? 'orange'
                                    : 'default';
                            return (
                              <Checkbox
                                checked={checkState !== 0}
                                disabled={true}
                                className={`custom-checkbox ${checkboxColor}`}
                              />
                            );
                          },
                          ...fixedColumn50,
                        },
                        {
                          title: 'キーワード',
                          dataIndex: 'keyword',
                          key: 'keyword',
                          width: '75%',
                          render: (_, [keyword, frequency, checked]) => (
                            <span
                              style={{
                                color: checked === 0 ? 'inherit' : '#ccc',
                              }}
                            >
                              {keyword}
                            </span>
                          ),
                        },
                        {
                          title: '重要度',
                          dataIndex: 'importance',
                          key: 'importance',
                          width: '15%',
                          render: (_, [keyword, frequency]) => (
                            <Progress
                              percent={frequency * 20}
                              steps={5}
                              size={7}
                              strokeColor={{
                                '0%': '#108ee9',
                                '100%': '#87d068',
                              }}
                              format={() => null}
                            />
                          ),
                        },
                      ]}
                      dataSource={
                        showAllTopics
                          ? relatedKeywords
                          : relatedKeywords.filter(
                              ([_, __, checked]) =>
                                checked === 0 || checked === 5,
                            )
                      }
                      pagination={false}
                      showHeader={false}
                      size="small"
                      className="content-check-table"
                    />
                  )}
                  {selectedTab === 'topTopics' && (
                    <Table
                      columns={[
                        {
                          title: 'チェック',
                          dataIndex: 'checked',
                          key: 'check',
                          width: '10%',
                          render: (_, record) => {
                            const topic = record[0];
                            const checkState = record[2];
                            const checkboxColor =
                              checkState === 1
                                ? 'pink'
                                : checkState === 2
                                  ? 'red'
                                  : checkState === 3
                                    ? 'orange'
                                    : 'default';
                            return (
                              <Checkbox
                                checked={checkState !== 0}
                                disabled={true}
                                className={`custom-checkbox ${checkboxColor}`}
                              />
                            );
                          },
                          ...fixedColumn50,
                        },
                        {
                          title: 'トピック',
                          dataIndex: 'topic',
                          key: 'topic',
                          width: '75%',
                          render: (_, [keyword, frequency, checked]) => (
                            <span
                              style={{
                                color: checked === 0 ? 'inherit' : '#ccc',
                              }}
                            >
                              {keyword}
                            </span>
                          ),
                        },
                        {
                          title: '重要度',
                          dataIndex: 'importance',
                          key: 'importance',
                          width: '15%',
                          render: (_, [keyword, frequency]) => (
                            <Progress
                              percent={frequency * 20}
                              steps={5}
                              size={7}
                              strokeColor={{
                                '0%': '#108ee9',
                                '100%': '#87d068',
                              }}
                              format={() => null}
                            />
                          ),
                        },
                      ]}
                      dataSource={
                        showAllTopics
                          ? topTopics
                          : topTopics.filter(
                              ([_, __, checked]) =>
                                checked === 0 || checked === 5,
                            )
                      }
                      pagination={false}
                      showHeader={false}
                      size="small"
                      className="content-check-table"
                    />
                  )}
                  {selectedTab === 'uniqueTopics' && (
                    <Table
                      columns={[
                        {
                          title: 'チェック',
                          dataIndex: 'checked',
                          key: 'check',
                          width: '10%',
                          render: (_, record) => {
                            const topic = record[0];
                            const checkState = record[2];
                            const checkboxColor =
                              checkState === 1
                                ? 'pink'
                                : checkState === 2
                                  ? 'red'
                                  : checkState === 3
                                    ? 'orange'
                                    : 'default';
                            return (
                              <Checkbox
                                checked={checkState !== 0}
                                disabled={true}
                                className={`custom-checkbox ${checkboxColor}`}
                              />
                            );
                          },
                          ...fixedColumn50,
                        },
                        {
                          title: 'トピック',
                          dataIndex: 'topic',
                          key: 'topic',
                          width: '75%',
                          render: (_, [keyword, uniqueness, checked]) => (
                            <span
                              style={{
                                color: checked === 0 ? 'inherit' : '#ccc',
                              }}
                            >
                              {keyword}
                            </span>
                          ),
                        },
                        {
                          title: '独自性',
                          dataIndex: 'uniqueness',
                          key: 'uniqueness',
                          width: '15%',
                          render: (_, [keyword, uniqueness]) => (
                            <Progress
                              percent={uniqueness * 20}
                              steps={5}
                              size={7}
                              strokeColor={{
                                '0%': '#108ee9',
                                '100%': '#87d068',
                              }}
                              format={() => null}
                            />
                          ),
                        },
                      ]}
                      dataSource={
                        showAllTopics
                          ? uniqueTopics
                          : uniqueTopics.filter(
                              ([_, __, checked]) =>
                                checked === 0 || checked === 5,
                            )
                      }
                      pagination={false}
                      showHeader={false}
                      size="small"
                      className="content-check-table"
                    />
                  )}
                </div>
              </Affix>
            </Col>
          </Row>
        )}
        {key === '6' && (
          <Row
            gutter={[40, 40]}
            style={{ justifyContent: 'center', margin: '0px' }}
          >
            <Col
              xs={24}
              xl={15}
              className="article_wrap"
              style={{ width: '100%' }}
            >
              {article.content ? (
                <>
                  <div className="article_title">{article.title}</div>
                  {article.eyecatch_image_url && (
                    <img
                      src={`${process.env.REACT_APP_API_URL}${article.eyecatch_image_url}`}
                      alt="Article eyecatch"
                      style={{
                        maxWidth: '100%',
                        height: 'auto',
                        marginBottom: '20px',
                      }}
                    />
                  )}
                  <div>
                    <ArticleContent content={article.content} />
                  </div>
                </>
              ) : (
                <>
                  <div className="article_title">{article.title}</div>
                  <Skeleton
                    loading={true}
                    active
                    paragraph={{
                      rows: 4,
                      width: ['100%', '100%', '100%', '100%'],
                    }}
                    title={{ width: '20%' }}
                    style={{ marginBottom: '36px' }}
                  >
                    <div style={{ marginBottom: '20px' }}>
                      <Skeleton.Paragraph
                        style={{ width: 200 }}
                        active
                        size="small"
                      />
                      <br />
                      <Skeleton.Paragraph
                        style={{ width: 150, marginTop: 16 }}
                        active
                        size="small"
                      />
                    </div>
                  </Skeleton>
                  <div
                    className="content-structure-wrap"
                    style={{ marginBottom: '24px' }}
                  >
                    <div className="table-of-contents">
                      <div className="toc-title">この記事の目次</div>
                      {article &&
                        article.structured_headings &&
                        renderStructuredHeadings(article.structured_headings)}
                    </div>
                  </div>
                  {article && article.structured_headings ? (
                    <Title
                      level={3}
                      style={{
                        fontSize: '14px',
                        color: 'rgb(0 212 255)',
                        marginBottom: '16px',
                      }}
                    >
                      想定文字数：
                      <span style={{ fontSize: '24px', fontWeight: 'bold' }}>
                        {(article.structured_headings.split('###').length - 1) *
                          400}
                      </span>
                      文字
                    </Title>
                  ) : (
                    <Title
                      level={3}
                      style={{
                        fontSize: '14px',
                        color: 'rgb(0 212 255)',
                        marginBottom: '16px',
                      }}
                    >
                      想定文字数：計算不可
                    </Title>
                  )}
                  <Paragraph>
                    <ul style={{ lineHeight: 2.0 }}>
                      <li>構成案に沿ってAIが本文を生成します。</li>
                      <li>文字数は目安となります。</li>
                      <li>本文生成のやり直しはできません。</li>
                    </ul>
                  </Paragraph>
                  <Button
                    type="primary"
                    size="large"
                    disabled={true}
                    style={{
                      background:
                        'linear-gradient(90deg, #0072ff 0%, #00d4ff 100%)',
                      border: 'none',
                      transition: 'all 0.3s',
                      width: '100%',
                      fontWeight: 'bold',
                    }}
                  >
                    記事の本文を生成する
                  </Button>
                </>
              )}
            </Col>
          </Row>
        )}
      </div>
    );
  };

  const renderHeader = () => (
    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      {!screens.xl && (
        <div className="logo mobile-logo">
          <span>Ignite creativity with..</span>magicss
        </div>
      )}
      <div
        style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}
      >
        {isAuthenticated ? (
          <AuthenticatedMenu
            userProfile={userProfile}
            userRole={userRole}
            ticketCount={ticketCount}
            isMobile={!screens.xl}
          />
        ) : (
          <div className="registration_wrap">
            <Text className="hide-on-mobile" style={{ marginRight: '16px' }}>
              ようこそ、ゲストさん！
            </Text>
            <Button
              type="primary"
              onClick={showRegistrationModal}
              className="registration"
              onMouseEnter={e => {
                e.currentTarget.style.opacity = '0.7';
              }}
              onMouseLeave={e => {
                e.currentTarget.style.opacity = '1.0';
              }}
            >
              無料登録する
            </Button>
          </div>
        )}
      </div>
    </div>
  );

  const renderSidebar = () => {
    const menuItems = [
      { key: '/seo-article', icon: <FormOutlined />, label: '新規生成' },
      {
        key: '/seo-article/list',
        icon: <DatabaseOutlined />,
        label: '生成結果一覧',
      },
      {
        key: '/seo-article/keywords-recommend',
        icon: <BulbOutlined />,
        label: 'おすすめキーワード β',
      },
    ];

    if (userRole === 'master' || userRole === 'admin') {
      menuItems.push({
        key: '/seo-article/observe_task',
        icon: <ClusterOutlined />,
        label: 'タスク監視',
      });
    }

    const modeMenu = (
      <Menu>
        <Menu.Item
          key="seo-article"
          onClick={() =>
            isAuthenticated ? navigate('/seo-article') : showRegistrationModal()
          }
        >
          SEO記事生成
        </Menu.Item>
        <Menu.Item key="comming-soon" disabled>
          Comming Soon...
        </Menu.Item>
      </Menu>
    );

    return (
      <>
        <div className="logo side">
          <span>Ignite creativity with..</span>magicss
        </div>
        <div className="mode-selector">
          <Dropdown overlay={modeMenu} trigger={['click']}>
            <div className="mode-selector-toggle">
              <RobotOutlined style={{ marginRight: '4px' }} />
              SEO記事生成モード <CaretDownOutlined />
            </div>
          </Dropdown>
        </div>
        <Menu
          mode="inline"
          selectedKeys={['2']}
          style={{ height: '100%', borderRight: 0, paddingTop: '24px' }}
          onClick={isAuthenticated ? null : showRegistrationModal}
        >
          {menuItems.map(item => (
            <Menu.Item key={item.key} icon={item.icon}>
              {isAuthenticated ? (
                <Link to={item.key}>{item.label}</Link>
              ) : (
                item.label
              )}
            </Menu.Item>
          ))}
        </Menu>
      </>
    );
  };

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  if (isPasswordRequired) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          background: 'rgba(0, 0, 0, 0.05)',
          backdropFilter: 'blur(5px)',
        }}
      >
        <Modal
          visible={isPasswordRequired}
          footer={null}
          closable={false}
          maskClosable={false}
          style={{ top: 20 }}
        >
          <div
            style={{
              padding: '24px',
            }}
          >
            <div
              className="logo"
              style={{
                textAlign: 'center',
                marginBottom: '24px',
              }}
            >
              magicss
            </div>
            <Title
              level={4}
              style={{
                textAlign: 'center',
                fontSize: '18px',
                marginBottom: '24px',
              }}
            >
              パスワードを入力してください
            </Title>
            <div
              style={{
                border: '2px solid #f5f5f5',
                backgroundColor: '#f5f5f5',
                transition: 'all 0.3s',
                borderRadius: '8px',
                marginBottom: '24px',
              }}
              onMouseEnter={e => {
                e.currentTarget.style.backgroundColor = '#eee';
              }}
              onMouseLeave={e => {
                e.currentTarget.style.backgroundColor = '#f5f5f5';
              }}
              onFocus={e => {
                e.currentTarget.style.border = '2px solid #0072ff';
              }}
              onBlur={e => {
                e.currentTarget.style.border = '2px solid #f5f5f5';
              }}
            >
              <Input.Password
                prefix={<LockOutlined />}
                value={password}
                onChange={e => setPassword(e.target.value)}
                onPressEnter={handlePasswordSubmit}
                placeholder="パスワード"
                size="large"
                style={{
                  border: 'none',
                  backgroundColor: 'transparent',
                  outline: 'none',
                  padding: '8px',
                }}
              />
            </div>
            <Button
              type="primary"
              onClick={handlePasswordSubmit}
              block
              size="large"
              style={{
                background: 'linear-gradient(90deg, #0072ff 0%, #00d4ff 100%)',
                border: 'none',
                transition: 'all 0.3s',
                width: '100%',
                fontWeight: 'bold',
              }}
              onMouseEnter={e => {
                e.currentTarget.style.opacity = '0.7';
              }}
              onMouseLeave={e => {
                e.currentTarget.style.opacity = '1.0';
              }}
            >
              送信して生成結果を開く
            </Button>
          </div>
        </Modal>
      </div>
    );
  }

  if (!article) {
    return <Typography.Title level={3}>記事が見つかりません</Typography.Title>;
  }

  return (
    <>
      <Helmet>
        <title>{`「${article?.keyword || ''}」の生成結果 | SEO記事生成モード - magicss`}</title>
        <meta name="robots" content="noindex" />
        <meta
          property="og:title"
          content={`「${article?.keyword || ''}」の生成結果 | SEO記事生成モード - magicss`}
        />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content={
            article?.eyecatch_image_url
              ? `${process.env.REACT_APP_API_URL}${article.eyecatch_image_url}`
              : `${process.env.PUBLIC_URL}/img/ogp.png`
          }
        />
        <meta
          property="og:description"
          content={`「${article?.keyword || ''}」のSEO記事生成結果です。`}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Layout style={{ minHeight: '100vh' }}>
        <Sider
          width={250}
          style={{
            height: '100vh',
            background: '#fff',
            position: 'fixed',
            left: 0,
            top: 0,
            bottom: 0,
            paddingTop: '80px',
          }}
          breakpoint="xl"
          collapsedWidth={0}
          trigger={null}
        >
          {renderSidebar()}
        </Sider>
        <Layout
          style={{
            marginLeft: screens.xl ? 250 : 0,
            transition: 'margin 0.2s',
          }}
        >
          <Header
            style={{
              background: '#fff',
              padding: screens.xl ? 0 : '0 16px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {!screens.xl && (
              <MenuOutlined className="menu-icon" onClick={toggleSidebar} />
            )}
            {renderHeader()}
          </Header>
          <Content
            style={{
              background: '#fff',
              padding: screens.xl ? '40px 40px 80px' : '16px',
              margin: screens.xl ? '24px 24px' : '16px 0',
              minHeight: 280,
            }}
          >
            <div id="a_detail">
              <Row gutter={24}>
                <Col xs={24} lg={14}>
                  <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>SEO記事生成</Breadcrumb.Item>
                    <Breadcrumb.Item>生成結果一覧</Breadcrumb.Item>
                    <Breadcrumb.Item className="hide-on-mobile">
                      「{article?.keyword}」の生成結果
                    </Breadcrumb.Item>
                  </Breadcrumb>
                  <Title
                    level={1}
                    className="gradient-text"
                    style={{ fontSize: '24px', lineHeight: '1.6' }}
                  >
                    「{article.keyword}」の生成結果
                  </Title>
                  <Text
                    type="secondary"
                    style={{ display: 'block', marginBottom: '24px' }}
                  >
                    生成完了日:{' '}
                    {moment
                      .utc(article.completed_at)
                      .tz('Asia/Tokyo')
                      .format('YYYY/MM/DD HH:mm')}
                  </Text>
                </Col>
                <Col xs={24} lg={10} className="shared_label">
                  <Space direction="vertical" align="end" size="small">
                    <Tag
                      color="orange"
                      style={{ fontSize: '14px', padding: '4px 8px' }}
                    >
                      このページは共有された分析結果です
                    </Tag>
                  </Space>
                </Col>
              </Row>
              <Divider style={{ margin: '24px 0' }} />
              <div style={{ paddingBottom: screens.lg ? 0 : '60px' }}>
                {screens.lg ? (
                  <Tabs
                    activeKey={activeKey}
                    onChange={handleTabChange}
                    type="card"
                    size="middle"
                    style={{ width: '100%' }}
                  >
                    {tabItems.map(item => (
                      <TabPane
                        tab={
                          <span>
                            {item.icon}
                            <span style={{ marginLeft: '8px' }}>
                              {item.title}
                            </span>
                          </span>
                        }
                        key={item.key}
                      >
                        {renderTabContent(item.key)}
                      </TabPane>
                    ))}
                  </Tabs>
                ) : (
                  <>
                    {renderTabContent(activeKey)}
                    <MobileTabMenu
                      activeKey={activeKey}
                      onChange={handleTabChange}
                      items={tabItems}
                    />
                  </>
                )}
              </div>
            </div>
          </Content>
          <Footer id="footer">
            <Row gutter={[16, 16]} justify={'space-between'}>
              <Col xs={24} xl={24} xxl={16}>
                <Row className="f_menu">
                  <Col xs={24} md={12} lg={5}>
                    <a href="https://magicss.ai" target="_blank">
                      magicssサービスサイト <ExportOutlined />
                    </a>
                  </Col>
                  <Col xs={24} md={12} lg={5}>
                    <a href="/terms-of-service" target="_blank">
                      サービス利用規約
                    </a>
                  </Col>
                  <Col xs={24} md={12} lg={5}>
                    <a href="/privacy-policy" target="_blank">
                      プライバシーポリシー
                    </a>
                  </Col>
                  <Col xs={24} md={12} lg={5}>
                    <a href="/legal-notice" target="_blank">
                      特定商取引法に基づく表記
                    </a>
                  </Col>
                  <Col xs={24} md={12} lg={4}>
                    <a href="/legal-notice" target="_blank">
                      お問い合わせ <ExportOutlined />
                    </a>
                  </Col>
                </Row>
              </Col>
              <Col
                xs={24}
                lg={24}
                xl={24}
                xxl={4}
                style={{ textAlign: 'center' }}
              >
                © 2024{' '}
                <a href="https://oneword.co.jp" target="_blank">
                  ONEWORD Inc.
                </a>
              </Col>
            </Row>
          </Footer>
        </Layout>
        <Drawer
          placement="left"
          closable={false}
          onClose={toggleSidebar}
          visible={sidebarVisible}
          bodyStyle={{ padding: 0 }}
          width={250}
        >
          {renderSidebar()}
        </Drawer>
      </Layout>
      <Modal
        title="会員登録のご案内"
        visible={isModalVisible}
        onOk={handleRegistration}
        onCancel={() => setIsModalVisible(false)}
        okText="登録する"
        cancelText="キャンセル"
      >
        <p>会員登録すると、より多くの機能をご利用いただけます。</p>
        <ul>
          <li>記事の保存と管理</li>
          <li>高度な分析ツール</li>
          <li>カスタマイズ機能</li>
        </ul>
      </Modal>
    </>
  );
};

export default SharedArticle;
