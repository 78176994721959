import React, { useEffect, useRef } from "react";

const HubSpotForms = ({ formType }) => {
  const formRef = useRef(null);

  useEffect(() => {
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        if (document.querySelector(`script[src="${src}"]`)) {
          console.log(`Script ${src} already loaded`);
          resolve();
          return;
        }
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
          console.log(`Script ${src} loaded successfully`);
          resolve();
        };
        script.onerror = (error) => {
          console.error(`Error loading script ${src}:`, error);
          reject(error);
        };
        document.body.appendChild(script);
      });
    };

    const createForm = async () => {
      if (formType === "regular") {
        console.log("Attempting to create regular form");
        try {
          await loadScript("//js.hsforms.net/forms/embed/v2.js");
          if (window.hbspt) {
            console.log("hbspt object found, creating form");
            window.hbspt.forms.create({
              portalId: "43662357",
              formId: "8a49df56-99ea-437a-ad9f-afeed4b4fd6c",
              target: "#hubspotRegularForm",
            });
          } else {
            console.error("hbspt object not found");
          }
        } catch (error) {
          console.error("Error creating regular form:", error);
        }
      } else if (formType === "meetings") {
        console.log("Attempting to create meetings form");
        if (formRef.current) {
          const iframe = document.createElement("iframe");
          iframe.src =
            "https://meetings.hubspot.com/word/magicss_options?embed=true";
          iframe.style.width = "100%";
          iframe.style.height = "1000px";
          iframe.style.border = "none";
          formRef.current.innerHTML = "";
          formRef.current.appendChild(iframe);
          console.log("Meetings iframe created and appended");
        } else {
          console.error("Meetings form container not found");
        }
      }
    };

    createForm();

    return () => {
      console.log(`Cleaning up ${formType} form`);
    };
  }, [formType]);

  console.log(`Rendering ${formType} form container`);
  if (formType === "regular") {
    return <div id="hubspotRegularForm"></div>;
  } else if (formType === "meetings") {
    return <div ref={formRef} className="meetings-iframe-container"></div>;
  }

  return null;
};

export default HubSpotForms;
