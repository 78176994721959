export const trackEvent = (eventName, eventParams = {}) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: eventName,
      ...eventParams,
    });
  }
};

export const setUserProperties = (userId, userProperties = {}) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: "set_user_properties",
      user_id: userId,
      user_properties: {
        user_id: userId,
        ...userProperties,
      },
    });
  }
};
