import React, { useEffect, useState } from "react";
import { Menu, Dropdown, Typography, Divider, Tag, Avatar } from "antd";
import {
  FireOutlined,
  UserOutlined,
  SettingOutlined,
  LogoutOutlined,
  CaretRightOutlined,
  CaretDownOutlined,
  RobotOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { logout, getUserProfile } from "../auth";

const { Text } = Typography;

const planTags = {
  free: { color: "blue", text: "フリープラン" },
  standard: { color: "green", text: "スタンダードプラン" },
  pro: { color: "orange", text: "プロプラン" },
  premium: { color: "red", text: "プレミアムプラン" },
  custom: { color: "purple", text: "カスタムプラン" },
};

const AuthenticatedMenu = ({
  userProfile,
  userRole,
  ticketCount,
  isMobile,
  toggleSidebar,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [userPlan, setUserPlan] = useState(null);
  const [settingsMenuOpen, setSettingsMenuOpen] = useState(false);
  const [userMenuOpen, setUserMenuOpen] = useState(false);

  useEffect(() => {
    const fetchUserPlan = async () => {
      try {
        const profile = await getUserProfile();
        if (profile && profile.plan) {
          setUserPlan(profile.plan);
        } else {
          console.error("User profile or plan is missing");
        }
      } catch (error) {
        console.error("Failed to fetch user profile:", error);
      }
    };
    fetchUserPlan();
  }, []);

  const handleLogout = () => {
    logout();
  };

  const planTag = userPlan ? (
    <Tag
      color={planTags[userPlan.name]?.color || planTags.free.color}
      style={{
        display: "flex",
        alignItems: "center",
        marginTop: "4px",
        marginBottom: "4px",
        justifyContent: "center",
      }}
    >
      {userPlan.display_name || planTags.free.text}
    </Tag>
  ) : null;

  const menu = (
    <Menu style={{ width: "250px" }}>
      <Menu.Item key="email" style={{ pointerEvents: "none" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Text>{userProfile.email}</Text>
          {planTag}
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key="subscription-management"
        onClick={() => navigate("/subscription-management")}
        style={{ padding: "8px 16px" }}
      >
        サブスクリプション管理
        <CaretRightOutlined
          style={{
            marginLeft: "auto",
            right: "10px",
            position: "absolute",
            top: "50%",
            transform: "translate(0, -50%)",
            fontSize: "12px",
            color: "#999",
          }}
        />
      </Menu.Item>
      <Menu.Item
        key="plan"
        onClick={() => navigate("/plan")}
        style={{ padding: "8px 16px" }}
      >
        プラン変更
        <CaretRightOutlined
          style={{
            marginLeft: "auto",
            right: "10px",
            position: "absolute",
            top: "50%",
            transform: "translate(0, -50%)",
            fontSize: "12px",
            color: "#999",
          }}
        />
      </Menu.Item>
      <Menu.Item
        key="settings"
        onClick={() => navigate("/settings")}
        style={{ padding: "8px 16px" }}
      >
        登録情報変更
        <CaretRightOutlined
          style={{
            marginLeft: "auto",
            right: "10px",
            position: "absolute",
            top: "50%",
            transform: "translate(0, -50%)",
            fontSize: "12px",
            color: "#999",
          }}
        />
      </Menu.Item>
      <Menu.Item
        key="logout"
        onClick={handleLogout}
        style={{ padding: "8px 16px" }}
      >
        ログアウト
        <LogoutOutlined
          style={{
            marginLeft: "auto",
            right: "10px",
            position: "absolute",
            top: "50%",
            transform: "translate(0, -50%)",
            fontSize: "12px",
            color: "#999",
          }}
        />
      </Menu.Item>
    </Menu>
  );

  const settingsMenu = (
    <Menu style={{ width: "250px" }}>
      <Menu.Item
        key="dashboard"
        onClick={() => navigate("/admin/dashboard")}
        style={{ padding: "8px 16px" }}
      >
        ダッシュボード
        <CaretRightOutlined
          style={{
            marginLeft: "auto",
            right: "10px",
            position: "absolute",
            top: "50%",
            transform: "translate(0, -50%)",
            fontSize: "12px",
            color: "#999",
          }}
        />
      </Menu.Item>
      <Menu.Item
        key="user-management"
        onClick={() => navigate("/admin/user-management")}
        style={{ padding: "8px 16px" }}
      >
        ユーザー管理
        <CaretRightOutlined
          style={{
            marginLeft: "auto",
            right: "10px",
            position: "absolute",
            top: "50%",
            transform: "translate(0, -50%)",
            fontSize: "12px",
            color: "#999",
          }}
        />
      </Menu.Item>
      <Menu.Item
        key="transactions"
        onClick={() => navigate("/admin/transactions")}
        style={{ padding: "8px 16px" }}
      >
        トランザクション一覧
        <CaretRightOutlined
          style={{
            marginLeft: "auto",
            right: "10px",
            position: "absolute",
            top: "50%",
            transform: "translate(0, -50%)",
            fontSize: "12px",
            color: "#999",
          }}
        />
      </Menu.Item>
    </Menu>
  );

  const modeMenu = (
    <Menu>
      <Menu.Item
        key="seo-article"
        onClick={() => navigate("/seo-article")}
        style={{ padding: "8px 16px" }}
      >
        SEO記事生成
      </Menu.Item>
      <Menu.Item key="comming-soon" style={{ padding: "8px 16px" }} disabled>
        Comming Soon...
      </Menu.Item>
    </Menu>
  );

  const isSEOArticlePage = location.pathname.startsWith("/seo-article");

  return (
    <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
      {isMobile && (
        <div className="logo mobile-logo">
          <span>Ignite creativity with..</span>magicss
        </div>
      )}
      <div
        style={{ marginLeft: "auto", display: "flex", alignItems: "center" }}
      >
        {!isMobile && (
          <>
            {isSEOArticlePage ? (
              <Dropdown overlay={modeMenu} placement="bottomRight">
                <Text
                  style={{
                    marginRight: "36px",
                    color: "#0072ff",
                    textDecoration: "none",
                    transition: "color 0.3s",
                  }}
                >
                  <RobotOutlined style={{ marginRight: "4px" }} />{" "}
                  現在のモード：SEO記事生成
                </Text>
              </Dropdown>
            ) : (
              <Dropdown overlay={modeMenu} placement="bottomRight">
                <Text
                  style={{
                    marginRight: "36px",
                    color: "black",
                    textDecoration: "underline",
                    transition: "color 0.3s",
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.color = "#0072ff";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.color = "black";
                  }}
                >
                  <CaretDownOutlined style={{ marginRight: "4px" }} />
                  モード選択
                </Text>
              </Dropdown>
            )}
            <div
              style={{
                marginRight: "24px",
                display: "flex",
                alignItems: "center",
                fontSize: "18px",
              }}
            >
              <FireOutlined style={{ marginRight: "8px", color: "#0072ff" }} />
              <Text
                strong
                style={{
                  background:
                    "linear-gradient(90deg, #0072ff 0%, #00d4ff 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                <span style={{ fontSize: "16px" }}>{ticketCount}</span> 枚
              </Text>
            </div>
          </>
        )}
        <div className="header-icons">
          {(userRole === "admin" || userRole === "master") && (
            <Dropdown
              overlay={settingsMenu}
              placement="bottomRight"
              onVisibleChange={setSettingsMenuOpen}
            >
              <div
                className={`header-icon ${settingsMenuOpen ? "active" : ""}`}
              >
                <SettingOutlined />
              </div>
            </Dropdown>
          )}
          <Dropdown
            overlay={menu}
            placement="bottomRight"
            onVisibleChange={setUserMenuOpen}
          >
            <div className={`header-icon ${userMenuOpen ? "active" : ""}`}>
              <UserOutlined />
            </div>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default AuthenticatedMenu;
