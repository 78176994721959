import React, { useState } from "react";
import { Form, Input, Button, message, Typography, Row, Col } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { resetPasswordConfirm } from "../../auth";
import styles from "../auth/Auth.module.css";

const { Title } = Typography;

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const { token } = useParams();
  const navigate = useNavigate();

  const onFinish = async (values) => {
    setLoading(true);
    try {
      await resetPasswordConfirm(token, values.password);
      message.success("パスワードが正常に更新されました");
      navigate("/login");
    } catch (error) {
      message.error("パスワードの更新に失敗しました");
    }
    setLoading(false);
  };

  return (
    <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
      <Col xs={22} sm={18} md={16} lg={8}>
        <div
          className={styles.authContainer}
          style={{ boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)" }}
        >
          <div className="logo outside">
            <span>Ignite creativity with..</span>
            magicss
          </div>
          <Title
            level={2}
            style={{
              marginBottom: "24px",
              textAlign: "center",
              fontSize: "1.3rem",
              color: "#00d4ff",
            }}
          >
            パスワードの再設定
          </Title>
          <Form onFinish={onFinish} layout="vertical">
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "パスワードを入力してください" },
                { min: 8, message: "パスワードは8文字以上で入力してください" },
                {
                  validator: (_, value) => {
                    if (
                      value &&
                      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
                        value,
                      )
                    ) {
                      return Promise.reject(
                        new Error(
                          "パスワードは大文字、小文字、数字、記号を含む8文字以上で入力してください",
                        ),
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <div
                style={{
                  border: "2px solid #f5f5f5",
                  backgroundColor: "#f5f5f5",
                  transition: "all 0.3s",
                  borderRadius: "8px", // 角丸を追加
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = "#eee";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = "#f5f5f5";
                }}
                onFocus={(e) => {
                  e.currentTarget.style.border = "2px solid #0072ff";
                }}
                onBlur={(e) => {
                  e.currentTarget.style.border = "2px solid #f5f5f5";
                }}
              >
                <Input.Password
                  placeholder="新しいパスワード"
                  autoComplete="new-password"
                  size="large"
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    outline: "none",
                    padding: "8px",
                  }}
                />
              </div>
            </Form.Item>
            <Form.Item
              name="confirm_password"
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: "パスワード（確認用）を入力してください",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("パスワードが一致しません"),
                    );
                  },
                }),
              ]}
            >
              <div
                style={{
                  border: "2px solid #f5f5f5",
                  backgroundColor: "#f5f5f5",
                  transition: "all 0.3s",
                  borderRadius: "8px", // 角丸を追加
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = "#eee";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = "#f5f5f5";
                }}
                onFocus={(e) => {
                  e.currentTarget.style.border = "2px solid #0072ff";
                }}
                onBlur={(e) => {
                  e.currentTarget.style.border = "2px solid #f5f5f5";
                }}
              >
                <Input.Password
                  placeholder="パスワード（確認用）"
                  autoComplete="new-password"
                  size="large"
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    outline: "none",
                    padding: "8px",
                  }}
                />
              </div>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                block
                size="large"
                style={{
                  background:
                    "linear-gradient(90deg, #0072ff 0%, #00d4ff 100%)",
                  border: "none",
                  transition: "all 0.3s",
                  fontWeight: "bold",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.opacity = "0.7";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.opacity = "1.0";
                }}
              >
                パスワードを更新
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default ResetPassword;
