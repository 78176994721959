// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Auth_authContainer__mMQiz {
  background-color: #fff;
  padding: 48px;
  border-radius: 4px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
}

.Auth_authTitle__vrhsP {
  text-align: center;
  margin-bottom: 24px;
}

.Auth_authSegmented__AfaD4 {
  margin-bottom: 24px;
}

.Auth_authContent__Emjb4 {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Auth_form__FjcNT {
  max-width: 500px;
  margin: 0 auto;
}

.Auth_label__YUTJV {
  color: #626262;
  font-weight: 500;
}

.Auth_input__dsL2n {
  border: 2px solid #ededed;
  border-radius: 4px;
}

.Auth_input__dsL2n:focus {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.Auth_form__FjcNT .ant-form-item-explain {
  font-size: 12px;
}

@media (max-width: 1200px) {
  .Auth_authContainer__mMQiz {
    padding: 24px;
  }
  .Auth_authSegmented__AfaD4 label div {
    font-size: 14px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/auth/Auth.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,aAAa;EACb,kBAAkB;EAClB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,SAAO;EACP,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,6CAA6C;AAC/C;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE;IACE,aAAa;EACf;EACA;IACE,0BAA0B;EAC5B;AACF","sourcesContent":[".authContainer {\n  background-color: #fff;\n  padding: 48px;\n  border-radius: 4px;\n  min-height: 400px;\n  display: flex;\n  flex-direction: column;\n}\n\n.authTitle {\n  text-align: center;\n  margin-bottom: 24px;\n}\n\n.authSegmented {\n  margin-bottom: 24px;\n}\n\n.authContent {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n\n.form {\n  max-width: 500px;\n  margin: 0 auto;\n}\n\n.label {\n  color: #626262;\n  font-weight: 500;\n}\n\n.input {\n  border: 2px solid #ededed;\n  border-radius: 4px;\n}\n\n.input:focus {\n  border-color: #40a9ff;\n  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);\n}\n\n.form :global(.ant-form-item-explain) {\n  font-size: 12px;\n}\n\n@media (max-width: 1200px) {\n  .authContainer {\n    padding: 24px;\n  }\n  .authSegmented label div {\n    font-size: 14px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"authContainer": `Auth_authContainer__mMQiz`,
	"authTitle": `Auth_authTitle__vrhsP`,
	"authSegmented": `Auth_authSegmented__AfaD4`,
	"authContent": `Auth_authContent__Emjb4`,
	"form": `Auth_form__FjcNT`,
	"label": `Auth_label__YUTJV`,
	"input": `Auth_input__dsL2n`
};
export default ___CSS_LOADER_EXPORT___;
