import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  message,
  Tooltip,
  Progress,
  Space,
  Pagination,
  Breadcrumb,
  Typography,
  Divider,
} from "antd";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { getUserRole } from "../../auth";
import { Helmet } from "react-helmet";

const { Title, Text } = Typography;

const RecommendedKeywords = () => {
  const [keywords, setKeywords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 20;
  const navigate = useNavigate();

  useEffect(() => {
    fetchRecommendedKeywords();
    checkAdminStatus();
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page); // ページ変更時にcurrentPageを更新
  };

  const checkAdminStatus = async () => {
    const role = await getUserRole();
    setIsAdmin(role === "admin" || role === "master");
  };

  const fetchRecommendedKeywords = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/seo-article/keywords-recommend`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        },
      );
      setKeywords(response.data.keywords);
      setLastUpdated(response.data.recommended_keywords_update_at);
    } catch (error) {
      console.error("Failed to fetch recommended keywords:", error);
      message.error("おすすめキーワードの取得に失敗しました");
    } finally {
      setLoading(false);
    }
  };

  const handleGenerate = (keyword) => {
    navigate("/seo-article", { state: { keyword } });
  };

  const handleUpdateKeywords = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/seo-article/update-keywords-recommend`,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        },
      );
      message.success("おすすめキーワードを更新しました");
      await fetchRecommendedKeywords();
    } catch (error) {
      console.error("Failed to update recommended keywords:", error);
      message.error("おすすめキーワードの更新に失敗しました");
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "キーワード",
      dataIndex: "keyword",
      key: "keyword",
    },
    {
      title: "検索Vol",
      dataIndex: "volume",
      key: "volume",
      sorter: (a, b) => a.volume - b.volume,
    },
    {
      title: "CPC",
      dataIndex: "cpc",
      key: "cpc",
      render: (cpc) => `$${cpc?.toFixed(2) || "N/A"}`,
      sorter: (a, b) => (a.cpc || 0) - (b.cpc || 0),
    },
    {
      title: "トレンド",
      dataIndex: "trend",
      key: "trend",
      render: (_, record) => {
        const data = record.monthly_volumes || [];
        const width = 80;
        const height = 24;
        const points = data
          .map(
            (d, i) =>
              `${(i / (data.length - 1)) * width},${height - (d.volume / Math.max(...data.map((d) => d.volume))) * height}`,
          )
          .join(" ");

        return (
          <svg width={width} height={height}>
            <polyline
              fill="none"
              stroke="#00d4ff"
              strokeWidth="1"
              points={points}
            />
          </svg>
        );
      },
    },
    {
      title: "競合性",
      dataIndex: "competition",
      key: "competition",
      render: (competition) => {
        const value =
          competition !== null && competition !== undefined
            ? Math.min(Math.round(competition * 10), 10)
            : 0;
        return (
          <Tooltip
            title={`競合性: ${value}/10 (${(competition * 100).toFixed(2)}%)`}
          >
            <Progress
              percent={value * 10}
              steps={10}
              size="small"
              showInfo={false}
              strokeColor="#1890ff"
            />
          </Tooltip>
        );
      },
      sorter: (a, b) => (a.competition || 0) - (b.competition || 0),
    },
    {
      title: "アクション",
      key: "action",
      render: (_, record) => (
        <Button onClick={() => handleGenerate(record.keyword)}>生成する</Button>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>おすすめキーワードβ | SEO記事生成モード - magicss</title>
        <meta name="robots" content="noindex" />
        <meta
          property="og:title"
          content={`おすすめキーワードβ | SEO記事生成モード - magicss`}
        />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/ogp.png`}
        />
        <meta
          property="og:description"
          content={`コンテンツ生成AIツール「magicss」のおすすめキーワード（β）ページです。`}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>
          <Link to="/seo-article">SEO記事生成</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>おすすめキーワード</Breadcrumb.Item>
      </Breadcrumb>
      <Title level={1} className="gradient-text" style={{ fontSize: "24px" }}>
        あなたにおすすめのキーワード
      </Title>
      <Text style={{ color: "#6d8eb7" }}>
        ご利用を続けていくと、AIが生成履歴を学習してあなたに合ったキーワードを提案します。（β版のテスト機能です）
      </Text>
      <Divider style={{ margin: "24px 0" }} />
      <Table
        columns={columns}
        dataSource={keywords}
        loading={loading}
        rowKey="id"
        pagination={false}
        scroll={{ x: "max-content" }}
      />
    </>
  );
};

export default RecommendedKeywords;
