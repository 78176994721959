import React, { useState } from 'react';
import { Modal, Button, Space, Typography } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import { api } from '../api';

const { Title, Paragraph, Text } = Typography;

const TermsAgreementModal = ({
  visible,
  type, // 'terms' or 'privacy'
  onAgree,
  updatedDate,
}) => {
  const [loading, setLoading] = useState(false);

  const handleAgree = async () => {
    try {
      setLoading(true);
      const endpoint = `/users/agree-${type}`;
      await api.post(endpoint);
      onAgree?.();
    } catch (error) {
      console.error('Agreement failed:', error);
    } finally {
      setLoading(false);
    }
  };

  const getModalContent = () => {
    const docType = type === 'terms' ? '利用規約' : 'プライバシーポリシー';
    return {
      title: `${docType}の改訂について`,
      content: `${updatedDate}付で${docType}を改訂いたしました。\nサービスのご利用を継続するには、改訂後の${docType}への同意が必要となります。`,
      link: type === 'terms' ? '/terms-of-service' : '/privacy-policy',
      linkText: `${docType}を確認する`,
    };
  };

  const { title, content, link, linkText } = getModalContent();

  return (
    <Modal
      title={<Title level={4}>{title}</Title>}
      open={visible}
      closable={false}
      maskClosable={false}
      keyboard={false}
      footer={null}
      width={600}
      centered
    >
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Paragraph>
          {content.split('\n').map((line, i) => (
            <Text key={i}>
              {line}
              <br />
            </Text>
          ))}
        </Paragraph>

        <div style={{ textAlign: 'center', marginBottom: '24px' }}>
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              color: '#0072ff',
              transition: 'opacity 0.3s',
            }}
            onMouseEnter={e => (e.currentTarget.style.opacity = '0.7')}
            onMouseLeave={e => (e.currentTarget.style.opacity = '1')}
          >
            {linkText}
            <ExportOutlined style={{ marginLeft: '4px' }} />
          </a>
        </div>

        <Button
          type="primary"
          onClick={handleAgree}
          loading={loading}
          size="large"
          block
          style={{
            background: 'linear-gradient(90deg, #0072ff 0%, #00d4ff 100%)',
            border: 'none',
            transition: 'all 0.3s',
          }}
          onMouseEnter={e => {
            e.currentTarget.style.opacity = '0.7';
          }}
          onMouseLeave={e => {
            e.currentTarget.style.opacity = '1';
          }}
        >
          同意して続ける
        </Button>
      </Space>
    </Modal>
  );
};

export default TermsAgreementModal;
