import React from 'react';
import { Typography, Layout, Button, Divider } from 'antd';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './forStatic.css';

const { Title, Paragraph } = Typography;
const { Header, Content } = Layout;

const TermsOfService = ({ isAuthenticated }) => {
  const renderHeader = () => (
    <Header
      style={{
        background: '#fff',
        padding: '0 16px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <div style={{ flex: 1 }}></div>
      <div
        className="logo mobile-logo"
        style={{ textAlign: 'center', flex: 1 }}
      >
        <span>Ignite creativity with..</span>magicss
      </div>
      <div style={{ flex: 1, textAlign: 'right' }}>
        {!isAuthenticated && (
          <Button type="primary">
            <Link to="/login">無料登録</Link>
          </Button>
        )}
      </div>
    </Header>
  );

  return (
    <>
      <Helmet>
        <title>利用規約 | magicss</title>
        <meta name="robots" content="noindex" />
        <meta property="og:title" content={`利用規約 | magicss`} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/ogp.png`}
        />
        <meta
          property="og:description"
          content={`コンテンツ生成AIツール「magicss」の利用規約ページです。`}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Layout id="static_page">
        {renderHeader()}
        <Content className="static_inner">
          <Title level={1}>利用規約</Title>
          <Divider />
          <Title level={2}>第１条 (目的)</Title>
          <ol>
            <li>
              この利用規約(以下「本規約」とします)は、ONEWORD株式会社
              (以下「当社」といいます。)が運営する「magicss」(以下「本サービス」といいます。)の利用に関し、当社と本サービスを利用するユーザーに適用されます。
            </li>
            <li>
              当社及びユーザーとの間で締結される本サービスの利用契約(以下「本契約」とします)は、ユーザーが本規約に同意することにより成立します。
            </li>
            <li>
              本規約は、本サービスの利用条件を定めています。本サービスにユーザー登録したユーザーは全て本規約の定める条件に従って本サービスを利用します。
            </li>
          </ol>

          <Title level={2}>第２条 (定義)</Title>
          <Paragraph>
            本規約において使用する以下の用語は、各々以下に定める意味を有するものとします。
          </Paragraph>
          <ol type="⑴">
            <li>
              「ユーザー」とは、本サービスにユーザー登録した全ての方を指します。
            </li>
            <li>
              「ユーザー情報」とは、本サービスに登録しているユーザーのID及びパスワード等の情報を指します。
            </li>
            <li>
              「SEO記事」とは、検索エンジン結果ページに上位表示されるよう戦略的に作成したインターネット上のウェブサイトを指します。
            </li>
            <li>
              「チケット」とは、１枚あたりで、本サービスを１回利用することができる権利を指し、ユーザーが選択した当社のサービスプランに応じた枚数のチケットをユーザーに付与します。ただし、ユーザーが保持できるチケットの枚数は、各サービスプランに応じて、一月ごとに上限がございます。
            </li>
          </ol>

          <Title level={2}>第３条 (本サービスの内容)</Title>
          <ol>
            <li>
              本サービスは、キーワードを入力することで、入力したキーワードに関連した参考となるSEO記事をAIによって自動で生成し、ユーザーによるSEO記事作成を補助するコンテンツを提供するサービスです。
            </li>
            <li>
              前項に規定するサービスの他、当社が今後追加するサービスも本サービスに含まれます。
            </li>
            <li>
              本サービスは、契約したプラン内容に応じて、一月ごとに本サービスによってAIが作成できるSEO記事等の作成数に上限があります。
            </li>
          </ol>

          <Title level={2}>第４条 (本サービスの停止)</Title>
          <ol>
            <li>
              当社は、次の各号のいずれかに該当する場合には、本サービスの全部又は一部を停止することがあります。
              <ol type="⑴">
                <li>
                  本サービスの提供に必要となる設備等へのメンテナンス、工事、障害の対策等が必要となった場合。
                </li>
                <li>
                  コンピューター、通信回線等の障害、誤操作、過度なアクセスの集中、不正アクセス、ハッキング等により本サービスの運営ができなくなった場合。
                </li>
                <li>
                  地震、落雷、火災、風水害、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合。
                </li>
                <li>
                  行政機関又は司法機関の業務停止命令又はその指導・命令があった場合。
                </li>
                <li>その他当社が必要と判断した場合。</li>
              </ol>
            </li>
            <li>
              前項の規定により、ユーザーにいかなる損害が発生したとしても、当社は責任を負いません。ただし、当社の故意によって損害が発生したと認められた場合はこの限りではありません。
            </li>
          </ol>

          <Title level={2}>第５条 (ユーザー登録)</Title>
          <ol>
            <li>
              本サービスを利用するには、本規約に同意の上、当社が定める手続によりユーザー登録を行う必要があります。ただし、ユーザーとして登録するにあたり、未成年、成年被後見人、被保佐人及び被補助人は、法定代理人によって設定及び入力されていない若しくは法定代理人の事前の同意を得ていなかった場合は、ユーザー登録ができないものとします。
            </li>
            <li>
              ユーザーは、登録した情報に変更が生じた場合には、直ちに、当社所定の方法により、登録情報の変更手続を行う義務を負います。
            </li>
            <li>
              利用希望者が、次の各号のいずれかに該当する場合には、ユーザー登録を行うことはできません。
              <ol type="⑴">
                <li>
                  日本国内に在住していない者及び日本国内に本店を有していない法人
                </li>
                <li>未成年等であって、事前に法定代理人の同意を得ていない者</li>
                <li>過去に当社から本契約を解除されたことがある者</li>
                <li>当社との間で紛争が係属している者</li>
                <li>
                  当社との間で競業関係にある事業を行っていると当社が判断した者又はその関係者
                </li>
                <li>第１６条に定める反社会的勢力に該当する者</li>
                <li>
                  その他当社がその裁量によりユーザー登録を行うことが妥当でないと認める者
                </li>
              </ol>
            </li>
            <li>
              利用希望者は、当社に対して次に掲げる事項を表明し保証します。
              <ol type="⑴">
                <li>
                  ユーザー登録の申込にあたり入力又は記載した必要事項が、真実かつ正確であること。
                </li>
                <li>
                  本サービスの利用が第三者のいかなる権利も侵害しないこと。
                </li>
                <li>
                  違法又は不正な意図をもって本サービスを利用するものではないこと
                </li>
                <li>
                  本契約を営業のためにもしくは営業として締結するものであること。
                </li>
              </ol>
            </li>
            <li>
              当社は、ユーザーとして登録しようとする者に対して、当社の裁量によりユーザーとして不適格と判断した場合、ユーザー登録を拒否する場合があります。
            </li>
            <li>
              ユーザーは、登録情報に不備があった場合、それに起因又は関連して損害が生じたときであっても、当社が何ら責任を負わないことに同意します。
            </li>
          </ol>

          <Title level={2}>第６条 (利用料金・支払)</Title>
          <ol>
            <li>
              本サービスの利用料金並びに消費税及び地方消費税(以下「本サービス料金」という)は、当社の定める料金表に従うものとします。ただし、当社とユーザー間で、別途合意した場合はそれによるものとします。
            </li>
            <li>
              本サービス料金の支払いに関しては、当社が定める方法によってのみ、決済を行うことができるものとします。
            </li>
            <li>
              ユーザーによる本サービスの解約日が月の途中であったとしても、本サービス料金は返金いたしません。
            </li>
            <li>
              当社は、ユーザーが、支払期日までに料金を支払わない場合には、年１４．６％の割合で遅延損害金を請求することができるものとします。
            </li>
            <li>
              当社は、本サービス料金が、経済情勢、公租公課等の変動により不相当と認められると判断した場合には、社会通念上相当な範囲で本サービス料金を変更できるものとします。
            </li>
          </ol>

          <Title level={2}>第７条 (契約期間及び更新)</Title>
          <ol>
            <li>
              本契約の有効期間は、当社とユーザーが別途合意しない限り、ユーザーが選択した本サービスのプランに応じて、本契約締結の日から起算するものとします。
            </li>
            <li>
              前項の規定にかかわらず、本契約の期間満了日までに当社又はユーザーのいずれからも書面又は電磁的方法による本契約の変更又は終了の申し入れのない場合、本契約は同一条件かつ同一期間で自動的に更新され、以後も同様とします。ただし、カスタムプラン等によって、当社とユーザー間で、別途合意した場合はそれによるものとします。
            </li>
          </ol>

          <Title level={2}>第８条 (知的財産権等)</Title>
          <ol>
            <li>
              本サービスにおいて提供される全ての著作物、ソフトウェア及びコンテンツ等は、当社又は当
              社がライセンスを受けているライセンサーが著作権(著作権法第２７条及び第２８条に定める権利を含みます)等の知的財産権又はその他使用権等の権利を有しています。
            </li>
            <li>
              本サービスにおいて当社がユーザーに提供したSEO記事については、当社は、ユーザーに対し、当社に帰属する当該記事に関する著作権(著作権法第２７条及び第２８条に定める権利を含みます)を譲渡するものとします。但し、ユーザーは、当社に対し、無償かつ無制限に利用できることを許諾し同意します。
            </li>
            <li>
              本条の規定に違反して問題が生じた場合において、当該ユーザーは、自己の責任において当該問題を解決するとともに、当社に対しては、何らの不利益、損害を与えないように適切な措置を講じなければなりません。
            </li>
            <li>
              ユーザーは、著作物となりうる投稿・掲載内容について、当社並びに当社より正当に権利を取得した第三者及び当該第三者から権利を承継した者に対し、著作者人格権(公表権、氏名表示権及び同一性保持権を含みます)を行使しません。
            </li>
            <li>
              本サービスの性質上、ユーザー同士、ユーザーと当社以外の第三者との間で、著作権(著作権法第２７条及び第２８条に定める権利を含みます)等の知的財産権又はその他使用権等の権利が競合する場合がありますが、本サービスによって、ユーザーにいかなる損害が発生したとしても、当社は責任を負いません。
            </li>
          </ol>

          <Title level={2}>第９条 (禁止事項)</Title>
          <ol>
            <li>
              ユーザーは、本サービスの利用にあたり、以下の各号に定める行為を行わないものとします。
              <ol type="⑴">
                <li>本規約に違反する行為をすること。</li>
                <li>
                  本サービス上に含まれる当社又は第三者の知的財産権、プライバシー若しくは肖像権等の財産的又は人格的な権利を侵害する行為をすること、又は、その恐れのある行為をすること。
                </li>
                <li>
                  当社又は第三者に不利益若しくは損害を与える行為をすること、又は、その恐れのある行為をすること。
                </li>
                <li>
                  当社又は第三者を差別又は誹謗中傷し、他人の名誉や信用を毀損する行為をすること、又は、その恐れのある行為をすること。
                </li>
                <li>
                  法令、規則、条例、告示、その他規定等に違反する行為をすること。
                </li>
                <li>
                  公序良俗又は信義則に反する行為をすること、又は、その恐れのある行為をすること。
                </li>
                <li>
                  犯罪行為、若しくはこれを助長する行為をすること、又は、その恐れのある行為をすること。
                </li>
                <li>児童に有害な悪影響を及ぼす恐れのある行為をすること。</li>
                <li>
                  無限連鎖講を開設し、又はこれを勧誘するような行為をすること。
                </li>
                <li>
                  選挙活動及び選挙の事前活動又はこれらに類似する活動及び公職選挙法に抵触する行為をすること、又は、その恐れのある行為をすること。
                </li>
                <li>
                  ユーザー登録をする際に、虚偽の情報を送信、登録すること。
                </li>
                <li>
                  当社のシステムへ不正にアクセスし、情報の改ざん又は消去等の行為をすること、若しくは、コンピューターウィルス等の有害なコンピュータープログラム等を送信又は掲載する等、本サービスの正常な運営を妨げる行為をすること、又は、その恐れのある行為をすること。
                </li>
                <li>
                  本サービスのネットワーク又はシステム等に過度な負荷をかけること。
                </li>
                <li>
                  他のユーザー又は第三者になりすまして、本サービスを利用し、本サービス上の権利を侵害し、若しくは迷惑、不利益を与える行為をすること、又、その恐れのある行為をすること。
                </li>
                <li>その他、当社が不適当、不適切と判断する行為をすること。</li>
              </ol>
            </li>
            <li>
              当社は、ユーザーの行為が、第１項各号のいずれかに該当すると判断した場合には、事前に通知を要することなく、以下の各号のいずれか又は全ての措置を講じることができます。なお、以下各号の措置に関連してユーザー又は第三者に生じた損害及び不利益につき、当社は責任を負いません。
              <ol type="⑴">
                <li>本サービスの利用制限の処置をすること。</li>
                <li>本契約の解除による退会処分の処置をすること。</li>
                <li>
                  その他当社が合理的に必要であると判断した処置をすること。
                </li>
              </ol>
            </li>
          </ol>

          <Title level={2}>第１０条 (解除)</Title>
          <ol>
            <li>
              当社は、ユーザーが以下の各号のいずれかに該当した場合、何らの通知等を要することなく、本契約を解除し、退会させることができます。
              <ol type="⑴">
                <li>
                  未成年、成年被後見人、被保佐人又は被補助人が、法定代理人、成年後見人、保佐人又は補助人等の同意なく、本サービスを利用した場合。
                </li>
                <li>
                  相続人等からの連絡等により、当社がユーザーの死亡の事実を確認できた場合。
                </li>
                <li>ユーザーが過去に退会処分を受けていた場合。</li>
                <li>第８条１項のいずれかに違反した場合。</li>
                <li>その他当社が不適当と判断した場合。</li>
              </ol>
            </li>
            <li>
              ユーザーが退会を希望する場合には、当社所定の退会手続の方法により、本契約を解除し、退会することができます。
            </li>
            <li>
              退会したユーザーは、退会時に期限の利益を喪失し、直ちに、当社に対して負担する全ての債務を履行します。
            </li>
          </ol>

          <Title level={2}>第１１条 (免責事項)</Title>
          <ol>
            <li>
              当社は、本サービスの内容について、その正確性、品質、完全性、適法性、信頼性、及び適切性等について保証をしません。
            </li>
            <li>
              当社は、本サービスに中断、中止その他の障害が生じないことを保証しません。
            </li>
            <li>
              予期することのできない不正アクセス等の行為によって、万が一ユーザー情報を盗取され、ユーザーに対して損害等が生じた場合でも、当社の故意又は重過失による場合を除き、当社は責任を負いません。
            </li>
            <li>
              当社は、天変地異、法令の制定改廃、交通事故、輸送機関の事故、労働争議、その他自然的又は人為的な事象であって、その責めに帰することができない不可抗力により本契約の全部又は一部に不履行が発生した場合、責任を負いません。
            </li>
            <li>
              本サービスの利用に関し、ユーザーが本サービスの内外を問わず、トラブルになった場合でも、当社の故意又は重過失による場合を除き、当社は責任を負わず、これらのトラブルは、当該ユーザーが自らの責任と負担において解決します。
            </li>
            <li>
              本サービスの利用に当たって、ユーザー間で発生した、いかなる紛争に対しても当社は責任を負いません。
            </li>
          </ol>

          <Title level={2}>第１２条 (広告主との取引)</Title>
          <Paragraph>
            本サービス上に掲載されている広告やリンク先等について、広告主やリンク先との連絡及び取引は、ユーザーが自己の責務で行うものとし、当社は何ら責任を負わないものとします。
          </Paragraph>

          <Title level={2}>第１３条 (損害賠償)</Title>
          <ol>
            <li>
              ユーザーは、本規約に違反し、当社に損害を与えた場合、その損害(逸失利益及び弁護士費用を含みます)を賠償します。
            </li>
            <li>
              当社は、ユーザーに損害を与えた場合には、現実かつ直接に発生した通常の損害(特別損害、逸失利益、間接損害及び弁護士費用を除く)の範囲内とし、かつ本サービス利用料金の１年分を上限とする金額でのみその損害を賠償する責任を負います。
            </li>
          </ol>

          <Title level={2}>第１４条 (通信機器・費用負担)</Title>
          <Paragraph>
            ユーザーは、本サービスの提供を受けるために必要な機器、ソフトウェア、通信手段等の環境及び必要となる通信費用、接続料等を、全て自らの負担と責任で設置、運用していただく必要があります。
          </Paragraph>

          <Title level={2}>第１５条 (本サービスの変更・廃止)</Title>
          <ol>
            <li>
              当社は、当社が合理的な理由により、本サービスの内容を変更又は提供を廃止すべきと判断した場合には、ユーザーに事前に通知のうえ、本サービスの変更又は提供を廃止できます。
            </li>
            <li>
              前項の場合において、当社に故意又は重過失がある場合を除き、責任を負いません。
            </li>
          </ol>

          <Title level={2}>第１６条 (秘密保持)</Title>
          <ol>
            <li>
              ユーザーは、本サービスの提供に関して知り得た秘密情報を、当社の事前の書面による同意なく、第三者に対して開示し漏洩してはなりません。尚、秘密情報とは、文書、電磁的データ、口頭その他形式の如何を問わず、本サービス導入に関して開示された当社又は第三者の技術上又は営業上の情報をいいます。
            </li>
            <li>
              次の各号の情報は、秘密情報に該当しないものとします。
              <ol type="⑴">
                <li>開示を受けた時、既に所有していた情報。</li>
                <li>
                  開示を受けた時、既に公知であった情報又はその後自己の責に帰さない事由により公知となった情報。
                </li>
                <li>開示を受けた後に、第三者から合法的に取得した情報。</li>
                <li>
                  開示された秘密情報によらず独自に開発し又は創作した情報。
                </li>
              </ol>
            </li>
            <li>
              ユーザーは、本契約が終了した場合、又は当社の指示があった場合には、速やかに秘密情報を当社に指示に従い返却又は廃棄するものとし、以後は使用しないものとします。
            </li>
          </ol>

          <Title level={2}>第１７条 (反社会的勢力の排除)</Title>
          <ol>
            <li>
              ユーザーは、次の各号のいずれか一にも該当しないことを表明し、かつ、将来にわたっても該当しないことを表明し、保証します。
              <ol type="⑴">
                <li>
                  自ら又は自らの役員が、暴力団、暴力団員、暴力団員でなくなった時から5年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋、社会運動等標ぼうゴロ又は特殊知能暴力集団等その他これらに準じる者(以下総称して「暴力団員等」とします)であること。
                </li>
                <li>
                  暴力団員等が経営を支配していると認められる関係を有すること。
                </li>
                <li>
                  暴力団員等が経営に実質的に関与していると認められる関係を有すること。
                </li>
                <li>
                  自ら若しくは第三者の不正の利益を図る目的又は第三者に損害を加える目的をもってするなど、暴力団員等を利用していると認められる関係を有すること。
                </li>
                <li>
                  暴力団員等に対して資金等を提供し、又は便宜を供与するなどの関与をしていると認められる関係を有すること。
                </li>
                <li>
                  自らの役員又は自らの経営に実質的に関与している者が暴力団員等と社会的に非難されるべき関係を有すること。
                </li>
              </ol>
            </li>
            <li>
              ユーザーは、自ら又は第三者を利用して次の各号のいずれか一にでも該当する行為を行わないことを保証します。
              <ol type="⑴">
                <li>暴力的な要求行為。</li>
                <li>法的な責任を超えた不当な要求行為。</li>
                <li>取引に関して、脅迫的な言動をし、又は暴力を用いる行為。</li>
                <li>
                  風説を流布し、偽計を用い又は威力を用いて相手方の信用を毀損し、又は相手方の業務を妨害する行為。
                </li>
                <li>その他前各号に準ずる行為。</li>
              </ol>
            </li>
            <li>
              当社は、ユーザーが前二項に違反した場合は、自己の責に帰すべき事由の有無を問わず、通知又は催告等何らの手続きを要しないで直ちに本契約を解除することができるものとします。
            </li>
            <li>
              当社は、前項の規定により本契約を解除した場合、これによりユーザーに損害が生じても、その賠償責任を負わないものとし、ユーザーはこれを了承します。
            </li>
          </ol>

          <Title level={2}>第１８条 (連絡・通知)</Title>
          <ol>
            <li>
              ユーザーより、本サービスに関する問い合わせ、連絡又は通知がある場合、当社は、回答及び対応するよう努めますが、法令又は本規約上、当社に義務又は責任が発生する場合を除き、その義務を負いません。
            </li>
            <li>
              当社から、本規約の変更に関する通知、その他ユーザーに対する連絡又は通知がある場合には、電子メールその他当社の定める方法で行うものとします。
            </li>
          </ol>

          <Title level={2}>第１９条 (地位の譲渡)</Title>
          <ol>
            <li>
              ユーザーは、本契約上の地位又は本規約に基づく権利若しくは義務の全部又は一部について、第三者に対し、利用、貸与、譲渡をすること、又は、担保設定、その他の処分をすることはできません。
            </li>
            <li>
              当社は、本サービスにかかる事業を他社に譲渡した場合、当該事業譲渡に伴い利用契約上の地位、本規約に基づく権利及び義務並びに利用者の登録事項その他の顧客情報を当該事業譲渡の譲受人に譲渡することができるものとし、ユーザーは、かかる譲渡につき本項において予め同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。
            </li>
          </ol>

          <Title level={2}>第２０条 (個人情報の取扱い)</Title>
          <Paragraph>
            本サービスにおける個人情報の取り扱いに関しては、当社が定める「プライバシーポリシー」に基づき取り扱うものとします。
          </Paragraph>

          <Title level={2}>第２１条 (違法行為への対応)</Title>
          <ol>
            <li>
              ユーザーは、本規約に違反する行為を発見した場合には、速やかに当社に連絡・通知をするよう努めなくてはならない。
            </li>
            <li>
              本規約に違反する行為に対しての当社の対処については、ユーザーは、異議を申し立てることはできません。
            </li>
          </ol>

          <Title level={2}>第２２条 (存続条項)</Title>
          <Paragraph>
            前条の規定にかかわらず、第８条(知的財産権)、第１０条(解除)第３項、第１１条(免責事項)、第１２条(広告主との取引)、第１３条(損害賠償)、第１４条(本サービスの変更・廃止)、第１６条(秘密保持)、第１７条(反社会的勢力の排除)第４項、第１９条(地位の譲渡)、本条から第２７条(その他)の規定は、本契約の終了後も有効に存続するものとします。
          </Paragraph>

          <Title level={2}>第２３条 (本規約の変更)</Title>
          <ol>
            <li>
              当社は、以下の各号のいずれかに該当する場合は、民法第548条の4の規定に基づき本規約を随時変更できます。本規約が変更された後の本契約は、変更後の本規約が適用されます。
              <ol type="⑴">
                <li>本規約の変更が、ユーザーの一般の利益に適合するとき。</li>
                <li>
                  本規約の変更が、契約をした目的に反せず、かつ、変更の必要性、変更後の内容の相当性及びその内容その他の変更に係る事情に照らして合理的なものであるとき。
                </li>
              </ol>
            </li>
            <li>
              当社は、本規約の変更を行う場合には、変更後の本規約の効力発生時期を定め、効力発生時期の２週間前までに、その内容をユーザーに通知し、本サービス上への表示その他当社所定の方法によりユーザーに周知します。
            </li>
            <li>
              前２項の規定にかかわらず、前項の本規約の変更の周知後にユーザーが本サービスを利用した場合又は当社所定の期間内にユーザーが解約の手続を取らなかった場合、当該ユーザーは本規約の変更に同意したものとします。
            </li>
          </ol>

          <Title level={2}>第２４条 (専属的合意管轄)</Title>
          <Paragraph>
            本契約及び本規約に関するユーザーと当社との間における一切の紛争については、東京地方裁判所又は東京簡易裁判所を第一審の専属的合意管轄裁判所とします。
          </Paragraph>

          <Title level={2}>第２５条 (分離可能性)</Title>
          <Paragraph>
            本規約のいずれかの条項の全部又はその一部が、消費者契約法その他の法令等により無効又は執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。
          </Paragraph>

          <Title level={2}>第２６条 (準拠法)</Title>
          <Paragraph>
            本契約及び本規約の成立、効力、解釈及び履行については、日本国法に準拠するものとします。
          </Paragraph>

          <Title level={2}>第２７条 (その他)</Title>
          <ol>
            <li>
              本規約に定めのない事項について、又は、当社が細目等を別途定めた場合には、ユーザーはこれに従うものとします。当該細目等は、本規約と一体をなします。
            </li>
            <li>
              細目等は、掲載した時点より効力を生じ、本規約の内容に矛盾抵触がある場合には、本規約が優先します。
            </li>
          </ol>

          <Paragraph>附則</Paragraph>
          <Paragraph style={{ textAlign: 'right' }}>
            2024年10月8日 制定
          </Paragraph>
        </Content>
      </Layout>
    </>
  );
};

export default TermsOfService;
