import React, { useState, useEffect, useCallback } from 'react';
import {
  Table,
  Button,
  message,
  Tag,
  Progress,
  Breadcrumb,
  Input,
  Typography,
  Divider,
  Modal,
  Tooltip,
  Spin,
  Space,
  Row,
  Col,
  Card,
} from 'antd';
import {
  SearchOutlined,
  UserOutlined,
  EyeOutlined,
  BankOutlined,
  MailOutlined,
} from '@ant-design/icons';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { getUserRole, getUserProfile } from '../../auth';
import moment from 'moment-timezone';
import debounce from 'lodash/debounce';
import UserDetail from '../UserManagement/UserDetail';

const { Title, Text } = Typography;
const { Search } = Input;

const ArticleList = () => {
  const [articles, setArticles] = useState([]);
  const [userRole, setUserRole] = useState('user');
  const [currentUser, setCurrentUser] = useState(null);
  const [userDetails, setUserDetails] = useState({});
  const [relatedKeywords, setRelatedKeywords] = useState([]);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [hasBaseKeyword, setHasBaseKeyword] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [selectedKeyword, setSelectedKeyword] = useState('');
  const [selectedArticleUuid, setSelectedArticleUuid] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchOptions, setSearchOptions] = useState([]);
  const navigate = useNavigate();
  const { userId } = useParams();
  const location = useLocation();
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [userDetailModalVisible, setUserDetailModalVisible] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [filters, setFilters] = useState({});
  const [sorter, setSorter] = useState({});
  const [dateRange, setDateRange] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const fetchArticlesAndUsers = useCallback(
    async (
      page = pagination.current,
      pageSize = pagination.pageSize,
      newFilters = filters,
      newSorter = sorter,
      newDateRange = dateRange,
      newUserId = userId,
    ) => {
      setLoading(true);
      try {
        const params = {
          page,
          per_page: pageSize,
          keyword: newFilters.keyword?.[0] || '',
          status: newFilters.status?.[0] || '',
          start_date: newDateRange[0]?.format('YYYY-MM-DD'),
          end_date: newDateRange[1]?.format('YYYY-MM-DD'),
          user_id: newUserId,
          sort_field: newSorter.field,
          sort_order: newSorter.order,
        };

        console.log('Fetching articles with params:', params);

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/seo-article/list`,
          {
            params,
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          },
        );

        console.log('Received articles:', response.data.articles);

        setArticles(response.data.articles);
        setPagination(prev => ({
          ...prev,
          current: page,
          pageSize,
          total: response.data.total_count,
        }));

        // ユーザー情報の取得は管理者とマスター権限を持つユーザーの場合のみ行う
        if (userRole === 'admin' || userRole === 'master') {
          const userIds = [
            ...new Set(response.data.articles.map(article => article.user_id)),
          ];

          if (userIds.length > 0) {
            const userResponse = await axios.get(
              `${process.env.REACT_APP_API_URL}/users/`,
              {
                params: { user_ids: userIds.join(',') },
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
              },
            );

            const userDetailsMap = {};
            userResponse.data.forEach(user => {
              userDetailsMap[user.user_id] = user;
            });
            setUserDetails(userDetailsMap);
          }
        }
      } catch (error) {
        console.error('Failed to fetch articles and user details:', error);
        message.error('データの取得に失敗しました');
      } finally {
        setLoading(false);
      }
    },
    [userRole, filters, sorter, dateRange, userId, pagination],
  );

  useEffect(() => {
    const initializeData = async () => {
      try {
        const role = await getUserRole();
        const profile = await getUserProfile();
        setUserRole(role);
        setCurrentUser(profile);

        if (userId && role !== 'admin' && role !== 'master') {
          navigate('/seo-article/list');
        } else {
          fetchArticlesAndUsers();
        }
      } catch (error) {
        console.error('Failed to initialize data:', error);
        message.error('データの初期化に失敗しました');
      }
    };

    initializeData();
  }, [userId, navigate]); // fetchArticlesAndUsers を依存配列から削除

  const updateArticleStatuses = useCallback(async () => {
    const currentArticleIds = articles.map(article => article.id);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/seo-article/status-updates`,
        { article_ids: currentArticleIds },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        },
      );
      const updates = response.data.updates;
      setArticles(prevArticles =>
        prevArticles.map(article => {
          const update = updates.find(u => u.id === article.id);
          if (update) {
            return {
              ...article,
              ...update,
              completed_at: update.completed_at
                ? new Date(update.completed_at)
                : null,
            };
          }
          return article;
        }),
      );
    } catch (error) {
      console.error('Failed to update article statuses:', error);
    }
  }, [articles]);

  useEffect(() => {
    const intervalId = setInterval(updateArticleStatuses, 5000); // 5秒ごとに更新
    return () => clearInterval(intervalId);
  }, [updateArticleStatuses]);

  const handleTableChange = (newPagination, newFilters, newSorter) => {
    console.log('Table change:', {
      pagination: newPagination,
      filters: newFilters,
      sorter: newSorter,
    });

    // 現在のページネーション情報を更新
    setPagination(prev => ({
      ...prev,
      current: newPagination.current,
      pageSize: newPagination.pageSize,
    }));

    setSorter(newSorter);

    // ステータスフィルターの処理
    const newStatus = newFilters?.status ? newFilters.status[0] : null;
    setSelectedStatus(newStatus);

    // 実際のデータ取得
    fetchArticlesAndUsers(
      newPagination.current, // 現在のページ
      newPagination.pageSize, // ページサイズ
      { ...filters, status: newStatus ? [newStatus] : [] },
      newSorter,
      dateRange,
      userId,
    );
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    // filtersステートを更新
    const newFilters = {
      ...filters,
      [dataIndex]: selectedKeys,
    };
    setFilters(newFilters);

    fetchArticlesAndUsers(
      pagination.current,
      pagination.pageSize,
      newFilters,
      sorter,
      dateRange,
      userId,
    );
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              // クリア時の処理も追加
              const newFilters = { ...filters };
              delete newFilters[dataIndex];
              setFilters(newFilters);
              fetchArticlesAndUsers(
                pagination.current,
                pagination.pageSize,
                newFilters,
                sorter,
                dateRange,
                userId,
              );
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      if (!value || !record || !record[dataIndex]) return false;

      const recordValue = String(record[dataIndex]); // 値を文字列に変換
      const searchValue = String(value); // 検索値を文字列に変換

      return recordValue.toLowerCase().includes(searchValue.toLowerCase());
    },
  });

  const getStatusColor = status => {
    if (status.includes('予約中') || status.includes('更新予約中'))
      return 'orange';
    if (status.includes('中') || status.includes('更新中')) return 'green';
    if (status === '生成済み') return 'blue';
    if (status === '生成失敗') return 'red';
    return 'default';
  };

  const showRegenModal = record => {
    Modal.confirm({
      title: 'タスクの再実行',
      content: `「${record.keyword}」のタスクを再実行しますか？ 10枚のチケットが消費されます。`,
      onOk: () => handleRegenerate(record),
    });
  };

  const handleRegenerate = async record => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/seo-article/regenerate/${record.uuid}`,
        {
          task_type: 'generate',
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        },
      );

      if (response.status === 200 && response.data.message) {
        message.success(response.data.message);
        setArticles(prevArticles =>
          prevArticles.map(article =>
            article.uuid === record.uuid
              ? { ...article, status: '予約中' }
              : article,
          ),
        );
      } else {
        throw new Error('Unexpected response from server');
      }
    } catch (error) {
      console.error('Failed to regenerate task:', error);
      if (error.response && error.response.status === 200) {
        message.success('タスクの再実行が予約されました');
        await fetchArticlesAndUsers();
      } else {
        message.error(
          'タスクの再実行に失敗しました: ' +
            (error.response?.data?.detail || error.message),
        );
      }
    }
  };

  const showModal = useCallback(record => {
    setSelectedKeyword(record.keyword);
    setSelectedArticleUuid(record.uuid);
    setModalVisible(true);
    setModalLoading(true);
    fetchRelatedKeywords(record.uuid);
  }, []);

  const fetchRelatedKeywords = useCallback(async articleUuid => {
    setModalLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/related-keywords/article/${articleUuid}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setRelatedKeywords(response.data.related_keywords || []);
      setLastUpdated(response.data.last_updated);
      setHasBaseKeyword(response.data.has_base_keyword);
    } catch (error) {
      console.error('Failed to fetch related keywords:', error);
      message.error('関連キーワードの取得に失敗しました');
      setRelatedKeywords([]);
    } finally {
      setModalLoading(false);
    }
  }, []);

  const handleFetchRelatedKeywords = useCallback(async () => {
    if (selectedArticleUuid) {
      setModalLoading(true);
      try {
        const token = localStorage.getItem('token');
        await axios.post(
          `${process.env.REACT_APP_API_URL}/seo-article/fetch-related-keywords/article/${selectedArticleUuid}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        await fetchRelatedKeywords(selectedArticleUuid);
      } catch (error) {
        console.error('Failed to fetch related keywords:', error);
        message.error('関連キーワードの取得に失敗しました');
      } finally {
        setModalLoading(false);
      }
    }
  }, [selectedArticleUuid, fetchRelatedKeywords]);

  const handleDelete = async record => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/seo-article/${record.uuid}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        },
      );
      message.success('記事が削除されました');
      await fetchArticlesAndUsers();
    } catch (error) {
      console.error('Failed to delete article:', error);
      if (error.response && error.response.status === 404) {
        message.warning('この記事は既に削除されています');
        await fetchArticlesAndUsers(); // 404エラーの場合も記事リストを更新
      } else {
        message.error('記事の削除に失敗しました');
      }
    }
  };

  const showDeleteConfirm = record => {
    Modal.confirm({
      title: '記事の削除',
      content: '本当にこの記事を削除しますか？この操作は取り消せません。',
      okText: '削除',
      okType: 'danger',
      cancelText: 'キャンセル',
      onOk() {
        handleDelete(record);
      },
    });
  };

  const columns = [
    {
      title: 'ステータス',
      dataIndex: 'status',
      key: 'status',
      filters: [
        { text: '予約中', value: '予約中' },
        { text: '更新予約中', value: '更新予約中' },
        { text: '生成中', value: '生成中' },
        { text: '更新中', value: '更新中' },
        { text: '生成済み', value: '生成済み' },
        { text: '本文完了', value: '本文完了' },
        { text: '生成失敗', value: '生成失敗' },
      ],
      filterMode: 'menu',
      filteredValue: selectedStatus ? [selectedStatus] : null,
      filterMultiple: false,
      render: (status, record) => {
        return (
          <>
            <Tag color={getStatusColor(status)}>{status.split('（')[0]}</Tag>
            {status.includes('（') && (
              <span
                style={{ marginLeft: '4px', fontSize: '12px', color: '#888' }}
              >
                {status.split('（')[1].replace('）', '')}
              </span>
            )}
            {status === '生成済み' && (
              <>{record.has_content && <Tag color="blue">本文</Tag>}</>
            )}
            {status === '生成中' && (
              <Progress
                id="progress"
                style={{
                  display: 'inline-block',
                  width: '100px',
                  marginLeft: '10px',
                }}
                percent={record.progress}
                size="small"
              />
            )}
            {status === '生成失敗' && !record.structured_headings && (
              <Button
                size="small"
                onClick={() => showRegenModal(record)}
                style={{ marginLeft: '0px' }}
              >
                再実行
              </Button>
            )}
          </>
        );
      },
      width: 140,
    },
    {
      title: 'キーワード',
      dataIndex: 'keyword',
      key: 'keyword',
      ...getColumnSearchProps('keyword'),
      filteredValue: filters.keyword || null,
      width: 200,
    },
    {
      title: '結果',
      key: 'result',
      render: (_, record) => {
        if (record.status === '生成済み' || record.structured_headings) {
          return (
            <Link to={`/seo-article/detail/${record.uuid}`}>
              <Button
                type="primary"
                style={{
                  background:
                    'linear-gradient(90deg, #0072ff 0%, #00d4ff 100%)',
                  border: 'none',
                  transition: 'all 0.3s',
                }}
                onMouseEnter={e => {
                  e.currentTarget.style.opacity = '0.7';
                }}
                onMouseLeave={e => {
                  e.currentTarget.style.opacity = '1.0';
                }}
              >
                結果
              </Button>
            </Link>
          );
        } else if (record.status === '生成失敗') {
          return (
            <Button type="danger" onClick={() => showDeleteConfirm(record)}>
              取消
            </Button>
          );
        }
        return null;
      },
      width: 100,
    },
    {
      title: '生成実行日',
      dataIndex: 'execution_date',
      key: 'execution_date',
      sorter: true,
      render: (text, record) => {
        if (record.status.includes('予約中')) {
          if (record.estimated_wait_time === 0) {
            return '予想待ち時間: 計算中';
          } else if (
            record.estimated_wait_time !== null &&
            record.estimated_wait_time !== undefined
          ) {
            return `予想待ち時間: ${record.estimated_wait_time}分`;
          } else {
            return '予想待ち時間: 不明';
          }
        }
        return text
          ? moment.utc(text).tz('Asia/Tokyo').format('YYYY/MM/DD HH:mm')
          : '-';
      },
      width: 160,
    },
    {
      title: '完了日',
      dataIndex: 'completed_at',
      key: 'completed_at',
      sorter: true,
      render: (text, record) => {
        if (record.status === '生成済み' || record.status === '生成失敗') {
          return text
            ? moment.utc(text).tz('Asia/Tokyo').format('YYYY/MM/DD HH:mm')
            : '-';
        }
        return '-';
      },
      width: 160,
    },
  ];

  if (userRole === 'admin' || userRole === 'master') {
    columns.push({
      title: '',
      key: 'user',
      render: (_, record) => (
        <Tooltip
          title={() => {
            const user = userDetails[record.user_id];
            if (!user) {
              return '読み込み中...';
            }
            return (
              <>
                <div>{user.email}</div>
                <div>{user.company_name}</div>
                <div>{user.department_name}</div>
                <div>
                  {user.last_name} {user.first_name}
                </div>
              </>
            );
          }}
        >
          <UserOutlined
            style={{
              cursor: 'pointer',
              transition: 'color 0.3s',
            }}
            onMouseEnter={e => (e.currentTarget.style.color = '#1890ff')}
            onMouseLeave={e => (e.currentTarget.style.color = 'inherit')}
            onClick={() => {
              setSelectedUserId(record.user_id);
              setUserDetailModalVisible(true);
            }}
          />
        </Tooltip>
      ),
      width: 50,
    });
    columns.push({
      title: '関連KW',
      key: 'relatedKeywords',
      render: (_, record) => (
        <Button
          onClick={() => showModal(record)}
          icon={record.has_base_keyword ? <EyeOutlined /> : <SearchOutlined />}
        >
          {record.has_base_keyword ? '表示' : '取得'}
        </Button>
      ),
      width: 100,
    });
  }

  const RelatedKeywordsModal = ({
    visible,
    onCancel,
    keyword,
    relatedKeywords,
    lastUpdated,
    loading,
    hasBaseKeyword,
    handleFetchRelatedKeywords,
  }) => {
    const columns = [
      {
        title: 'キーワード',
        dataIndex: 'keyword',
        key: 'keyword',
      },
      {
        title: '検索Vol',
        dataIndex: 'volume',
        key: 'volume',
        sorter: (a, b) => a.volume - b.volume,
      },
      {
        title: 'CPC',
        dataIndex: 'cpc',
        key: 'cpc',
        render: cpc => `$${cpc?.toFixed(2) || 'N/A'}`,
        sorter: (a, b) => (a.cpc || 0) - (b.cpc || 0),
      },
      {
        title: 'トレンド',
        dataIndex: 'trend',
        key: 'trend',
        render: (_, record) => {
          const data = record.monthly_volumes || [];
          const width = 80;
          const height = 24;
          const points = data
            .map(
              (d, i) =>
                `${(i / (data.length - 1)) * width},${height - (d.volume / Math.max(...data.map(d => d.volume))) * height}`,
            )
            .join(' ');

          return (
            <svg width={width} height={height}>
              <polyline
                fill="none"
                stroke="#00d4ff"
                strokeWidth="1"
                points={points}
              />
            </svg>
          );
        },
      },
      {
        title: '競合性',
        dataIndex: 'competition',
        key: 'competition',
        render: competition => {
          const value =
            competition !== null && competition !== undefined
              ? Math.min(Math.round(competition * 10), 10)
              : 0;
          return (
            <Tooltip
              title={`競合性: ${value}/10 (${(competition * 100).toFixed(2)}%)`}
            >
              <Progress
                percent={value * 10}
                steps={10}
                size="small"
                showInfo={false}
                strokeColor="#1890ff"
              />
            </Tooltip>
          );
        },
        sorter: (a, b) => (a.competition || 0) - (b.competition || 0),
      },
    ];

    return (
      <Modal
        title={
          <div style={{ fontSize: '16px' }}>
            <span style={{ fontSize: '20px', color: '#00d4ff' }}>
              「{keyword}」
            </span>
            の関連キーワード
          </div>
        }
        visible={visible}
        centered
        onCancel={onCancel}
        footer={[
          <Button
            key="fetch"
            onClick={handleFetchRelatedKeywords}
            loading={loading}
          >
            関連キーワードを更新
          </Button>,
          <Button key="close" onClick={onCancel}>
            閉じる
          </Button>,
        ]}
        width="80vw"
      >
        {lastUpdated && (
          <Text style={{ fontSize: '12px' }}>
            取得日時:{' '}
            {moment
              .utc(lastUpdated)
              .tz('Asia/Tokyo')
              .format('YYYY/MM/DD HH:mm')}
          </Text>
        )}
        <Spin spinning={loading}>
          {relatedKeywords && relatedKeywords.length > 0 ? (
            <Table
              dataSource={relatedKeywords}
              columns={columns}
              pagination={{ pageSize: 10 }}
              scroll={{ y: 500 }}
              style={{ marginTop: '24px' }}
            />
          ) : (
            <p style={{ marginTop: '24px' }}>
              関連キーワードが見つかりません。
            </p>
          )}
        </Spin>
      </Modal>
    );
  };

  return (
    <>
      <Helmet>
        <title>生成結果一覧 | SEO記事生成モード - magicss</title>
        <meta name="robots" content="noindex" />
        <meta
          property="og:title"
          content={`生成結果一覧 | SEO記事生成モード - magicss`}
        />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/ogp.png`}
        />
        <meta
          property="og:description"
          content={`コンテンツ生成AIツール「magicss」のSEO記事生成結果の一覧ページです。`}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Row gutter={24}>
        <Col xs={24} lg={14}>
          <Breadcrumb style={{ margin: '16px 0' }}>
            <Breadcrumb.Item>
              <Link to="/seo-article">SEO記事生成</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>生成結果一覧</Breadcrumb.Item>
          </Breadcrumb>
          <Title
            level={1}
            className="gradient-text"
            style={{ fontSize: '24px' }}
          >
            生成結果一覧
          </Title>
          <Text style={{ color: '#6d8eb7' }}>
            テーブルの項目行にあるアイコンをクリックすると、絞り込みや検索、データの並び替えができます。
          </Text>
        </Col>
        <Col xs={24} lg={10}>
          {userId && (userRole === 'admin' || userRole === 'master') && (
            <Card
              className="by_user"
              style={{
                marginBottom: '20px',
                marginTop: '20px',
                backgroundColor: '#fffde4',
              }}
            >
              <Row gutter={16}>
                <Col span={24}>
                  <div style={{ marginBottom: '8px' }}>
                    <Tag>{userDetails[userId]?.plan.name || 'User'}</Tag>
                  </div>
                  <div style={{ marginBottom: '8px' }}>
                    <MailOutlined style={{ marginRight: '8px' }} />
                    <Text>{userDetails[userId]?.email}</Text>
                  </div>
                </Col>
                <Col span={24}>
                  <div style={{ marginBottom: '8px' }}>
                    <BankOutlined style={{ marginRight: '8px' }} />
                    <Text>
                      {userDetails[userId]?.company_name}{' '}
                      {userDetails[userId]?.department_name}
                    </Text>
                  </div>
                  <Text>
                    {userDetails[userId]?.last_name}{' '}
                    {userDetails[userId]?.first_name}
                  </Text>
                  <Text type="secondary" style={{ fontSize: '12px' }}>
                    （{userDetails[userId]?.last_name_kana}{' '}
                    {userDetails[userId]?.first_name_kana}）
                  </Text>
                </Col>
              </Row>
            </Card>
          )}
        </Col>
      </Row>
      <Divider style={{ margin: '24px 0' }} />
      <Table
        columns={columns}
        dataSource={articles}
        rowKey="id"
        loading={loading}
        onChange={handleTableChange}
        pagination={{
          ...pagination,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: (total, range) => `${range[0]}-${range[1]} / ${total}件`,
        }}
        scroll={{ x: 'max-content' }}
      />
      <UserDetail
        visible={userDetailModalVisible}
        onCancel={() => setUserDetailModalVisible(false)}
        userId={selectedUserId}
        onUpdate={() => {
          setUserDetailModalVisible(false);
          fetchArticlesAndUsers();
        }}
      />
      <RelatedKeywordsModal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        keyword={selectedKeyword}
        relatedKeywords={relatedKeywords}
        lastUpdated={lastUpdated}
        loading={modalLoading}
        hasBaseKeyword={hasBaseKeyword}
        handleFetchRelatedKeywords={handleFetchRelatedKeywords}
      />
    </>
  );
};

export default ArticleList;
