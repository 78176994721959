import React, { useState } from "react";
import { Form, Input, Button, Modal, message } from "antd";
import { RightOutlined, SendOutlined } from "@ant-design/icons";
import { resetPassword, login } from "../../auth";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const LoginForm = () => {
  const [loading, setLoading] = useState(false);
  const [resetPasswordModalVisible, setResetPasswordModalVisible] =
    useState(false);
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
  const navigate = useNavigate();

  const handleResetPassword = async (values) => {
    setResetPasswordLoading(true);
    try {
      await resetPassword(values.email);
      setResetPasswordModalVisible(false);
      message.success(
        "パスワード再設定URLを送信しました。メールをご確認ください。",
      );
    } catch (error) {
      message.error("パスワード再設定URLの送信に失敗しました");
    }
    setResetPasswordLoading(false);
  };

  const handleLogin = async (values) => {
    setLoading(true);
    try {
      console.log("Login values:", values);
      await login(values.email, values.password);
      console.log("Login successful");
      message.success("ログインに成功しました");
      navigate("/seo-article/");
    } catch (error) {
      console.error("Login failed:", error);
      message.error("ログインに失敗しました");
    }
    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>ログイン | magicss</title>
        <meta name="robots" content="noindex" />
        <meta property="og:title" content={`ログイン | magicss`} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/ogp.png`}
        />
        <meta
          property="og:description"
          content={`コンテンツ生成AIツール「magicss」のログインページです。`}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Form
        onFinish={handleLogin}
        autoComplete="on"
        style={{ marginTop: "4px" }}
        method="post"
        action="/login"
      >
        <Form.Item
          name="email"
          rules={[
            { required: true, message: "メールアドレスを入力してください" },
          ]}
        >
          <Input
            type="email"
            placeholder="メールアドレス"
            autoComplete="username"
            size="large"
            style={{
              border: "2px solid #f5f5f5",
              backgroundColor: "#f5f5f5",
              padding: "8px",
              transition: "all 0.3s",
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = "#eee";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = "#f5f5f5";
            }}
            onFocus={(e) => {
              e.currentTarget.style.border = "2px solid #0072ff";
            }}
            onBlur={(e) => {
              e.currentTarget.style.border = "2px solid #f5f5f5";
            }}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "パスワードを入力してください" }]}
        >
          <div
            style={{
              border: "2px solid #f5f5f5",
              backgroundColor: "#f5f5f5",
              transition: "all 0.3s",
              borderRadius: "8px",
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = "#eee";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = "#f5f5f5";
            }}
            onFocus={(e) => {
              e.currentTarget.style.border = "2px solid #0072ff";
            }}
            onBlur={(e) => {
              e.currentTarget.style.border = "2px solid #f5f5f5";
            }}
          >
            <Input.Password
              placeholder="パスワード"
              autoComplete="current-password"
              size="large"
              style={{
                border: "none",
                backgroundColor: "transparent",
                outline: "none",
                padding: "8px",
              }}
            />
          </div>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            block
            size="large"
            style={{
              background: "linear-gradient(90deg, #0072ff 0%, #00d4ff 100%)",
              border: "none",
              transition: "all 0.3s",
              width: "100%",
              fontWeight: "bold",
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.opacity = "0.7";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.opacity = "1.0";
            }}
          >
            ログインする
            <RightOutlined
              style={{
                marginLeft: "auto",
                right: "10px",
                position: "absolute",
                top: "50%",
                transform: "translate(0, -50%)",
                fontSize: "14px",
              }}
            />
          </Button>
        </Form.Item>
      </Form>
      <div style={{ textAlign: "center" }}>
        <a onClick={() => setResetPasswordModalVisible(true)}>
          パスワードを忘れた場合
        </a>
      </div>
      <Modal
        title="パスワードの再設定"
        visible={resetPasswordModalVisible}
        onCancel={() => setResetPasswordModalVisible(false)}
        footer={null}
        style={{ textAlign: "center" }}
      >
        <Form
          onFinish={handleResetPassword}
          style={{ marginTop: "36px", textAlign: "left" }}
        >
          <Form.Item
            name="email"
            rules={[
              { required: true, message: "メールアドレスを入力してください" },
            ]}
          >
            <Input
              type="email"
              placeholder="メールアドレス"
              size="large"
              style={{
                border: "2px solid #f5f5f5",
                backgroundColor: "#f5f5f5",
                padding: "8px",
                transition: "all 0.3s",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = "#eee";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "#f5f5f5";
              }}
              onFocus={(e) => {
                e.currentTarget.style.border = "2px solid #0072ff";
              }}
              onBlur={(e) => {
                e.currentTarget.style.border = "2px solid #f5f5f5";
              }}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={resetPasswordLoading}
              block
              size="large"
              style={{
                background: "linear-gradient(90deg, #0072ff 0%, #00d4ff 100%)",
                border: "none",
                transition: "all 0.3s",
                width: "100%",
                fontWeight: "bold",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.opacity = "0.7";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.opacity = "1.0";
              }}
            >
              パスワード再設定URLを送信
              <SendOutlined
                style={{
                  marginLeft: "auto",
                  right: "10px",
                  position: "absolute",
                  top: "50%",
                  transform: "translate(0, -50%)",
                  fontSize: "14px",
                }}
              />
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default LoginForm;
