import React, { useState, useEffect } from 'react';
import {
  Card,
  Row,
  Col,
  Table,
  Divider,
  Typography,
  Statistic,
  Button,
  DatePicker,
  message,
  Spin,
} from 'antd';
import { Line } from 'react-chartjs-2';
import 'react-datepicker/dist/react-datepicker.css';
import { api } from '../api';
import UserDetail from './UserManagement/UserDetail';
import dayjs from 'dayjs';

const { Title, Text } = Typography;
const { RangePicker } = DatePicker;

const AdminDashboard = () => {
  const [planStats, setPlanStats] = useState({});
  const [dailyStats, setDailyStats] = useState({});
  const [userActivityData, setUserActivityData] = useState([]);
  const [kpiData, setKpiData] = useState({
    dates: [],
    new_free_users: [],
    new_paid_users: [],
    renewals: [],
    cancellations: [],
  });
  const [periodStats, setPeriodStats] = useState({});
  const [activityData, setActivityData] = useState({});
  const [dateRange, setDateRange] = useState([
    dayjs().subtract(1, 'month'),
    dayjs(),
  ]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [userDetailVisible, setUserDetailVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      fetchDashboardData();
    }
  }, [dateRange]);

  const fetchDashboardData = async () => {
    setLoading(true);
    try {
      const [start, end] = dateRange;
      console.log('Sending request with params:', {
        start_date: start.format('YYYY-MM-DD'),
        end_date: end.format('YYYY-MM-DD'),
      });
      const response = await api.get('/admin/dashboard', {
        params: {
          start_date: start.format('YYYY-MM-DD'),
          end_date: end.format('YYYY-MM-DD'),
        },
      });
      setPlanStats(response.data.plan_stats);
      setDailyStats(response.data.daily_stats);
      setUserActivityData(response.data.user_activity);
      setKpiData(
        response.data.kpi_data || {
          dates: [],
          new_free_users: [],
          new_paid_users: [],
          renewals: [],
          cancellations: [],
        },
      );
      setPeriodStats(response.data.period_stats);
      setActivityData(response.data.activity_data);
    } catch (error) {
      console.error('Failed to fetch dashboard data:', error);
      message.error('ダッシュボードデータの取得に失敗しました');
    } finally {
      setLoading(false);
    }
  };

  const renderPlanCards = () => (
    <Row gutter={[16, 16]}>
      {Object.entries(planStats)
        .sort(([aId], [bId]) => parseInt(aId) - parseInt(bId))
        .map(([planId, stats]) => (
          <Col xs={24} sm={12} md={8} lg={6} xl={4} key={planId}>
            <Card>
              <Statistic title={`${stats.display_name}`} value={stats.count} />
              <Text
                type={
                  stats.change > 0
                    ? 'success'
                    : stats.change < 0
                      ? 'danger'
                      : 'secondary'
                }
                style={{ display: 'block', marginTop: 8, fontSize: '12px' }}
              >
                直近一週間の増減: {stats.change > 0 ? '+' : ''}
                {stats.change}
              </Text>
            </Card>
          </Col>
        ))}
    </Row>
  );

  const renderDailyStats = () => (
    <Row gutter={[16, 16]}>
      {[
        {
          title: '本日の利用者数',
          value: 'user_count',
          change: 'user_count_change',
        },
        {
          title: '本日の生成実行数',
          value: 'generation_count',
          change: 'generation_count_change',
        },
        {
          title: '本日のトピックチェック数',
          value: 'topic_check_count',
          change: 'topic_check_count_change',
        },
        {
          title: '本日の本文生成数',
          value: 'content_generation_count',
          change: 'content_generation_count_change',
        },
      ].map(({ title, value, change }) => (
        <Col xs={24} lg={24} xl={24} key={value}>
          <Card>
            <Statistic title={title} value={dailyStats[value]} />
            <Text
              type={
                dailyStats[change] > 0
                  ? 'success'
                  : dailyStats[change] < 0
                    ? 'danger'
                    : 'secondary'
              }
              style={{ display: 'block', marginTop: 8, fontSize: '12px' }}
            >
              前日比: {dailyStats[change] > 0 ? '+' : ''}
              {dailyStats[change] || 0}
            </Text>
          </Card>
        </Col>
      ))}
    </Row>
  );

  const renderUserActivityTable = () => {
    const columns = [
      {
        title: '会社名/氏名',
        key: 'user',
        render: (_, record) => (
          <>
            <div style={{ fontSize: '0.9em', color: '#999' }}>
              {record.company_name}
            </div>
            <div>{record.full_name}</div>
          </>
        ),
      },
      {
        title: 'プラン',
        dataIndex: 'plan_name',
        key: 'plan',
      },
      {
        title: 'チケット枚数',
        dataIndex: 'ticket_count',
        key: 'ticket_count',
      },
      {
        title: 'SEO記事生成数',
        dataIndex: 'article_count',
        key: 'article_count',
      },
      {
        title: 'ENG',
        dataIndex: 'engagement_score',
        key: 'engagement_score',
        render: score => (score ? score.toFixed(2) : 'N/A'),
      },
    ];

    return (
      <Table
        dataSource={userActivityData}
        columns={columns}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: userActivityData.length,
          onChange: (page, pageSize) => {
            setCurrentPage(page);
            setPageSize(pageSize);
          },
        }}
        onRow={record => ({
          onClick: () => {
            setSelectedUserId(record.user_id);
            setUserDetailVisible(true);
          },
        })}
      />
    );
  };

  const renderKPIChart = () => {
    if (
      !kpiData ||
      !kpiData.dates ||
      !kpiData.new_free_users ||
      !kpiData.new_paid_users ||
      !kpiData.renewals ||
      !kpiData.cancellations
    ) {
      return <div>データの読み込み中...</div>;
    }

    const data = {
      labels: kpiData.dates,
      datasets: [
        {
          label: '新規登録者数',
          data: kpiData.dates.map(
            (_, index) =>
              kpiData.new_free_users[index] + kpiData.new_paid_users[index],
          ),
          borderColor: 'rgb(62,175,123)',
          borderWidth: 1.5,
          fill: false,
        },
        {
          label: '有料プラン転換数',
          data: kpiData.new_paid_users,
          borderColor: 'rgb(70,124,207)',
          borderWidth: 1.5,
          fill: false,
        },
        {
          label: '継続数',
          data: kpiData.renewals,
          borderColor: 'rgb(207,152,70)',
          borderWidth: 1.5,
          fill: false,
        },
        {
          label: '有料プランキャンセル数',
          data: kpiData.cancellations,
          borderColor: 'rgb(171,58,58)',
          borderWidth: 1.5,
          fill: false,
        },
      ],
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          type: 'time',
          time: {
            unit: 'day',
            displayFormats: {
              day: 'MM/DD',
            },
          },
        },
        y: {
          beginAtZero: true,
        },
      },
    };

    return (
      <div style={{ height: '400px', width: '100%' }}>
        <Line data={data} options={options} />
      </div>
    );
  };

  const renderActivityChart = () => {
    const data = {
      labels: activityData.dates,
      datasets: [
        {
          label: '利用者数',
          data: activityData.user_counts,
          borderColor: 'rgb(62,175,123)',
          borderWidth: 1.5,
          fill: false,
        },
        {
          label: '生成実行数',
          data: activityData.generation_counts,
          borderColor: 'rgb(70,124,207)',
          borderWidth: 1.5,
          fill: false,
        },
        {
          label: 'トピックチェック数',
          data: activityData.topic_check_counts,
          borderColor: 'rgb(207,152,70)',
          borderWidth: 1.5,
          fill: false,
        },
        {
          label: '本文生成数',
          data: activityData.content_generation_counts,
          borderColor: 'rgb(171,58,58)',
          borderWidth: 1.5,
          fill: false,
        },
      ],
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          type: 'time',
          time: {
            unit: 'day',
            displayFormats: {
              day: 'MM/DD',
            },
          },
        },
        y: {
          beginAtZero: true,
        },
      },
    };

    return (
      <div style={{ height: '400px', width: '100%' }}>
        <Line data={data} options={options} />
      </div>
    );
  };

  const renderPeriodStats = () => (
    <Row gutter={[16, 16]}>
      <Col xs={24} lg={12} xl={12}>
        <Card>
          <Statistic
            title="新規登録者（合計）"
            value={periodStats.total_new_users}
          />
          <Text
            type={
              periodStats.total_new_users_change > 0
                ? 'success'
                : periodStats.total_new_users_change < 0
                  ? 'danger'
                  : 'secondary'
            }
            style={{ display: 'block', marginTop: 8, fontSize: '12px' }}
          >
            前期間比: {periodStats.total_new_users_change > 0 ? '+' : ''}
            {periodStats.total_new_users_change}
          </Text>
        </Card>
      </Col>
      <Col xs={24} lg={12} xl={12}>
        <Card>
          <Statistic
            title="新規有料登録者"
            value={periodStats.new_paid_users}
          />
          <Text
            type={
              periodStats.paid_users_change > 0
                ? 'success'
                : periodStats.paid_users_change < 0
                  ? 'danger'
                  : 'secondary'
            }
            style={{ display: 'block', marginTop: 8 }}
          >
            前期間比: {periodStats.paid_users_change > 0 ? '+' : ''}
            {periodStats.paid_users_change}
          </Text>
        </Card>
      </Col>
      <Col xs={24} lg={12} xl={12}>
        <Card>
          <Row>
            <Col span={14}>
              <Statistic
                title="有料転換率"
                value={periodStats.conversion_rate?.toFixed(2)}
                suffix="%"
              />
              <Text
                type={
                  periodStats.conversion_rate_change > 0
                    ? 'success'
                    : periodStats.conversion_rate_change < 0
                      ? 'danger'
                      : 'secondary'
                }
                style={{ display: 'block', marginTop: 8 }}
              >
                前期間比: {periodStats.conversion_rate_change > 0 ? '+' : ''}
                {periodStats.conversion_rate_change?.toFixed(2) || '0.00'}%
              </Text>
            </Col>
            <Col span={10}>
              <div style={{ marginTop: 16 }}>
                {Object.entries(periodStats.conversion_rate_by_plan || {}).map(
                  ([planType, rate]) => (
                    <div key={planType}>
                      <Text style={{ color: '#999' }}>
                        {planType}: {rate.toFixed(2)}%
                      </Text>
                    </div>
                  ),
                )}
              </div>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col xs={24} lg={12} xl={12}>
        <Card>
          <Row>
            <Col span={14}>
              <Statistic title="売上" value={periodStats.revenue} prefix="¥" />
              <Text
                type={
                  periodStats.revenue_change > 0
                    ? 'success'
                    : periodStats.revenue_change < 0
                      ? 'danger'
                      : 'secondary'
                }
                style={{ display: 'block', marginTop: 8 }}
              >
                前期間比: {periodStats.revenue_change > 0 ? '+' : ''}
                {periodStats.revenue_change?.toLocaleString() || '0'}円
              </Text>
            </Col>
            <Col span={10}>
              <div style={{ marginTop: 16 }}>
                {Object.entries(periodStats.revenue_by_plan || {}).map(
                  ([planType, amount]) => (
                    <div key={planType}>
                      <Text style={{ color: '#999' }}>
                        {planType}: ¥{amount.toLocaleString()}
                      </Text>
                    </div>
                  ),
                )}
              </div>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );

  return (
    <Spin spinning={loading}>
      <div>
        <Title
          level={3}
          style={{
            fontSize: '14px',
            color: 'rgb(0 212 255)',
            marginBottom: '16px',
          }}
        >
          累計
        </Title>
        {renderPlanCards()}
        <Divider style={{ margin: '36px 0' }} />
        <Title
          level={3}
          style={{
            fontSize: '14px',
            color: 'rgb(0 212 255)',
            marginBottom: '16px',
          }}
        >
          リアルタイム
        </Title>
        <Row gutter={16}>
          <Col xs={24} xl={12}>
            {renderDailyStats()}
          </Col>
          <Col xs={24} xl={12}>
            {renderUserActivityTable()}
          </Col>
        </Row>
        <Divider style={{ margin: '36px 0' }} />
        <Title
          level={3}
          style={{
            fontSize: '14px',
            color: 'rgb(0 212 255)',
            marginBottom: '16px',
          }}
        >
          期間選択
        </Title>
        <RangePicker
          value={dateRange}
          onChange={dates => setDateRange(dates)}
          format="YYYY年MM月DD日"
          presets={[
            {
              label: '過去7日間',
              value: [dayjs().subtract(7, 'day'), dayjs()],
            },
            {
              label: '過去14日間',
              value: [dayjs().subtract(14, 'day'), dayjs()],
            },
            {
              label: '過去30日間',
              value: [dayjs().subtract(30, 'day'), dayjs()],
            },
            {
              label: '過去90日間',
              value: [dayjs().subtract(90, 'day'), dayjs()],
            },
            {
              label: '今月',
              value: [dayjs().startOf('month'), dayjs().endOf('month')],
            },
            {
              label: '前月',
              value: [
                dayjs().subtract(1, 'month').startOf('month'),
                dayjs().subtract(1, 'month').endOf('month'),
              ],
            },
          ]}
        />
        <Row gutter={[24, 24]} style={{ marginTop: '36px' }}>
          <Col xs={24} lg={24} xl={12}>
            {renderKPIChart()}
          </Col>
          <Col xs={24} lg={24} xl={12}>
            {renderActivityChart()}
          </Col>
        </Row>
        <Divider style={{ margin: '36px 0' }} />
        {renderPeriodStats()}
        <UserDetail
          visible={userDetailVisible}
          onCancel={() => setUserDetailVisible(false)}
          userId={selectedUserId}
          onUpdate={fetchDashboardData}
        />
      </div>
    </Spin>
  );
};

export default AdminDashboard;
