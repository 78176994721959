import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import { api } from '../api';
import TermsAgreementModal from './TermsAgreementModal';

const AgreementManager = ({ children }) => {
  const [agreementStatus, setAgreementStatus] = useState({
    showTermsModal: false,
    showPrivacyModal: false,
    updatedDate: '',
  });
  const navigate = useNavigate();

  useEffect(() => {
    checkAgreementStatus();
  }, []);

  const checkAgreementStatus = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) return;

      const { data } = await api.get('/users/agreement-status');

      setAgreementStatus({
        showTermsModal: data.needs_terms_agreement,
        showPrivacyModal:
          data.needs_privacy_agreement && !data.needs_terms_agreement,
        updatedDate: data.updated_date,
      });
    } catch (error) {
      if (error.response?.status === 401) {
        navigate('/login');
      }
      console.error('Failed to check agreement status:', error);
    }
  };

  const handleAgree = async type => {
    if (type === 'terms') {
      setAgreementStatus(prev => ({
        ...prev,
        showTermsModal: false,
        showPrivacyModal: prev.showPrivacyModal,
      }));
    } else {
      setAgreementStatus(prev => ({
        ...prev,
        showPrivacyModal: false,
      }));
    }
    await checkAgreementStatus();
  };

  return (
    <>
      {children}

      <TermsAgreementModal
        visible={agreementStatus.showTermsModal}
        type="terms"
        onAgree={() => handleAgree('terms')}
        updatedDate={agreementStatus.updatedDate}
      />

      <TermsAgreementModal
        visible={agreementStatus.showPrivacyModal}
        type="privacy"
        onAgree={() => handleAgree('privacy')}
        updatedDate={agreementStatus.updatedDate}
      />
    </>
  );
};

export default AgreementManager;
