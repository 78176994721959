import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { message } from "antd";

const SubscriptionCanceled = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const action = searchParams.get("action");

    if (action === "keep_current") {
      message.info("現在のサブスクリプションが維持されました。");
    } else {
      message.warning("サブスクリプションの変更がキャンセルされました。");
    }

    // ユーザーを適切なページにリダイレクト（例：サブスクリプション管理ページ）
    navigate("/subscription-management");
  }, [location, navigate]);

  return <div>処理中...</div>;
};

export default SubscriptionCanceled;
