import React from "react";
import { Alert, Button } from "antd";
import { RocketOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const UpgradeBanner = () => (
  <Alert
    message={
      <span>
        チケットを使い切りました。AIによる爆発的な業務効率化の準備はできましたか？
        <Button style={{ marginLeft: "24px" }}>
          <Link to="/plan">今すぐプランをアップグレードする</Link>
        </Button>
      </span>
    }
    type="info"
    showIcon
    banner
    style={{ marginBottom: 24 }}
  />
);

export default UpgradeBanner;
