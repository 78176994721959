import React from 'react';
import { Typography, Layout, Button, Divider } from 'antd';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './forStatic.css';

const { Title, Paragraph } = Typography;
const { Header, Content } = Layout;

const PrivacyPolicy = ({ isAuthenticated }) => {
  const renderHeader = () => (
    <Header
      style={{
        background: '#fff',
        padding: '0 16px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <div style={{ flex: 1 }}></div>
      <div
        className="logo mobile-logo"
        style={{ textAlign: 'center', flex: 1 }}
      >
        <span>Ignite creativity with..</span>magicss
      </div>
      <div style={{ flex: 1, textAlign: 'right' }}>
        {!isAuthenticated && (
          <Button type="primary">
            <Link to="/login">無料登録</Link>
          </Button>
        )}
      </div>
    </Header>
  );

  return (
    <>
      <Helmet>
        <title>プライバシーポリシー | magicss</title>
        <meta name="robots" content="noindex" />
        <meta property="og:title" content={`プライバシーポリシー | magicss`} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/ogp.png`}
        />
        <meta
          property="og:description"
          content={`コンテンツ生成AIツール「magicss」のプライバシーポリシーページです。`}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Layout id="static_page">
        {renderHeader()}
        <Content className="static_inner">
          <Title level={1}>プライバシーポリシー</Title>
          <Divider />
          <Title level={2}>お客様から取得する情報</Title>
          <Paragraph>当社は、お客様から以下の情報を取得します。</Paragraph>

          <ul>
            <li>氏名(ニックネームやペンネームも含む)</li>
            <li>職業、職歴、学歴</li>
            <li>メールアドレス</li>
            <li>住所</li>
            <li>
              外部サービスでお客様が利用するID、その他外部サービスのプライバシー設定によりお客様が連携先に開示を認めた情報
            </li>
            <li>Cookie(クッキー)を用いて生成された識別情報</li>
            <li>
              OSが生成するID、端末の種類、端末識別子等のお客様が利用するOSや端末に関する情報
            </li>
            <li>
              当社ウェブサイトの滞在時間、入力履歴、購買履歴等の当社ウェブサイトにおけるお客様の行動履歴
            </li>
            <li>
              当社アプリの起動時間、入力履歴、購買履歴等の当社アプリの利用履歴
            </li>
          </ul>

          <Title level={2}>お客様の情報を利用する目的</Title>
          <Paragraph>
            当社は、お客様から取得した情報を、以下の目的のために利用します。
          </Paragraph>

          <ul>
            <li>
              当社サービスに関する登録の受付、お客様の本人確認、認証のため
            </li>
            <li>お客様の当社サービスの利用履歴を管理するため</li>
            <li>利用料金の決済のため</li>
            <li>
              当社サービスにおけるお客様の行動履歴を分析し、当社サービスの維持改善に役立てるため{' '}
            </li>
            <li>広告の配信、表示及び効果測定のため</li>
            <li>当社のサービスに関するご案内をするため</li>
            <li>お客様からのお問い合わせに対応するため</li>
            <li>当社の規約や法令に違反する行為に対応するため</li>
            <li>
              当社サービスの変更、提供中止、終了、契約解除をご連絡するため
            </li>
            <li>当社規約の変更等を通知するため</li>
            <li>以上の他、当社サービスの提供、維持、保護及び改善のため</li>
          </ul>

          <Title level={2}>安全管理のために講じた措置</Title>

          <Paragraph>
            当社が、お客様から取得した情報に関して安全管理のために講じた措置につきましては、末尾記載のお問い合わせ先にご連絡をいただきましたら、法令の定めに従い個別にご回答させていただきます。
          </Paragraph>

          <Title level={2}>第三者提供</Title>

          <Paragraph>
            当社は、お客様から取得する情報のうち、個人データ（個人情報保護法第１６条第３項）に該当するものついては、あらかじめお客様の同意を得ずに、第三者（日本国外にある者を含みます）に提供しません。
          </Paragraph>
          <Paragraph>但し、次の場合は除きます。</Paragraph>

          <ul>
            <li>個人データの取扱いを外部に委託する場合</li>
            <li>当社や当社サービスが買収された場合</li>
            <li>
              事業パートナーと共同利用する場合（具体的な共同利用がある場合は、その内容を別途公表します。）
            </li>
            <li>その他、法律によって合法的に第三者提供が許されている場合</li>
          </ul>

          <Title level={2}>アクセス解析ツール</Title>

          <Paragraph>
            当社は、お客様のアクセス解析のために、「Googleアナリティクス」を利用しています。Googleアナリティクスは、トラフィックデータの収集のためにCookieを使用しています。トラフィックデータは匿名収集されており、個人を特定するものではありません。Cookieを無効にすれば、これらの情報の収集を拒否することができます。詳しくはお使いのブラウザの設定をご確認ください。Googleアナリティクスについて、詳しくは以下からご確認ください。
          </Paragraph>
          <Paragraph>
            <a
              href="https://marketingplatform.google.com/about/analytics/terms/jp/"
              target="_blank"
            >
              https://marketingplatform.google.com/about/analytics/terms/jp/
            </a>
          </Paragraph>

          <Title level={2}>プライバシーポリシーの変更</Title>

          <Paragraph>
            当社は、必要に応じて、このプライバシーポリシーの内容を変更します。この場合、変更後のプライバシーポリシーの施行時期と内容を適切な方法により周知または通知します。
          </Paragraph>

          <Title level={2}>お問い合わせ</Title>

          <Paragraph>
            お客様の情報の開示、情報の訂正、利用停止、削除をご希望の場合は、以下のメールアドレスにご連絡ください。
          </Paragraph>

          <Paragraph>e-mail:privacy@magicss.ai</Paragraph>

          <Paragraph>
            この場合、必ず、運転免許証のご提示等当社が指定する方法により、ご本人からのご請求であることの確認をさせていただきます。なお、情報の開示請求については、開示の有無に関わらず、ご請求時に一件あたり1,000円の事務手数料を申し受けます。
          </Paragraph>

          <Title level={2}>事業者の情報</Title>
          <table id="static_table">
            <tr>
              <th>事業者の名称</th>
              <td>ONEWORD株式会社</td>
            </tr>
            <tr>
              <th>代表者氏名</th>
              <td>金井 良祐</td>
            </tr>
            <tr>
              <th>事業者の住所</th>
              <td>東京都渋谷区桜丘町14-10 渋谷コープ708</td>
            </tr>
          </table>

          <Paragraph style={{ textAlign: 'right' }}>
            2024年10月18日 制定
          </Paragraph>
        </Content>
      </Layout>
    </>
  );
};

export default PrivacyPolicy;
